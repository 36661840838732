import { generalApi } from '@/lib/api/general';
import {
  dispatchCheckApiError,
  dispatchLogOut,
  MainContext,
} from '@/store/main/actionsMain';
import { commitAddNotification } from '@/store/main/mutations';
import { SortState } from '@/views/composables/main/general/useSds';
import { AxiosError } from 'axios';

export const actionGetDynamicList = async (
  context: MainContext,
  payload: {
    dynamicListId: number;
  }
) => {
  try {
    return await generalApi.getDynamicList(
      context.state.token,
      payload.dynamicListId
    );
  } catch (error) {
    const axiosError = error as AxiosError;
    await dispatchCheckApiError(context, axiosError);
  }
};

export const actionGetDynamicLists = async (
  context: MainContext,
  payload: {
    page: number;
    limit: number;
    showAll: boolean;
  }
) => {
  try {
    return await generalApi.getDynamicLists(
      context.state.token,
      payload.page,
      payload.limit,
      payload.showAll
    );
  } catch (error) {
    const axiosError = error as AxiosError;
    await dispatchCheckApiError(context, axiosError);
  }
};

export const actionGetDynamicListResults = async (
  context: MainContext,
  payload: {
    dynamicListId: number;
    keywordList: Array<string>;
    sortState: SortState | null;
    pdfName?: string;
    productName?: string;
    note?: string;
    cause?: string;
    revisedAtStartDate?: string;
    revisedAtEndDate?: string;
    createdAtStartDate?: string;
    createdAtEndDate?: string;
    updatedAtStartDate?: string;
    updatedAtEndDate?: string;
    userIdList: Array<string>;
    tagIdList: Array<number>;
    departmentIdList: Array<string>;
    isNew?: boolean;
  }
) => {
  const {
    dynamicListId,
    keywordList,
    sortState,
    pdfName,
    productName,
    note,
    cause,
    revisedAtStartDate,
    revisedAtEndDate,
    createdAtStartDate,
    createdAtEndDate,
    updatedAtStartDate,
    updatedAtEndDate,
    userIdList,
    tagIdList,
    departmentIdList,
    isNew,
  } = payload;

  const safeSortState: SortState | undefined =
    sortState === null ? undefined : sortState;

  try {
    return await generalApi.getDynamicListResults(
      context.state.token,
      dynamicListId,
      keywordList,
      safeSortState,
      pdfName,
      productName,
      note,
      cause,
      revisedAtStartDate,
      revisedAtEndDate,
      createdAtStartDate,
      createdAtEndDate,
      updatedAtStartDate,
      updatedAtEndDate,
      userIdList,
      tagIdList,
      departmentIdList,
      isNew
    );
  } catch (error) {
    const axiosError = error as AxiosError;
    await dispatchCheckApiError(context, axiosError);
  }
};

export const actionGetExportDynamicListResults = async (
  context: MainContext,
  payload: {
    dynamicListId: number;
  }
) => {
  try {
    return await generalApi.getExportDynamicListResults(
      context.state.token,
      payload.dynamicListId
    );
  } catch (error) {
    const axiosError = error as AxiosError;
    await dispatchCheckApiError(context, axiosError);
  }
};

export const actionRequestDynamicList = async (
  context: MainContext,
  payload: {
    companyName: string;
    description: string;
    law_regulation_text: string[];
  }
) => {
  try {
    const data = {
      company_name: payload.companyName,
      description: payload.description,
      law_regulation_text: payload.law_regulation_text,
    };
    const response = await generalApi.requestDynamicList(
      context.state.token,
      data
    );
    if (response) {
      commitAddNotification(context, {
        content: 'リスト作成を依頼しました',
        color: 'success',
      });
    }
  } catch (error) {
    const axiosError = error as AxiosError;
    await dispatchCheckApiError(context, axiosError);
  }
};

export const actionCreateDynamicList = async (
  context: MainContext,
  payload: {
    lawRegulationIds: string[];
    extractionTargets: string[];
    title: string;
    description: string;
  }
) => {
  try {
    const response = await generalApi.createDynamicList(context.state.token, {
      law_regulation_ids: payload.lawRegulationIds,
      extraction_targets: payload.extractionTargets,
      title: payload.title,
      description: payload.description,
    });
    if (response) {
      commitAddNotification(context, {
        content: 'チェックリストを作成しました',
        color: 'success',
      });
    }
  } catch (error) {
    const axiosError = error as AxiosError;
    await dispatchCheckApiError(context, axiosError);
    if (axiosError?.response?.status === 403) {
      await dispatchLogOut(context);
    }
    throw axiosError;
  }
};

export const actionResumeDynamicLists = async (
  context: MainContext,
  payload: {
    dynamicListIds: number[];
  }
) => {
  try {
    const response = await generalApi.resumeDynamicLists(context.state.token, {
      dynamic_list_ids: payload.dynamicListIds,
    });
    if (response) {
      commitAddNotification(context, {
        content: 'チェックリストの更新を再開しました',
        color: 'success',
      });
    }
  } catch (error) {
    const axiosError = error as AxiosError;
    await dispatchCheckApiError(context, axiosError);
    if (axiosError?.response?.status === 403) {
      await dispatchLogOut(context);
    }
    throw axiosError;
  }
};

export const actionSuspendDynamicLists = async (
  context: MainContext,
  payload: {
    dynamicListIds: number[];
  }
) => {
  try {
    const response = await generalApi.suspendDynamicLists(context.state.token, {
      dynamic_list_ids: payload.dynamicListIds,
    });
    if (response) {
      commitAddNotification(context, {
        content: 'チェックリストの更新を停止しました',
        color: 'success',
      });
    }
  } catch (error) {
    const axiosError = error as AxiosError;
    await dispatchCheckApiError(context, axiosError);
    if (axiosError?.response?.status === 403) {
      await dispatchLogOut(context);
    }
    throw axiosError;
  }
};
