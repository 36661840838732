import Vue from 'vue';
import Router, { Route } from 'vue-router';

import { store } from '@/store/index';
import { dispatchCheckLoggedIn } from '@/store/main/actionsMain';
import { readIsLoggedIn } from '@/store/main/getters';
import { usePermissionComposable } from '@/views/composables/main/general/usePermission';

Vue.use(Router);

const isLogin = async (): Promise<boolean> => {
  await dispatchCheckLoggedIn(store);

  return !!readIsLoggedIn(store);
};

function getEnvironmentBasedRedirectUrl(basePath) {
  const baseUrl =
    {
      production: 'https://chemican.com',
      staging: 'https://stg.chemican.com',
    }[process.env.VUE_APP_ENV] || '';

  return baseUrl + basePath;
}

export default new Router({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: () => import('./views/layouts/LPLayout.vue'),
      beforeEnter: (to, from, next) => {
        if (to.path === '/') {
          const redirectUrl = getEnvironmentBasedRedirectUrl('/');
          if (
            redirectUrl &&
            process.env.VUE_APP_ENV !== 'development' &&
            process.env.NODE_ENV !== 'development'
          ) {
            window.location.href = redirectUrl;
          } else {
            next();
          }
        } else {
          next();
        }
      },
      children: [
        {
          path: '',
          name: 'top',
          beforeEnter: (to, from, next) => {
            const redirectUrl = getEnvironmentBasedRedirectUrl('/');
            if (
              redirectUrl &&
              process.env.VUE_APP_ENV !== 'development' &&
              process.env.NODE_ENV !== 'development'
            ) {
              window.location.href = redirectUrl;
            } else {
              next();
            }
          },
        },
        {
          path: 'corporate',
          name: 'corporate',
          beforeEnter: (to, from, next) => {
            const redirectUrl = getEnvironmentBasedRedirectUrl('/corporate');
            if (
              redirectUrl &&
              process.env.VUE_APP_ENV !== 'development' &&
              process.env.NODE_ENV !== 'development'
            ) {
              window.location.href = redirectUrl;
            } else {
              next();
            }
          },
        },
        {
          path: 'privacypolicy',
          name: 'privacypolicy',
          component: () =>
            import(
              /* webpackChunkName: "user" */ './views/main/general/PrivacyPolicy.vue'
            ),
        },
        {
          path: 'terms',
          name: 'terms',
          component: () =>
            import(
              /* webpackChunkName: "user" */ './views/main/general/TermsOfUse.vue'
            ),
        },
        {
          path: 'login',
          name: 'login',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () =>
            import(
              /* webpackChunkName: "login" */ './views/main/general/LoginPageComponent.vue'
            ),
        },
        {
          path: 'signup/activation',
          name: 'activation',
          component: () =>
            import(
              /* webpackChunkName: "userActivation" */ './views/main/general/UserActivation.vue'
            ),
        },
        {
          path: 'signup',
          name: 'signUp',
          component: () =>
            import(
              /* webpackChunkName: "login" */ './views/main/general/SignUp.vue'
            ),
        },
        {
          path: 'inquiry',
          name: 'inquiry',
          component: () =>
            import(
              /* webpackChunkName: "login" */ './views/main/general/InquiryPageComponent.vue'
            ),
        },
        {
          path: 'en/contact',
          name: 'contacten',
          component: () =>
            import(
              /* webpackChunkName: "login" */ './views/main/general/ContactEn.vue'
            ),
        },
        {
          path: 'reset-password',
          name: 'resetPassword',
          component: () =>
            import(
              /* webpackChunkName: "login" */ './views/main/general/ResetPassword.vue'
            ),
        },
        {
          path: 'password-recovery',
          name: 'passwordRecovery',
          component: () =>
            import(
              /* webpackChunkName: "login" */ './views/main/general/PasswordRecovery.vue'
            ),
        },
        {
          path: 'unregister',
          name: 'unregister',
          component: () =>
            import(
              /* webpackChunkName: "user" */ './views/main/general/UnregisterServiceWorker.vue'
            ),
        },
      ],
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: () => import('./views/layouts/DashboardLayout.vue'),
      beforeEnter: async (to: Route, from: Route, next) => {
        const isLoggedIn = await isLogin();
        if (isLoggedIn) {
          next();
        } else {
          next({ name: 'login', query: { redirect: to.fullPath } });
        }
      },
      children: [
        {
          path: 'sds/upload',
          name: 'sdsUpload',
          component: () =>
            import(
              /* webpackChunkName: "sds-upload" */ './views/main/general/SdsUpload.vue'
            ),
          beforeEnter: async (to: Route, from: Route, next) => {
            const {
              hasPermission,
              PERMISSIONS: { SDS_UPLOAD },
            } = usePermissionComposable();
            if (!hasPermission(SDS_UPLOAD)) {
              return next({ name: 'sdsList' });
            } else {
              return next();
            }
          },
        },
        {
          path: 'sds/index',
          name: 'sdsList',
          component: () =>
            import(
              /* webpackChunkName: "sds-list" */ './views/main/general/SdsList.vue'
            ),
        },
        {
          path: 'dynamic/list',
          name: 'sdsDynamicList',
          component: () =>
            import(
              /* webpackChunkName: "processing-sds" */ './views/main/general/SdsDynamicList.vue'
            ),
          beforeEnter: async (to: Route, from: Route, next) => {
            const {
              hasPermission,
              PERMISSIONS: { CHECK_LIST_READ },
            } = usePermissionComposable();
            if (!hasPermission(CHECK_LIST_READ)) {
              return next({ name: 'sdsList' });
            } else {
              return next();
            }
          },
        },
        {
          path: 'dynamic/list/:id',
          name: 'sdsDynamicListResult',
          component: () =>
            import(
              /* webpackChunkName: "processing-sds" */ './views/main/general/SdsDynamicListResult.vue'
            ),
          beforeEnter: async (to: Route, from: Route, next) => {
            const {
              hasPermission,
              PERMISSIONS: { CHECK_LIST_READ },
            } = usePermissionComposable();
            if (!hasPermission(CHECK_LIST_READ)) {
              return next({ name: 'sdsList' });
            } else {
              return next();
            }
          },
        },
        {
          path: 'sds/index/error',
          name: 'sdsError',
          component: () =>
            import(
              /* webpackChunkName: "error-sds" */ './views/main/general/SdsError.vue'
            ),
        },
        {
          path: 'company/departments',
          name: 'companyDepartments',
          component: () =>
            import(
              /* webpackChunkName: "company-departments" */ './views/main/general/CompanyDepartments.vue'
            ),
        },
        {
          path: 'company/users',
          name: 'companyUsers',
          component: () =>
            import(
              /* webpackChunkName: "company-users" */ './views/main/general/CompanyUsers.vue'
            ),
        },
        {
          path: 'company/tags',
          name: 'companyTags',
          component: () =>
            import(
              /* webpackChunkName: "company-tags" */ './views/main/general/CompanyTags.vue'
            ),
        },
        {
          path: 'company/whitelist_ips',
          name: 'companyWhitelistIps',
          component: () =>
            import(
              /* webpackChunkName: "company-whitelist-ips" */ './views/main/general/CompanyWhitelistIps.vue'
            ),
        },
        {
          path: 'company',
          name: 'company',
          component: () =>
            import(
              /* webpackChunkName: "company" */ './views/main/general/CompanyPageComponent.vue'
            ),
        },
        {
          path: 'user',
          name: 'user',
          component: () =>
            import(
              /* webpackChunkName: "user" */ './views/main/general/UserPageComponent.vue'
            ),
        },
      ],
    },
    {
      path: '/*',
      redirect: '/',
    },
  ],
  // @ts-ignore
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { selector: to.hash };
    }

    return { x: 0, y: 0 };
  },
});
