export class PlanId {
  private static readonly _values: PlanId[] = [];

  static readonly FREE = new PlanId(0, 'フリープラン', '無料', 10, '10件');
  static readonly PAID_100 = new PlanId(100, '100件', '15,000円', 100, '100件');
  static readonly PAID_250 = new PlanId(250, '250件', '-円', 250, '250件');
  static readonly PAID_300 = new PlanId(300, '300件', '20,000円', 300, '300件');
  static readonly PAID_500 = new PlanId(500, '500件', '25,000円', 500, '500件');
  static readonly PAID_1000 = new PlanId(
    1000,
    '1,000件',
    '40,000円',
    1000,
    '1,000件'
  );
  static readonly PAID_2500 = new PlanId(
    2500,
    '2,500件',
    '60,000円',
    2500,
    '2,500件'
  );
  static readonly PAID_4000 = new PlanId(
    4000,
    '4,000件',
    '40,000円',
    4000,
    '4,000件'
  );
  static readonly PAID_5000 = new PlanId(
    5000,
    '5,000件',
    '75,000円',
    5000,
    '5,000件'
  );
  static readonly PAID_7500 = new PlanId(
    7500,
    '7,500件',
    '90,000円',
    7500,
    '7,500件'
  );
  static readonly PAID_10000 = new PlanId(
    10000,
    '10,000件',
    '100,000円',
    10000,
    '10,000件'
  );
  static readonly PAID_15000 = new PlanId(
    15000,
    '15,000件',
    '-円',
    15000,
    '15,000件'
  );
  static readonly PAID_20000 = new PlanId(
    20000,
    '20,000件',
    '-円',
    20000,
    '20,000件'
  );
  static readonly PAID_30000 = new PlanId(
    30000,
    '30,000件',
    '-円',
    30000,
    '30,000件'
  );
  static readonly PAID_40000 = new PlanId(
    40000,
    '40,000件',
    '-円',
    40000,
    '40,000件'
  );
  static readonly PAID_50000 = new PlanId(
    50000,
    '50,000件',
    '-円',
    50000,
    '50,000件'
  );
  static readonly PAID_UNLIMITED = new PlanId(
    1000000000,
    '上限なし',
    'お問い合わせください',
    Infinity,
    '上限なし'
  );

  private constructor(
    readonly value: number,
    readonly text: string,
    readonly price: string,
    readonly maxUpload: number,
    readonly maxUploadText: string
  ) {
    PlanId._values.push(this);
  }

  static fromPlanId(value: number): PlanId {
    const res = PlanId._values.find((x) => x.value === value);
    if (res) {
      return res;
    } else {
      return PlanId.FREE;
    }
  }

  static values(): PlanId[] {
    return PlanId._values;
  }
}

export class StatusId {
  private static readonly _values: StatusId[] = [];
  private static _valuesCopy: StatusId[] | null = null;

  static readonly DEFAULT = new StatusId(0, '', '', 'Default');
  static readonly UPLOADING = new StatusId(
    10000,
    '分析前',
    'アップロード中',
    '分析前'
  );
  static readonly ASSORT = new StatusId(
    15000,
    '分析前',
    '再仕分け中',
    '分析前'
  );
  static readonly ASSORT_DONE = new StatusId(
    20000,
    '分析前',
    '仕分け完了・1次入力前',
    '分析前'
  );
  static readonly FIRST_CHECK_PROCESSING = new StatusId(
    30000,
    '分析中(削除不可)',
    '1次入力中',
    '分析中'
  );
  static readonly FIRST_CHECK_DONE = new StatusId(
    40000,
    '分析中(削除不可)',
    '1次入力完了・2次入力前',
    '分析中'
  );
  static readonly SECOND_CHECK_PROCESSING = new StatusId(
    50000,
    '分析中(削除不可)',
    '2次入力中',
    '分析中'
  );
  static readonly SECOND_CHECK_DONE = new StatusId(
    60000,
    '分析完了',
    '2次入力完了',
    '分析完了'
  );
  static readonly ERROR = new StatusId(
    90000,
    '分析不可',
    '分析不可',
    '分析不可'
  );
  static readonly ERROR_RECONFIRMATION = new StatusId(
    91000,
    '要確認',
    '要確認',
    '要確認'
  );

  private constructor(
    readonly value: number,
    readonly text: string,
    readonly adminText: string,
    readonly groupId: string
  ) {
    StatusId._values.push(this);
  }

  static fromStatusId(value: number): StatusId {
    const res = StatusId._values.find((x) => x.value === value);
    if (res) {
      return res;
    } else {
      return StatusId.DEFAULT;
    }
  }

  static values(): Array<number> {
    return StatusId._values.map((x) => x.value);
  }

  static uploadingToCompletedValues(): Array<number> {
    return [
      StatusId.UPLOADING.value,
      StatusId.ASSORT.value,
      StatusId.ASSORT_DONE.value,
      StatusId.FIRST_CHECK_PROCESSING.value,
      StatusId.FIRST_CHECK_DONE.value,
      StatusId.SECOND_CHECK_PROCESSING.value,
      StatusId.SECOND_CHECK_DONE.value,
      StatusId.ERROR.value,
      StatusId.ERROR_RECONFIRMATION.value,
    ];
  }

  static secondCheckDoneToCompletedValues(): Array<number> {
    return [StatusId.SECOND_CHECK_DONE.value];
  }

  static getDropdownOptions(): Array<StatusId> {
    if (this._valuesCopy === null) {
      this._valuesCopy = StatusId._values
        .filter((status) => status.value !== StatusId.DEFAULT.value) // DEFAULT を除外
        .map(
          (status) =>
            new StatusId(
              status.value,
              status.text,
              status.adminText,
              status.groupId
            )
        );
    }
    return this._valuesCopy;
  }

  static getClassFromStatusId = (statusId: number): string => {
    if (statusId <= 20000) return 'base-status-class status-pre-analysis-class';
    if (statusId <= 50000) return 'base-status-class status-analyzing-class';
    if (statusId == 60000) return 'base-status-class success';
    if (statusId == 90000) return 'base-status-class error';
    return 'base-status-class warning';
  };

  static getStatusFromStatusId = (statusId: number): string => {
    if (statusId <= 20000) return 'before';
    if (statusId <= 50000) return 'analyzing';
    if (statusId == 60000) return 'complete';
    if (statusId == 90000) return 'impossible';
    return 'check';
  };
}

export class RoleId {
  private static readonly _values: RoleId[] = [];

  static readonly USER_VIEWER = new RoleId(0, '閲覧者');
  static readonly USER_EDITOR = new RoleId(90, '編集者');
  static readonly USER_ADMIN = new RoleId(100, '管理者');

  private constructor(readonly value: number, readonly text: string) {
    RoleId._values.push(this);
  }

  static fromRoleId(value: number): RoleId {
    const res = RoleId._values.find((x) => x.value === value);
    if (res) {
      return res;
    } else {
      return RoleId.USER_VIEWER;
    }
  }

  static values(): RoleId[] {
    return RoleId._values;
  }
}

export class LanguageId {
  private static readonly _values: LanguageId[] = [];

  static readonly JAPANESE = new LanguageId(44, '日本語');
  static readonly ENGLISH = new LanguageId(16, '英語');
  static readonly OTHER = new LanguageId(999, 'その他');

  private constructor(readonly value: number, readonly text: string) {
    LanguageId._values.push(this);
  }

  static fromLanguageId(value: number): LanguageId {
    const res = LanguageId._values.find((x) => x.value === value);
    if (res) {
      return res;
    } else {
      return LanguageId.OTHER;
    }
  }

  static values(): LanguageId[] {
    return LanguageId._values;
  }
}

export class SourceTypeId {
  private static readonly _values: SourceTypeId[] = [];

  static readonly BATCH = new SourceTypeId(0, 'batch');
  static readonly USER = new SourceTypeId(1, 'user');

  private constructor(readonly value: number, readonly text: string) {
    SourceTypeId._values.push(this);
  }

  static fromSourceTypeId(value: number): SourceTypeId {
    const res = SourceTypeId._values.find((x) => x.value === value);
    if (res) {
      return res;
    } else {
      return SourceTypeId.BATCH;
    }
  }

  static values(): SourceTypeId[] {
    return SourceTypeId._values;
  }
}

export class LawId {
  private static readonly _values: LawId[] = [];

  static readonly DEFAULT = new LawId(0, '', '', '');
  static readonly LAW_ENCS = new LawId(
    100000000,
    '化学物質の審査及び製造等の規制に関する法律 ; 化審法 ; Chemical Substances Control Law ; CSCL | ENCS',
    '化学物質の審査及び製造等の規制に関する法律 ; 化審法',
    'Chemical Substances Control Law ; CSCL | ENCS'
  );
  static readonly LAW_PRTR = new LawId(
    100010000,
    '化学物質排出把握管理促進法（PRTR） ; 化管法 ; Act on Confirmation, etc. of Release Amounts of Specific Chemical Substances in the Environment and Promotion of Improvements to the Management Thereof ; PRTR',
    '化学物質排出把握管理促進法（PRTR） ; 化管法',
    'Act on Confirmation, etc. of Release Amounts of Specific Chemical Substances in the Environment and Promotion of Improvements to the Management Thereof ; PRTR'
  );
  static readonly LAW_ISHL = new LawId(
    100020000,
    '労働安全衛生法 ; 安衛法 | 労安法 ; Industrial Safety and Health Act ; ISHA | ISHL',
    '労働安全衛生法 ; 安衛法 | 労安法',
    'Industrial Safety and Health Act ; ISHA | ISHL'
  );
  static readonly LAW_POISONOUS_AND_DELETERIOUS_SUBSTANCES_CONTROL_ACT =
    new LawId(
      100030000,
      '毒物及び劇物取締法 ; 毒劇法 ; Poisonous and Deleterious Substances Control Act ; -',
      '毒物及び劇物取締法 ; 毒劇法',
      'Poisonous and Deleterious Substances Control Act ; -'
    );
  static readonly LAW_FIRE_SERVICE_ACT = new LawId(
    100040000,
    '消防法 ; - ; Fire Service Act ; -',
    '消防法',
    'Fire Service Act'
  );
  static readonly LAW_FOREIGN_EXCHANGE_AND_FOREIGN_TRADE_ACT = new LawId(
    100050000,
    '外国為替及び外国貿易法 ; 外為法 ; Foreign Exchange and Foreign Trade Act ; -',
    '外国為替及び外国貿易法 ; 外為法',
    'Foreign Exchange and Foreign Trade Act'
  );
  static readonly LAW_ACT_ON_THE_PROTECTION_OF_THE_OZONE_LAYER = new LawId(
    100060000,
    '特定物質等の規制等によるオゾン層の保護に関する法律 ; オゾン層保護法 ; Act on the Protection of the Ozone Layer through the Control and Other Measures on Specified Substances and Other Substances ; -',
    '特定物質等の規制等によるオゾン層の保護に関する法律 ; オゾン層保護法',
    'Act on the Protection of the Ozone Layer through the Control and Other Measures on Specified Substances and Other Substances'
  );
  static readonly LAW_AIR_POLLUTION_CONTROL_ACT = new LawId(
    100070000,
    '大気汚染防止法 ; - ; Air Pollution Control Act ; -',
    '大気汚染防止法',
    'Air Pollution Control Act'
  );
  static readonly LAW_WATER_POLLUTION_PREVENTION_ACT = new LawId(
    100080000,
    '水質汚濁防止法 ; - ; Water Pollution Prevention Act ; -',
    '水質汚濁防止法',
    'Water Pollution Prevention Act'
  );
  static readonly LAW_SOIL_CONTAMINATION_COUNTERMEASURES_ACT = new LawId(
    100090000,
    '土壌汚染対策法 ; - ; Soil Contamination Countermeasures Act ; -',
    '土壌汚染対策法',
    'Soil Contamination Countermeasures Act'
  );
  static readonly LAW_FOOD_SANITATION_ACT = new LawId(
    100100000,
    '食品衛生法 ; - ; Food Sanitation Act ; -',
    '食品衛生法',
    'Food Sanitation Act'
  );
  static readonly LAW_ACT_ON_PREVENTION_OF_MARINE_POLLUTION = new LawId(
    100110000,
    '海洋汚染等及び海上災害の防止に関する法律 ; 海洋汚染防止法 ; Act on Prevention of Marine Pollution and Maritime Disaster ; -',
    '海洋汚染等及び海上災害の防止に関する法律 ; 海洋汚染防止法',
    'Act on Prevention of Marine Pollution and Maritime Disaster'
  );
  static readonly LAW_SHIP_SAFETY_LAW = new LawId(
    100120000,
    '船舶安全法 ; - ; Ship Safety Law ; -',
    '船舶安全法',
    'Ship Safety Law'
  );
  static readonly LAW_CIVIL_AERONAUTICS_ACT = new LawId(
    100130000,
    '航空法 ; - ; Civil Aeronautics Act ; -',
    '航空法',
    'Civil Aeronautics Act'
  );
  static readonly LAW_PORT_REGULATIONS_ACT = new LawId(
    100140000,
    '港則法  ; - ; Port Regulations Act ; -',
    '港則法',
    'Port Regulations Act'
  );
  static readonly LAW_WASTE_MANAGEMENT_AND_PUBLIC_CLEANSING_ACT = new LawId(
    100150000,
    '廃棄物の処理及び清掃に関する法律 ; 廃棄物処理法 | 廃掃法 ; Waste Management and Public Cleansing Act ; -',
    '廃棄物の処理及び清掃に関する法律 ; 廃棄物処理法 | 廃掃法',
    'Waste Management and Public Cleansing Act'
  );
  static readonly LAW_ACT_ON_PROHIBITION_OF_CHEMICAL_WEAPONS = new LawId(
    100160000,
    '化学兵器の禁止及び特定物質の規制等に関する法律 ; 化学兵器禁止法 ; Act on Prohibition of Chemical Weapons and Control, etc. of Specific Chemicals ; -',
    '化学兵器の禁止及び特定物質の規制等に関する法律 ; 化学兵器禁止法',
    'Act on Prohibition of Chemical Weapons and Control, etc. of Specific Chemicals'
  );
  static readonly LAW_ACT_ON_THE_CONTROL_OF_HOUSEHOLD_PRODUCTS_CONTAINING_HARMFUL_SUBSTANCES =
    new LawId(
      100170000,
      '有害物質を含有する家庭用品の規制に関する法律 ; 家庭用品規制法 ; Act on the Control of Household Products Containing Harmful Substances ; -',
      '有害物質を含有する家庭用品の規制に関する法律 ; 家庭用品規制法',
      'Act on the Control of Household Products Containing Harmful Substances'
    );
  static readonly LAW_HIGH_PRESSURE_GAS_SAFETY_ACT = new LawId(
    100180000,
    '高圧ガス保安法 ; - ; High Pressure Gas Safety Act ; -',
    '高圧ガス保安法',
    'High Pressure Gas Safety Act'
  );
  static readonly LAW_THE_PNEUMOCONIOSIS_LAW = new LawId(
    100190000,
    'じん肺法 ; - ; The Pneumoconiosis Law ; -',
    'じん肺法',
    'The Pneumoconiosis Law'
  );
  static readonly LAW_ETHANOL_BUSINESS_ACT = new LawId(
    100200000,
    'アルコール事業法 ; - ; Ethanol Business Act ; -',
    'アルコール事業法',
    'Ethanol Business Act'
  );
  static readonly LAW_LIQUOR_TAX_LAW = new LawId(
    100210000,
    '酒税法 ; - ; Liquor Tax Law ; -',
    '酒税法',
    'Liquor Tax Law'
  );
  static readonly LAW_ACT_ON_PROMOTION_OF_GLOBAL_WARMING_COUNTERMEASURES =
    new LawId(
      100220000,
      '地球温暖化対策の推進に関わる法律 ; - ; Act on Promotion of Global Warming Countermeasures ; -',
      '地球温暖化対策の推進に関わる法律',
      'Act on Promotion of Global Warming Countermeasures'
    );
  static readonly LAW_WATER_SUPPLY_ACT = new LawId(
    100230000,
    '水道法 ; - ; Water Supply Act ; -',
    '水道法',
    'Water Supply Act'
  );
  static readonly LAW_SEWERAGE_ACT = new LawId(
    100240000,
    '下水道法 ; - ; Sewerage Act ; -',
    '下水道法',
    'Sewerage Act'
  );
  static readonly LAW_LABOR_STANDARDS_ACT = new LawId(
    100250000,
    '労働基準法 ; - ; Labor Standards Act ; -',
    '労働基準法',
    'Labor Standards Act'
  );
  static readonly LAW_ROAD_ACT = new LawId(
    100260000,
    '道路法 ; - ; Road Act ; -',
    '道路法',
    'Road Act'
  );
  static readonly LAW_ACT_ON_CONTROL_OF_EXPORT_IMPORT_AND_OTHERS_OF_SPECIFIED_HAZARDOUS_WASTES_AND_OTHER_WASTES =
    new LawId(
      100270000,
      '特定有害廃棄物輸出入規制法（バーゼル法） ; - ; Act on Control of Export, Import and Others of Specified Hazardous Wastes and Other Wastes ; -',
      '特定有害廃棄物輸出入規制法（バーゼル法）',
      'Act on Control of Export, Import and Others of Specified Hazardous Wastes and Other Wastes'
    );
  static readonly LAW_ORDER_FOR_ENFORCEMENT_OF_THE_AGRICULTURAL_CHEMICALS_REGULATION_ACT =
    new LawId(
      100280000,
      '農薬取締法 ; - ; Order for Enforcement of the Agricultural Chemicals Regulation Act ; -',
      '農薬取締法',
      'Order for Enforcement of the Agricultural Chemicals Regulation Act'
    );
  static readonly LAW_OFFENSIVE_ODOR_CONTROL_ACT = new LawId(
    100290000,
    '悪臭防止法 ; - ; Offensive Odor Control Act ; -',
    '悪臭防止法',
    'Offensive Odor Control Act'
  );
  static readonly LAW_PMD_ACT = new LawId(
    100300000,
    '医薬品医療機器等法 ; 薬機法 ; Pharmaceutical and Medical Device Act ; PMD Act',
    '医薬品医療機器等法 ; 薬機法',
    'Pharmaceutical and Medical Device Act ; PMD Act'
  );
  static readonly LAW_EXPLOSIVES_CONTROL_ACT = new LawId(
    100310000,
    '火薬類取締法 ; - ; Explosives Control Act ; -',
    '火薬類取締法',
    'Explosives Control Act'
  );
  static readonly LAW_NARCOTICS_AND_PSYCHOTROPICS_CONTROL_ACT = new LawId(
    100320000,
    '麻薬及び向精神薬取締法 ; - ; Narcotics and Psychotropics Control Act ; -',
    '麻薬及び向精神薬取締法',
    'Narcotics and Psychotropics Control Act'
  );
  static readonly LAW_STIMULANTS_CONTROL_ACT = new LawId(
    100330000,
    '覚せい剤取締法 ; - ; Stimulants Control Act ; -',
    '覚せい剤取締法',
    'Stimulants Control Act'
  );
  static readonly LAW_JAS = new LawId(
    100340000,
    '日本農林規格等に関する法律 ; JAS法 ; Japanese Agricultural Standard ; JAS',
    '日本農林規格等に関する法律 ; JAS法',
    'Japanese Agricultural Standard ; JAS'
  );
  static readonly LAW_MEASUREMENT_ACT = new LawId(
    100350000,
    '計量法 ; - ; Measurement Act ; -',
    '計量法',
    'Measurement Act'
  );
  static readonly LAW_WORKING_ENVIRONMENT_MEASUREMENT_ACT = new LawId(
    100360000,
    '作業環境測定法 ; - ; Working Environment Measurement Act ; -',
    '作業環境測定法',
    'Working Environment Measurement Act'
  );
  static readonly LAW_CRUSHED_STONE_ACT = new LawId(
    100370000,
    '砕石法 ; - ; Crushed Stone Act ; -',
    '砕石法',
    'Crushed Stone Act'
  );
  static readonly LAW_ACT_ON_SAFETY_ASSURANCE = new LawId(
    100380000,
    '飼料の安全性の確保及び品質の改善に関する法律 ; 飼料安全法 ; Act on Safety Assurance and Quality Improvement of Feeds ; -',
    '飼料の安全性の確保及び品質の改善に関する法律 ; 飼料安全法',
    'Act on Safety Assurance and Quality Improvement of Feeds'
  );
  static readonly LAW_FERTILIZER_CONTROL_ACT = new LawId(
    100390000,
    '肥料の品質の確保等に関する法律 ; - ; Fertilizer Control Act ; -',
    '肥料の品質の確保等に関する法律',
    'Fertilizer Control Act'
  );
  static readonly LAW_MARINERS_LAW = new LawId(
    100400000,
    '船員法 ; - ; Mariners Law ; -',
    '船員法',
    'Mariners Law'
  );
  static readonly LAW_JSQI = new LawId(
    100410000,
    '医薬部外品原料規格 ; - ; Japanese Standards of Quasi-drug Ingredients ; JSQI',
    '医薬部外品原料規格',
    'Japanese Standards of Quasi-drug Ingredients ; JSQI'
  );
  static readonly LAW_JSCI = new LawId(
    100420000,
    '化粧品原料基準 ; - ; The Japanese Standards of Cosmetic Ingredients ; JSCI',
    '化粧品原料基準',
    'The Japanese Standards of Cosmetic Ingredients ; JSCI'
  );
  static readonly LAW_THE_PHARMACEUTICAL_AFFAIRS_LAW = new LawId(
    100430000,
    '薬事法 ; - ; The Pharmaceutical Affairs Law ; -',
    '薬事法',
    'The Pharmaceutical Affairs Law'
  );
  static readonly LAW_BUILDING_STANDARDS_ACT = new LawId(
    100440000,
    '建築基準法 ; - ; Building Standards Act ; -',
    '建築基準法',
    'Building Standards Act'
  );
  static readonly LAW_SALT_INDUSTRY_ACT = new LawId(
    100450000,
    '塩事業法 ; - ; Salt Industry Act ; -',
    '塩事業法',
    'Salt Industry Act'
  );
  static readonly LAW_ROAD_TRUCKING_VEHICLE_ACT = new LawId(
    100460000,
    '道路運送車両法 ; - ; Road Trucking Vehicle Act ; -',
    '道路運送車両法',
    'Road Trucking Vehicle Act'
  );
  static readonly LAW_THE_CONTAINERS_AND_PACKAGING_RECYCLING_LAW = new LawId(
    100470000,
    '包装容器リサイクル法 ; - ; The Containers and Packaging Recycling Law ; -',
    '包装容器リサイクル法',
    'The Containers and Packaging Recycling Law'
  );
  static readonly LAW_EXPLOSIVES_CONTROL_LAW = new LawId(
    100480000,
    '火薬取締法 ; - ; Explosives Control Law ; -',
    '火薬取締法',
    'Explosives Control Law'
  );
  static readonly LAW_IMDG = new LawId(
    100490000,
    '国際海上危険物規定 ; - ; International Maritime Dangerous Goods Code ; IMDG',
    '国際海上危険物規定',
    'International Maritime Dangerous Goods Code'
  );
  static readonly LAW_ICAO = new LawId(
    100500000,
    '国際民間航空機関 ; - ; International Civil Aviation Organization ; ICAO',
    '国際民間航空機関',
    'International Civil Aviation Organization'
  );
  static readonly LAW_FIRE_PREVENTION_PROPERTY = new LawId(
    100510000,
    '火災予防条例 ; - ; Fire Prevention Property ; -',
    '火災予防条例',
    'Fire Prevention Property'
  );
  static readonly LAW_MARPOL = new LawId(
    100520000,
    'マルポール条約 ; - ; International Convention for the Prevention of Pollution from Ships, 1973, as modified by the Protocol of 1978 relating thereto ; MARPOL',
    'マルポール条約',
    ''
  );
  static readonly LAW_ACT_CONCERNING_THE_RECOVERY_AND_DESTRUCTION_OF_FLUOROCARBONS =
    new LawId(
      100530000,
      '特定製品に係るフロン類の回収及び破壊の実施の確保等に関する法律 ; フロン回収破壊法 ; Act Concerning the Recovery and Destruction of Fluorocarbons ; -',
      '特定製品に係るフロン類の回収及び破壊の実施の確保等に関する法律',
      'Act Concerning the Recovery and Destruction of Fluorocarbons'
    );
  static readonly LAW_CWC = new LawId(
    100540000,
    '国際化学兵器禁止条約 ; - ; Chemical Weapons Convention ; CWC',
    '国際化学兵器禁止条約',
    'Chemical Weapons Convention'
  );
  static readonly LAW_ACT_TO_PREVENT_SOIL_CONTAMINATION_ON_AGRICULTURAL_LAND =
    new LawId(
      100550000,
      '農用地の土壌の汚染防止等に関する法律 ; 農用地汚染防止法 ; Act to Prevent Soil Contamination on Agricultural Land ; -',
      '農用地の土壌の汚染防止等に関する法律',
      'Act to Prevent Soil Contamination on Agricultural Land'
    );
  static readonly LAW_POPS = new LawId(
    100560000,
    '残留性有機汚染物質に関するストックホルム条約 ; POPs条約 ; Stockholm Convention on Persistent Organic Pollutants ; POPs',
    '残留性有機汚染物質に関するストックホルム条約',
    'Stockholm Convention on Persistent Organic Pollutants'
  );
  static readonly LAW_EXPORT_TRADE_CONTROL_ORDER = new LawId(
    100570000,
    '輸出貿易管理令 ; - ; Export Trade Control Order ; -',
    '輸出貿易管理令',
    'Export Trade Control Order'
  );
  static readonly LAW_OSHA = new LawId(
    100580000,
    '改正産業安全保健法 ; 改正OSHA ; - ; -',
    '改正産業安全保健法',
    'OSHA'
  );
  static readonly LAW_ACT_CONCERNING_THE_SECURING_OF_SAFETY_AND_THE_OPTIMIZATION_OF_TRANSACTION_OF_LIQUEFIED_PETROLEUM_GAS =
    new LawId(
      100590000,
      '液化石油ガスの保安の確保及び取引の適正化に関する法律 ; - ; Act Concerning the Securing of Safety and the Optimization of Transaction of Liquefied Petroleum Gas ; -',
      '液化石油ガスの保安の確保及び取引の適正化に関する法律',
      'Act Concerning the Securing of Safety and the Optimization of Transaction of Liquefied Petroleum Gas'
    );
  static readonly LAW_CUSTOMS_TARIFF_ACT = new LawId(
    100600000,
    '関税定率法 ; - ; Customs Tariff Act ; -',
    '関税定率法',
    'Customs Tariff Act'
  );
  static readonly LAW_FOOD_LABELING_ACT = new LawId(
    100610000,
    '食品表示法 ; - ; Food Labeling Act ; -',
    '食品表示法',
    'Food Labeling Act'
  );
  static readonly LAW_OPIUM_CONTROL_ACT = new LawId(
    100620000,
    'あへん法 ; - ; Opium Control Act ; -',
    'あへん法',
    'Opium Control Act'
  );
  static readonly LAW_ACT_CONCERNING_SPECIAL_PROVISIONS_FOR_THE_NARCOTICS =
    new LawId(
      100630000,
      '国際的な協力の下に規制薬物に係る不正行為を助長する行為等の防止を図るための麻薬及び向精神薬取締法等の特例等に関する法律 ; 麻薬特例法 ; - ; -',
      '国際的な協力の下に規制薬物に係る不正行為を助長する行為等の防止を図るための麻薬及び向精神薬取締法等の特例等に関する法律',
      'Act Concerning Special Provisions for the Narcotics and Psychotropics Control Act, etc. and Other Matters for the Prevention of Activities Encouraging Illicit Conducts and Other Activities Involving Controlled Substances through International Cooperation'
    );
  static readonly LAW_CANNABIS_CONTROL_ACT = new LawId(
    100640000,
    '大麻取締法 ; - ; Cannabis Control Act ; -',
    '大麻取締法',
    'Cannabis Control Act'
  );
  static readonly LAW_HOUSEHOLD_GOODS_QUALITY_LABELING_ACT = new LawId(
    100650000,
    '家庭用品品質表示法 ; - ; Household Goods Quality Labeling Act ; -',
    '家庭用品品質表示法',
    'Household Goods Quality Labeling Act'
  );
  static readonly LAW_HOME_APPLIANCE_RECYCLING_LAW = new LawId(
    100660000,
    '特定家庭用機器再商品化法 ; 家電リサイクル法 ; Act on Recycling of Specified Kinds of Home Appliance ; Home Appliance Recycling Law',
    '特定家庭用機器再商品化法',
    'Home Appliance Recycling Law'
  );
  static readonly LAW_MONTREAL_PROTOCOL_ON_SUBSTANCES_THAT_DEPLETE_THE_OZONE_LAYER =
    new LawId(
      100670000,
      'オゾン層を破壊する物質に関するモントリオール議定書 ; モントリオール議定書 ; Montreal Protocol on Substances that Deplete the Ozone Layer ; -',
      'オゾン層を破壊する物質に関するモントリオール議定書',
      'Montreal Protocol on Substances that Deplete the Ozone Layer'
    );
  static readonly LAW_PIC = new LawId(
    100680000,
    'ロッテルダム条約 ; - ; Prior Informed Consent ; PIC',
    'ロッテルダム条約',
    'PIC'
  );
  static readonly LAW_ORDINANCE_ON_PREVENTION_OF_HAZARDS_DUE_TO_DUST_MINISTRY_OF_LABOUR_ORDINANCE =
    new LawId(
      100690000,
      '粉塵障害防止規則 ; - ; Ordinance on Prevention of Hazards Due to Dust Ministry of Labour Ordinance ; -',
      '粉塵障害防止規則',
      ''
    );
  static readonly LAW_ACT_ON_TEMPORARY_MEASURES_CONCERNING_CUSTOMS = new LawId(
    100700000,
    '関税暫定措置法 ; - ; Act on Temporary Measures concerning Customs ; -',
    '関税暫定措置法',
    'Act on Temporary Measures concerning Customs'
  );

  private constructor(
    readonly value: number,
    readonly text: string,
    readonly textJp: string,
    readonly textEn: string
  ) {
    LawId._values.push(this);
  }

  static fromId(value: number): LawId {
    const res = LawId._values.find((x) => x.value === value);
    if (res) {
      return res;
    } else {
      return LawId.DEFAULT;
    }
  }

  static values(): LawId[] {
    return LawId._values;
  }
}

export class RegulationId {
  private static readonly _values: RegulationId[] = [];

  static readonly DEFAULT = new RegulationId(0, '', '', '', 0);
  static readonly REGULATION1_LAW_ENCS_0000 = new RegulationId(
    200000000,
    '第一種特定化学物質 ; Class I Specified Chemical Substances',
    '第一種特定化学物質',
    'Class I Specified Chemical Substances',
    100000000
  );
  static readonly REGULATION1_LAW_ENCS_0001 = new RegulationId(
    200000001,
    '第二種特定化学物質 ; Class II Specified Chemical Substances',
    '第二種特定化学物質',
    'Class II Specified Chemical Substances',
    100000000
  );
  static readonly REGULATION1_LAW_ENCS_0002 = new RegulationId(
    200000002,
    '監視化学物質 ; Monitoring Chemical Substances',
    '監視化学物質',
    'Monitoring Chemical Substances',
    100000000
  );
  static readonly REGULATION1_LAW_ENCS_0003 = new RegulationId(
    200000003,
    '優先評価化学物質 ; Priority Assessment Chemical Substances',
    '優先評価化学物質',
    'Priority Assessment Chemical Substances',
    100000000
  );
  static readonly REGULATION1_LAW_ENCS_0004 = new RegulationId(
    200000004,
    '（取消）優先評価化学物質 ; Canceled Priority Assessment Chemical Substances',
    '（取消）優先評価化学物質',
    'Canceled Priority Assessment Chemical Substances',
    100000000
  );
  static readonly REGULATION1_LAW_ENCS_0005 = new RegulationId(
    200000005,
    '新規公示化学物質 ; Newly Announced Chemical Substances',
    '新規公示化学物質',
    'Newly Announced Chemical Substances',
    100000000
  );
  static readonly REGULATION1_LAW_ENCS_0006 = new RegulationId(
    200000006,
    '新規公示化学物質（2011年4月1日以降届出） ; Newly Announced Chemical Substances (notified on and after April 1, 2011)',
    '新規公示化学物質（2011年4月1日以降届出）',
    'Newly Announced Chemical Substances (notified on and after April 1, 2011)',
    100000000
  );
  static readonly REGULATION1_LAW_ENCS_0007 = new RegulationId(
    200000007,
    '新規公示化学物質（2011年3月31日以前届出） ; Newly Announced Chemical Substances (notified by March 31, 2011)',
    '新規公示化学物質（2011年3月31日以前届出）',
    'Newly Announced Chemical Substances (notified by March 31, 2011)',
    100000000
  );
  static readonly REGULATION1_LAW_ENCS_0008 = new RegulationId(
    200000008,
    '既存化学物質 ; Existing Chemical Substances',
    '既存化学物質',
    'Existing Chemical Substances',
    100000000
  );
  static readonly REGULATION1_LAW_ENCS_0009 = new RegulationId(
    200000009,
    '旧第二種監視化学物質 ; Type II Monitoring Chemical Substances (before amendment)',
    '旧第二種監視化学物質',
    'Type II Monitoring Chemical Substances (before amendment)',
    100000000
  );
  static readonly REGULATION1_LAW_ENCS_0010 = new RegulationId(
    200000010,
    '旧第三種監視化学物質 ; Type III Monitoring Chemical Substances (before amendment)',
    '旧第三種監視化学物質',
    'Type III Monitoring Chemical Substances (before amendment)',
    100000000
  );
  static readonly REGULATION1_LAW_ENCS_0011 = new RegulationId(
    200000011,
    '製造輸入量の届出を要しない物質（届出不要物質） ; Chemical Substances exempted from notification of manufacturing or import amount',
    '製造輸入量の届出を要しない物質（届出不要物質）',
    'Chemical Substances exempted from notification of manufacturing or import amount',
    100000000
  );
  static readonly REGULATION1_LAW_ENCS_0012 = new RegulationId(
    200000012,
    '一般化学物質 ; ',
    '一般化学物質',
    '',
    100000000
  );
  static readonly REGULATION1_LAW_PRTR_0000 = new RegulationId(
    200010000,
    '指定化学物質',
    '指定化学物質',
    '',
    100010000
  );
  static readonly REGULATION1_LAW_PRTR_0001 = new RegulationId(
    200010001,
    '第一種指定化学物質 ; Class I',
    '第一種指定化学物質',
    'Class I',
    100010000
  );
  static readonly REGULATION1_LAW_PRTR_0002 = new RegulationId(
    200010002,
    '第二種指定化学物質 ; Class II',
    '第二種指定化学物質',
    'Class II',
    100010000
  );
  static readonly REGULATION1_LAW_PRTR_0003 = new RegulationId(
    200010003,
    '特定第一種指定化学物質 ; Specified Class I',
    '特定第一種指定化学物質',
    'Specified Class I',
    100010000
  );
  static readonly REGULATION1_LAW_PRTR_0004 = new RegulationId(
    200010004,
    '2023年3月31日まで　第一種指定化学物質 ; Class I (notified by March 31, 2023)',
    '2023年3月31日まで　第一種指定化学物質',
    'Class I (notified by March 31, 2023)',
    100010000
  );
  static readonly REGULATION1_LAW_PRTR_0005 = new RegulationId(
    200010005,
    '2023年3月31日まで　第二種指定化学物質 ; Class II (notified by March 31, 2023)',
    '2023年3月31日まで　第二種指定化学物質',
    'Class II (notified by March 31, 2023)',
    100010000
  );
  static readonly REGULATION1_LAW_PRTR_0006 = new RegulationId(
    200010006,
    '2023年3月31日まで　特定第一種指定化学物質 ; Specified Class I (notified by March 31, 2023)',
    '2023年3月31日まで　特定第一種指定化学物質',
    'Specified Class I (notified by March 31, 2023)',
    100010000
  );
  static readonly REGULATION1_LAW_PRTR_0007 = new RegulationId(
    200010007,
    '2023年4月1日から　第一種指定化学物質 ; Class I (notified on and after April 1, 2023)',
    '2023年4月1日から　第一種指定化学物質',
    'Class I (notified on and after April 1, 2023)',
    100010000
  );
  static readonly REGULATION1_LAW_PRTR_0008 = new RegulationId(
    200010008,
    '2023年4月1日から　第二種指定化学物質 ; Class II (notified on and after April 1, 2023)',
    '2023年4月1日から　第二種指定化学物質',
    'Class II (notified on and after April 1, 2023)',
    100010000
  );
  static readonly REGULATION1_LAW_PRTR_0009 = new RegulationId(
    200010009,
    '2023年4月1日から　特定第一種指定化学物質 ; Specified Class I (notified on and after April 1, 2023)',
    '2023年4月1日から　特定第一種指定化学物質',
    'Specified Class I (notified on and after April 1, 2023)',
    100010000
  );
  static readonly REGULATION1_LAW_PRTR_0010 = new RegulationId(
    200010010,
    '2023年3月31日まで',
    '2023年3月31日まで',
    '2023年3月31日まで',
    100010000
  );
  static readonly REGULATION1_LAW_PRTR_0011 = new RegulationId(
    200010011,
    '2023年4月1日から',
    '2023年4月1日から',
    '2023年4月1日から',
    100010000
  );
  static readonly REGULATION1_LAW_ISHL_0000 = new RegulationId(
    200020000,
    '既存化学物質（名称公表化学物質、新規名称公表化学物質） ; Existing Chemical Substances (including Newly Announced Chemical Substances)',
    '既存化学物質（名称公表化学物質、新規名称公表化学物質）',
    'Existing Chemical Substances (including Newly Announced Chemical Substances)',
    100020000
  );
  static readonly REGULATION1_LAW_ISHL_0001 = new RegulationId(
    200020001,
    '新規名称公表化学物質 ; Newly Announced Chemical Substances',
    '新規名称公表化学物質',
    'Newly Announced Chemical Substances',
    100020000
  );
  static readonly REGULATION1_LAW_ISHL_0002 = new RegulationId(
    200020002,
    '製造等が禁止される有害物等 ; Chemical Substances Prohibited to Manufacturing, etc',
    '製造等が禁止される有害物等',
    'Chemical Substances Prohibited to Manufacturing, etc',
    100020000
  );
  static readonly REGULATION1_LAW_ISHL_0003 = new RegulationId(
    200020003,
    '製造の許可を受けるべき有害物 ; Chemical Substances Requiring Permission for Manufacture, etc',
    '製造の許可を受けるべき有害物',
    'Chemical Substances Requiring Permission for Manufacture, etc',
    100020000
  );
  static readonly REGULATION1_LAW_ISHL_0004 = new RegulationId(
    200020004,
    '表示又は通知対象物質（名称等を表示し、又は通知すべき危険物及び有害物）_第57条・施行令第18条(別表第9) ; Chemical Substances Requiring Labeling and Delivery of Documents, etc.',
    '表示又は通知対象物質（名称等を表示し、又は通知すべき危険物及び有害物）_第57条・施行令第18条(別表第9)',
    'Chemical Substances Requiring Labeling and Delivery of Documents, etc.',
    100020000
  );
  static readonly REGULATION1_LAW_ISHL_0005 = new RegulationId(
    200020005,
    '第 28 条第３項 健康障害を防止するための指針（がん原性に係る指針対象物質） ; Guidelines for Carcinogenic Substances',
    '第 28 条第３項 健康障害を防止するための指針（がん原性に係る指針対象物質）',
    'Guidelines for Carcinogenic Substances',
    100020000
  );
  static readonly REGULATION1_LAW_ISHL_0006 = new RegulationId(
    200020006,
    '危険物 ; Dangerous',
    '危険物',
    '危険物 ; Dangerous',
    100020000
  );
  static readonly REGULATION1_LAW_ISHL_0007 = new RegulationId(
    200020007,
    '特定化学物質障害予防規則（特定化学物質等・特化則） ; Specified Chemical Substances',
    '特定化学物質障害予防規則（特定化学物質等・特化則）',
    'Specified Chemical Substances',
    100020000
  );
  static readonly REGULATION1_LAW_ISHL_0008 = new RegulationId(
    200020008,
    '有機溶剤中毒予防規則（有機溶剤等・有機則） ; Ordinance on Prevention of Organic Solvent Poisoning',
    '有機溶剤中毒予防規則（有機溶剤等・有機則）',
    'Ordinance on Prevention of Organic Solvent Poisoning',
    100020000
  );
  static readonly REGULATION1_LAW_ISHL_0009 = new RegulationId(
    200020009,
    '強い変異原性が認められた化学物質（変異原性:57条の4） ; Strong Mutagenic Chemical Substances',
    '強い変異原性が認められた化学物質（変異原性:57条の4）',
    'Strong Mutagenic Chemical Substances',
    100020000
  );
  static readonly REGULATION1_LAW_ISHL_0010 = new RegulationId(
    200020010,
    '変異原性が認められた化学物質（変異原性:57条の5） ; Mutagenic Chemical Substances',
    '変異原性が認められた化学物質（変異原性:57条の5）',
    'Mutagenic Chemical Substances',
    100020000
  );
  static readonly REGULATION1_LAW_ISHL_0011 = new RegulationId(
    200020011,
    '変異原性が認められた化学物質（変異原性:57条の5）既存化学物質 ; Mutagenic Chemical Substances',
    '変異原性が認められた化学物質（変異原性:57条の5）既存化学物質',
    'Mutagenic Chemical Substances',
    100020000
  );
  static readonly REGULATION1_LAW_ISHL_0012 = new RegulationId(
    200020012,
    '変異原性が認められた化学物質（変異原性:57条の5）新規届出化学物質 ; Mutagenic Chemical Substances',
    '変異原性が認められた化学物質（変異原性:57条の5）新規届出化学物質',
    'Mutagenic Chemical Substances',
    100020000
  );
  static readonly REGULATION1_LAW_ISHL_0013 = new RegulationId(
    200020013,
    '鉛等（鉛中毒予防規則） ; Ordinance on Prevention of Lead Poisoning',
    '鉛等（鉛中毒予防規則）',
    'Ordinance on Prevention of Lead Poisoning',
    100020000
  );
  static readonly REGULATION1_LAW_ISHL_0014 = new RegulationId(
    200020014,
    '四アルキル鉛等（四アルキル鉛中毒予防規則） ; Ordinance on Prevention of Tetraalkyl Lead Poisoning, etc',
    '四アルキル鉛等（四アルキル鉛中毒予防規則）',
    'Ordinance on Prevention of Tetraalkyl Lead Poisoning, etc',
    100020000
  );
  static readonly REGULATION1_LAW_ISHL_0015 = new RegulationId(
    200020015,
    '作業環境評価基準で定める管理濃度 ; Administrative Control Levels of Working Environment Evaluation Standards',
    '作業環境評価基準で定める管理濃度',
    'Administrative Control Levels of Working Environment Evaluation Standards',
    100020000
  );
  static readonly REGULATION1_LAW_ISHL_0016 = new RegulationId(
    200020016,
    '粉塵障害防止規則(粉塵則・粉じん障害防止規則・粉じん則) ; Ordinance on Prevention of Hazards due to Dust',
    '粉塵障害防止規則(粉塵則・粉じん障害防止規則・粉じん則)',
    'Ordinance on Prevention of Hazards due to Dust',
    100020000
  );
  static readonly REGULATION1_LAW_ISHL_0017 = new RegulationId(
    200020017,
    '腐食性液体 ; Corrosive Liquids',
    '腐食性液体',
    'Corrosive Liquids',
    100020000
  );
  static readonly REGULATION1_LAW_ISHL_0018 = new RegulationId(
    200020018,
    '健康診断対象物質 ; Substances Subject to Medical Examination',
    '健康診断対象物質',
    'Substances Subject to Medical Examination',
    100020000
  );
  static readonly REGULATION1_LAW_ISHL_0019 = new RegulationId(
    200020019,
    '酸素欠乏症防止規則 ; Ordinance on Prevention of Anoxia',
    '酸素欠乏症防止規則',
    'Ordinance on Prevention of Anoxia',
    100020000
  );
  static readonly REGULATION1_LAW_ISHL_0020 = new RegulationId(
    200020020,
    '事務所衛生基準規則 ; Ordinance on Health Standards in the Office',
    '事務所衛生基準規則',
    'Ordinance on Health Standards in the Office',
    100020000
  );
  static readonly REGULATION1_LAW_ISHL_0021 = new RegulationId(
    200020021,
    'リスクアセスメント対象物質 ; Substances requiring Risk Assessment',
    'リスクアセスメント対象物質',
    'Substances requiring Risk Assessment',
    100020000
  );
  static readonly REGULATION1_LAW_ISHL_0022 = new RegulationId(
    200020022,
    '危険有害化学物質等(規則第24条の14)',
    '危険有害化学物質等(規則第24条の14)',
    '危険有害化学物質等(規則第24条の14)',
    100020000
  );
  static readonly REGULATION1_LAW_ISHL_0023 = new RegulationId(
    200020023,
    '特定危険有害化学物質等(規則第24条の15)',
    '特定危険有害化学物質等(規則第24条の15)',
    '特定危険有害化学物質等(規則第24条の15)',
    100020000
  );
  static readonly REGULATION1_LAW_ISHL_0024 = new RegulationId(
    200020024,
    '皮膚等障害化学物質_皮膚吸収性有害物質 ; Skin Absorbable Hazardous Substances',
    '皮膚等障害化学物質_皮膚吸収性有害物質',
    'Skin Absorbable Hazardous Substances',
    100020000
  );
  static readonly REGULATION1_LAW_ISHL_0025 = new RegulationId(
    200020025,
    'がん原性物質（作業記録等の30年保存対象物質） ; Carcinogenicity',
    'がん原性物質（作業記録等の30年保存対象物質）',
    'Carcinogenicity',
    100020000
  );
  static readonly REGULATION1_LAW_ISHL_0026 = new RegulationId(
    200020026,
    '名称公表化学物質 ; Existing Chemical Substances',
    '名称公表化学物質',
    'Existing Chemical Substances',
    100020000
  );
  static readonly REGULATION1_LAW_ISHL_0027 = new RegulationId(
    200020027,
    'がん原性に係る指針対象物質（健康障害を防止するための指針） ; Guidelines for Carcinogenic Substances',
    'がん原性に係る指針対象物質（健康障害を防止するための指針）',
    'Guidelines for Carcinogenic Substances',
    100020000
  );
  static readonly REGULATION1_LAW_ISHL_0028 = new RegulationId(
    200020028,
    '特定化学物質等（特化則） ; Specified Chemical Substances',
    '特定化学物質等（特化則）',
    'Specified Chemical Substances',
    100020000
  );
  static readonly REGULATION1_LAW_ISHL_0029 = new RegulationId(
    200020029,
    '第一類特定化学物質 ; Class I',
    '第一類特定化学物質',
    'Class I',
    100020000
  );
  static readonly REGULATION1_LAW_ISHL_0030 = new RegulationId(
    200020030,
    '第二類特定化学物質 ; Class II',
    '第二類特定化学物質',
    'Class II',
    100020000
  );
  static readonly REGULATION1_LAW_ISHL_0031 = new RegulationId(
    200020031,
    '第三類特定化学物質 ; Class III',
    '第三類特定化学物質',
    'Class III',
    100020000
  );
  static readonly REGULATION1_LAW_ISHL_0032 = new RegulationId(
    200020032,
    '皮膚等障害化学物質等(規則 第594条の2 第1項)',
    '皮膚等障害化学物質等(規則 第594条の2 第1項)',
    '皮膚等障害化学物質等(規則 第594条の2 第1項)',
    100020000
  );
  static readonly REGULATION1_LAW_ISHL_0033 = new RegulationId(
    200020033,
    '（2024年 4月以降対象予定）表示又は通知対象物質（名称等を表示し、又は通知すべき危険物及び有害物）_第57条・施行令第18条(別表第9) ; Chemical Substances Requiring Labeling and Delivery of Documents, etc',
    '（2024年 4月以降対象予定）表示又は通知対象物質（名称等を表示し、又は通知すべき危険物及び有害物）_第57条・施行令第18条(別表第9)',
    'Chemical Substances Requiring Labeling and Delivery of Documents, etc',
    100020000
  );
  static readonly REGULATION1_LAW_ISHL_0034 = new RegulationId(
    200020034,
    '（2025年 4月以降対象予定）表示又は通知対象物質（名称等を表示し、又は通知すべき危険物及び有害物）_第57条・施行令第18条(別表第9) ; Chemical Substances Requiring Labeling and Delivery of Documents, etc',
    '（2025年 4月以降対象予定）表示又は通知対象物質（名称等を表示し、又は通知すべき危険物及び有害物）_第57条・施行令第18条(別表第9)',
    'Chemical Substances Requiring Labeling and Delivery of Documents, etc',
    100020000
  );
  static readonly REGULATION1_LAW_ISHL_0035 = new RegulationId(
    200020035,
    '（2026年 4月以降対象予定）表示又は通知対象物質（名称等を表示し、又は通知すべき危険物及び有害物）_第57条・施行令第18条(別表第9) ; Chemical Substances Requiring Labeling and Delivery of Documents, etc',
    '（2026年 4月以降対象予定）表示又は通知対象物質（名称等を表示し、又は通知すべき危険物及び有害物）_第57条・施行令第18条(別表第9)',
    'Chemical Substances Requiring Labeling and Delivery of Documents, etc',
    100020000
  );
  static readonly REGULATION1_LAW_ISHL_0036 = new RegulationId(
    200020036,
    'エポキシ樹脂の硬化剤による健康障害防止',
    'エポキシ樹脂の硬化剤による健康障害防止',
    'エポキシ樹脂の硬化剤による健康障害防止',
    100020000
  );
  static readonly REGULATION1_LAW_ISHL_0037 = new RegulationId(
    200020037,
    '濃度基準値設定物質（化学物質による健康障害防止のための濃度の基準_労働安全衛生規則第577条の2）',
    '濃度基準値設定物質（化学物質による健康障害防止のための濃度の基準_労働安全衛生規則第577条の2）',
    '濃度基準値設定物質（化学物質による健康障害防止のための濃度の基準_労働安全衛生規則第577条の2）',
    100020000
  );
  static readonly REGULATION1_LAW_ISHL_0038 = new RegulationId(
    200020038,
    '労働基準局長通達基発第312号_変異原性が認められた化学物質による健康障害を防止するための指針',
    '労働基準局長通達基発第312号3の別添1_変異原性が認められた化学物質による健康障害を防止するための指針',
    '労働基準局長通達基発第312号3の別添1_変異原性が認められた化学物質による健康障害を防止するための指針',
    100020000
  );
  static readonly REGULATION1_LAW_ISHL_0039 = new RegulationId(
    200020039,
    '労働基準局長通達基発第348号_変異原性化学物質',
    '労働基準局長通達基発第348号の2_変異原性化学物質',
    '労働基準局長通達基発第348号の2_変異原性化学物質',
    100020000
  );
  static readonly REGULATION1_LAW_ISHL_0040 = new RegulationId(
    200020040,
    '労働基準局長通達基発第341号_変異原性が認められた化学物質',
    '労働基準局長通達基発第341号_変異原性が認められた化学物質',
    '労働基準局長通達基発第341号_変異原性が認められた化学物質',
    100020000
  );
  static readonly REGULATION1_LAW_ISHL_0041 = new RegulationId(
    200020041,
    '皮膚等障害化学物質_皮膚刺激性有害物質',
    '皮膚等障害化学物質_皮膚刺激性有害物質',
    '皮膚等障害化学物質_皮膚刺激性有害物質',
    100020000
  );
  static readonly REGULATION1_LAW_ISHL_0042 = new RegulationId(
    200020042,
    '事業者が行うべき調査等（第57条の3第1項）',
    '事業者が行うべき調査等（第57条の3第1項）',
    '事業者が行うべき調査等（第57条の3第1項）',
    100020000
  );
  static readonly REGULATION1_LAW_POISONOUS_AND_DELETERIOUS_SUBSTANCES_CONTROL_ACT_0000 =
    new RegulationId(
      200030000,
      '有機シアン化合物から除かれるもの ; Substances Excluded from Organic Cyanide Compounds',
      '有機シアン化合物から除かれるもの',
      'Substances Excluded from Organic Cyanide Compounds',
      100030000
    );
  static readonly REGULATION1_LAW_POISONOUS_AND_DELETERIOUS_SUBSTANCES_CONTROL_ACT_0001 =
    new RegulationId(
      200030001,
      '毒物（法令） ; Poisonous Substances',
      '毒物（法令）',
      'Poisonous Substances',
      100030000
    );
  static readonly REGULATION1_LAW_POISONOUS_AND_DELETERIOUS_SUBSTANCES_CONTROL_ACT_0002 =
    new RegulationId(
      200030002,
      '毒物（政令） ; Poisonous Substances',
      '毒物（政令）',
      'Poisonous Substances',
      100030000
    );
  static readonly REGULATION1_LAW_POISONOUS_AND_DELETERIOUS_SUBSTANCES_CONTROL_ACT_0003 =
    new RegulationId(
      200030003,
      '劇物（法令） ; Deleterious Substances',
      '劇物（法令）',
      'Deleterious Substances',
      100030000
    );
  static readonly REGULATION1_LAW_POISONOUS_AND_DELETERIOUS_SUBSTANCES_CONTROL_ACT_0004 =
    new RegulationId(
      200030004,
      '劇物（政令） ; Deleterious Substances',
      '劇物（政令）',
      'Deleterious Substances',
      100030000
    );
  static readonly REGULATION1_LAW_POISONOUS_AND_DELETERIOUS_SUBSTANCES_CONTROL_ACT_0005 =
    new RegulationId(
      200030005,
      '特定毒物（法令） ; Specified Poisonous Substances',
      '特定毒物（法令）',
      'Specified Poisonous Substances',
      100030000
    );
  static readonly REGULATION1_LAW_POISONOUS_AND_DELETERIOUS_SUBSTANCES_CONTROL_ACT_0006 =
    new RegulationId(
      200030006,
      '特定毒物（政令） ; Specified Poisonous Substances',
      '特定毒物（政令）',
      'Specified Poisonous Substances',
      100030000
    );
  static readonly REGULATION1_LAW_POISONOUS_AND_DELETERIOUS_SUBSTANCES_CONTROL_ACT_0007 =
    new RegulationId(
      200030007,
      '法律・毒物／政令・毒物 ; Poisonous Substances',
      '法律・毒物／政令・毒物',
      'Poisonous Substances',
      100030000
    );
  static readonly REGULATION1_LAW_POISONOUS_AND_DELETERIOUS_SUBSTANCES_CONTROL_ACT_0008 =
    new RegulationId(
      200030008,
      '法律・劇物／政令・劇物 ; Deleterious Substances',
      '法律・劇物／政令・劇物',
      'Deleterious Substances',
      100030000
    );
  static readonly REGULATION1_LAW_POISONOUS_AND_DELETERIOUS_SUBSTANCES_CONTROL_ACT_0009 =
    new RegulationId(
      200030009,
      '法律・特定毒物／政令・特定毒物 ; Specified Poisonous Substances',
      '法律・特定毒物／政令・特定毒物',
      'Specified Poisonous Substances',
      100030000
    );
  static readonly REGULATION1_LAW_POISONOUS_AND_DELETERIOUS_SUBSTANCES_CONTROL_ACT_0010 =
    new RegulationId(
      200030010,
      '毒物 ; Poisonous Substances',
      '毒物',
      'Poisonous Substances',
      100030000
    );
  static readonly REGULATION1_LAW_POISONOUS_AND_DELETERIOUS_SUBSTANCES_CONTROL_ACT_0011 =
    new RegulationId(
      200030011,
      '劇物 ; Deleterious Substances',
      '劇物',
      'Deleterious Substances',
      100030000
    );
  static readonly REGULATION1_LAW_POISONOUS_AND_DELETERIOUS_SUBSTANCES_CONTROL_ACT_0012 =
    new RegulationId(
      200030012,
      '特定毒物 ; Specified Poisonous Substances',
      '特定毒物',
      'Specified Poisonous Substances',
      100030000
    );
  static readonly REGULATION1_LAW_FIRE_SERVICE_ACT_0000 = new RegulationId(
    200040000,
    '第1類 ; Category1',
    '第1類',
    'Category1',
    100040000
  );
  static readonly REGULATION1_LAW_FIRE_SERVICE_ACT_0001 = new RegulationId(
    200040001,
    '第2類 ; Category2',
    '第2類',
    'Category2',
    100040000
  );
  static readonly REGULATION1_LAW_FIRE_SERVICE_ACT_0002 = new RegulationId(
    200040002,
    '第3類 ; Category3',
    '第3類',
    'Category3',
    100040000
  );
  static readonly REGULATION1_LAW_FIRE_SERVICE_ACT_0003 = new RegulationId(
    200040003,
    '第4類 ; Category4',
    '第4類',
    'Category4',
    100040000
  );
  static readonly REGULATION1_LAW_FIRE_SERVICE_ACT_0004 = new RegulationId(
    200040004,
    '第5類 ; Category5',
    '第5類',
    'Category5',
    100040000
  );
  static readonly REGULATION1_LAW_FIRE_SERVICE_ACT_0005 = new RegulationId(
    200040005,
    '第6類 ; Category6',
    '第6類',
    'Category6',
    100040000
  );
  static readonly REGULATION1_LAW_FIRE_SERVICE_ACT_0007 = new RegulationId(
    200040007,
    '指定可燃物 ; Specified Combustibles',
    '指定可燃物',
    'Specified Combustibles',
    100040000
  );
  static readonly REGULATION1_LAW_FIRE_SERVICE_ACT_0008 = new RegulationId(
    200040008,
    '貯蔵等の届出を要する物質（法第９条の３・危険物令第１条の１０） ; Article 9-3 Substances requiring notification of storage',
    '貯蔵等の届出を要する物質（法第９条の３・危険物令第１条の１０）',
    'Article 9-3 Substances requiring notification of storage',
    100040000
  );
  static readonly REGULATION1_LAW_FIRE_SERVICE_ACT_0009 = new RegulationId(
    200040009,
    '火災予防条例 ; Fire Prevention Ordinance',
    '火災予防条例',
    'Fire Prevention Ordinance',
    100040000
  );
  static readonly REGULATION1_LAW_FIRE_SERVICE_ACT_0010 = new RegulationId(
    200040010,
    '危険等級 I ; Hazard Class 1',
    '危険等級 I',
    'Hazard Class 1',
    100040000
  );
  static readonly REGULATION1_LAW_FIRE_SERVICE_ACT_0011 = new RegulationId(
    200040011,
    '危険等級 II ; Hazard Class 2',
    '危険等級 II',
    'Hazard Class 2',
    100040000
  );
  static readonly REGULATION1_LAW_FIRE_SERVICE_ACT_0012 = new RegulationId(
    200040012,
    '危険等級 III ; Hazard Class 3',
    '危険等級 III',
    'Hazard Class 3',
    100040000
  );
  static readonly REGULATION1_LAW_FIRE_SERVICE_ACT_0014 = new RegulationId(
    200040014,
    '非危険物 ; Non-Hazardous Material',
    '非危険物',
    'Non-Hazardous Material',
    100040000
  );
  static readonly REGULATION1_LAW_FOREIGN_EXCHANGE_AND_FOREIGN_TRADE_ACT_0000 =
    new RegulationId(
      200050000,
      '輸出貿易管理令別表第 1 の 16 の項 キャッチオール規制 ; Catch-All Controls (Export Trade Control Order Article 1 Appended table 1)',
      '輸出貿易管理令別表第 1 の 16 の項 キャッチオール規制',
      'Catch-All Controls (Export Trade Control Order Article 1 Appended table 1)',
      100050000
    );
  static readonly REGULATION1_LAW_FOREIGN_EXCHANGE_AND_FOREIGN_TRADE_ACT_0001 =
    new RegulationId(
      200050001,
      '輸出貿易管理令別表第二 輸出の承認 ; Export Trade Control Appended Table 2 (Export Approval)',
      '輸出貿易管理令別表第二 輸出の承認',
      'Export Trade Control Appended Table 2 (Export Approval)',
      100050000
    );
  static readonly REGULATION1_LAW_ACT_ON_THE_PROTECTION_OF_THE_OZONE_LAYER_0000 =
    new RegulationId(
      200060000,
      '特定物質 ; Specified substance',
      '特定物質',
      'Specified substance',
      100060000
    );
  static readonly REGULATION1_LAW_ACT_ON_THE_PROTECTION_OF_THE_OZONE_LAYER_0001 =
    new RegulationId(
      200060001,
      '特定物質代替物質 ; Alternatives',
      '特定物質代替物質',
      'Alternatives',
      100060000
    );
  static readonly REGULATION1_LAW_AIR_POLLUTION_CONTROL_ACT_0000 =
    new RegulationId(
      200070000,
      'ばい煙（有害物質） ; Soot and Smoke (Hazardous Substances)',
      'ばい煙（有害物質）',
      'Soot and Smoke (Hazardous Substances)',
      100070000
    );
  static readonly REGULATION1_LAW_AIR_POLLUTION_CONTROL_ACT_0001 =
    new RegulationId(
      200070001,
      'ばい煙 ; Soot and Smoke',
      'ばい煙',
      'Soot and Smoke',
      100070000
    );
  static readonly REGULATION1_LAW_AIR_POLLUTION_CONTROL_ACT_0002 =
    new RegulationId(
      200070002,
      '揮発性有機化合物（VOC） ; Volatile Organic Compound (VOC)',
      '揮発性有機化合物（VOC）',
      'Volatile Organic Compound (VOC)',
      100070000
    );
  static readonly REGULATION1_LAW_AIR_POLLUTION_CONTROL_ACT_0003 =
    new RegulationId(
      200070003,
      'VOCの除外物質 ; VOC Excluded Substances',
      'VOCの除外物質',
      'VOC Excluded Substances',
      100070000
    );
  static readonly REGULATION1_LAW_AIR_POLLUTION_CONTROL_ACT_0004 =
    new RegulationId(
      200070004,
      '特定粉じん ; Specified Particulates',
      '特定粉じん',
      'Specified Particulates',
      100070000
    );
  static readonly REGULATION1_LAW_AIR_POLLUTION_CONTROL_ACT_0005 =
    new RegulationId(
      200070005,
      '水銀等 ; Mercury and Its Compounds',
      '水銀等',
      'Mercury and Its Compounds',
      100070000
    );
  static readonly REGULATION1_LAW_AIR_POLLUTION_CONTROL_ACT_0006 =
    new RegulationId(
      200070006,
      '有害大気汚染物質（優先取組） ; Hazardous Air Pollutant (Priority Chemicals)',
      '有害大気汚染物質（優先取組）',
      'Hazardous Air Pollutant (Priority Chemicals)',
      100070000
    );
  static readonly REGULATION1_LAW_AIR_POLLUTION_CONTROL_ACT_0007 =
    new RegulationId(
      200070007,
      '有害大気汚染物質（指定物質） ; Hazardous Air Pollutant (Designated Substance)',
      '有害大気汚染物質（指定物質）',
      'Hazardous Air Pollutant (Designated Substance)',
      100070000
    );
  static readonly REGULATION1_LAW_AIR_POLLUTION_CONTROL_ACT_0008 =
    new RegulationId(
      200070008,
      '有害大気汚染物質 ; Hazardous Air Pollutant',
      '有害大気汚染物質',
      'Hazardous Air Pollutant',
      100070000
    );
  static readonly REGULATION1_LAW_AIR_POLLUTION_CONTROL_ACT_0009 =
    new RegulationId(
      200070009,
      '自動車排出ガス ; Automobile Exhaust',
      '自動車排出ガス',
      'Automobile Exhaust',
      100070000
    );
  static readonly REGULATION1_LAW_AIR_POLLUTION_CONTROL_ACT_0010 =
    new RegulationId(
      200070010,
      '特定物質 ; Specified Substance',
      '特定物質',
      'Specified Substance',
      100070000
    );
  static readonly REGULATION1_LAW_WATER_POLLUTION_PREVENTION_ACT_0000 =
    new RegulationId(
      200080000,
      '有害物質 ; Harmful Substances',
      '有害物質',
      'Harmful Substances',
      100080000
    );
  static readonly REGULATION1_LAW_WATER_POLLUTION_PREVENTION_ACT_0001 =
    new RegulationId(
      200080001,
      '指定物質 ; Designated Substances',
      '指定物質',
      'Designated Substances',
      100080000
    );
  static readonly REGULATION1_LAW_WATER_POLLUTION_PREVENTION_ACT_0002 =
    new RegulationId(
      200080002,
      '排出規制 ; Effluent Regulation',
      '排出規制',
      'Effluent Regulation',
      100080000
    );
  static readonly REGULATION1_LAW_WATER_POLLUTION_PREVENTION_ACT_0003 =
    new RegulationId(
      200080003,
      '油分排出規制 ; Oil Effluent Regulation',
      '油分排出規制',
      'Oil Effluent Regulation',
      100080000
    );
  static readonly REGULATION1_LAW_WATER_POLLUTION_PREVENTION_ACT_0004 =
    new RegulationId(
      200080004,
      '個別輸送（個品運送） ; Individual Transportation',
      '個別輸送（個品運送）',
      'Individual Transportation',
      100080000
    );
  static readonly REGULATION1_LAW_WATER_POLLUTION_PREVENTION_ACT_0005 =
    new RegulationId(
      200080005,
      '個品運送P ; Individual Transportation P',
      '個品運送P',
      'Individual Transportation P',
      100080000
    );
  static readonly REGULATION1_LAW_SOIL_CONTAMINATION_COUNTERMEASURES_ACT_0000 =
    new RegulationId(
      200090000,
      '特定有害物質 ; Designated Hazardous Substances',
      '特定有害物質',
      'Designated Hazardous Substances',
      100090000
    );
  static readonly REGULATION1_LAW_SOIL_CONTAMINATION_COUNTERMEASURES_ACT_0001 =
    new RegulationId(
      200090001,
      '第一種特定有害物質 ; Class 1 Designated Hazardous Substances',
      '第一種特定有害物質',
      'Class 1 Designated Hazardous Substances',
      100090000
    );
  static readonly REGULATION1_LAW_SOIL_CONTAMINATION_COUNTERMEASURES_ACT_0002 =
    new RegulationId(
      200090002,
      '第二種特定有害物質 ; Class 2 Designated Hazardous Substances',
      '第二種特定有害物質',
      'Class 2 Designated Hazardous Substances',
      100090000
    );
  static readonly REGULATION1_LAW_SOIL_CONTAMINATION_COUNTERMEASURES_ACT_0003 =
    new RegulationId(
      200090003,
      '第三種特定有害物質 ; Class 3 Designated Hazardous Substances',
      '第三種特定有害物質',
      'Class 3 Designated Hazardous Substances',
      100090000
    );
  static readonly REGULATION1_LAW_FOOD_SANITATION_ACT_0000 = new RegulationId(
    200100000,
    '基ポリマー（プラスチック） ; Base polymers (Plastics)',
    '基ポリマー（プラスチック）',
    'Base polymers (Plastics)',
    100100000
  );
  static readonly REGULATION1_LAW_FOOD_SANITATION_ACT_0001 = new RegulationId(
    200100001,
    '基ポリマー（コーティング等） ;  Base polymers (Coatings)',
    '基ポリマー（コーティング等）',
    'Base polymers (Coatings)',
    100100000
  );
  static readonly REGULATION1_LAW_FOOD_SANITATION_ACT_0002 = new RegulationId(
    200100002,
    '基ポリマー（微量モノマー） ; Base polymers (Trace amount monomer)',
    '基ポリマー（微量モノマー）',
    'Base polymers (Trace amount monomer)',
    100100000
  );
  static readonly REGULATION1_LAW_FOOD_SANITATION_ACT_0003 = new RegulationId(
    200100003,
    '基ポリマー ; Base polymers',
    '基ポリマー',
    'Base polymers',
    100100000
  );
  static readonly REGULATION1_LAW_FOOD_SANITATION_ACT_0004 = new RegulationId(
    200100004,
    '添加剤 ; Additives',
    '添加剤',
    'Additives',
    100100000
  );
  static readonly REGULATION1_LAW_FOOD_SANITATION_ACT_0005 = new RegulationId(
    200100005,
    '指定添加物 ; Additives',
    '指定添加物',
    'Additives',
    100100000
  );
  static readonly REGULATION1_LAW_FOOD_SANITATION_ACT_0006 = new RegulationId(
    200100006,
    '人の健康を損なう恐れのない添加物 ; Additives',
    '人の健康を損なう恐れのない添加物',
    'Additives',
    100100000
  );
  static readonly REGULATION1_LAW_FOOD_SANITATION_ACT_0007 = new RegulationId(
    200100007,
    '食品包装として使用する場合',
    '食品包装として使用する場合',
    '食品包装として使用する場合',
    100100000
  );
  static readonly REGULATION1_LAW_ACT_ON_PREVENTION_OF_MARINE_POLLUTION_0000 =
    new RegulationId(
      200110000,
      '有害液体物質（Z類物質） ; Harmful Liquid Substances (Z)',
      '有害液体物質（Z類物質）',
      'Harmful Liquid Substances (Z)',
      100110000
    );
  static readonly REGULATION1_LAW_SHIP_SAFETY_LAW_0000 = new RegulationId(
    200120000,
    '火薬類 ; Explosives',
    '火薬類',
    'Explosives',
    100120000
  );
  static readonly REGULATION1_LAW_SHIP_SAFETY_LAW_0001 = new RegulationId(
    200120001,
    '高圧ガス ; High-pressure gases',
    '高圧ガス',
    'High-pressure gases',
    100120000
  );
  static readonly REGULATION1_LAW_SHIP_SAFETY_LAW_0002 = new RegulationId(
    200120002,
    '引火性液体類 ; Inflammable liquids',
    '引火性液体類',
    'Inflammable liquids',
    100120000
  );
  static readonly REGULATION1_LAW_SHIP_SAFETY_LAW_0003 = new RegulationId(
    200120003,
    '可燃性物質類 ; Flammable substances',
    '可燃性物質類',
    'Flammable substances',
    100120000
  );
  static readonly REGULATION1_LAW_SHIP_SAFETY_LAW_0004 = new RegulationId(
    200120004,
    '酸化性物質類 ; Oxidizing Substances',
    '酸化性物質類',
    'Oxidizing Substances',
    100120000
  );
  static readonly REGULATION1_LAW_SHIP_SAFETY_LAW_0005 = new RegulationId(
    200120005,
    '毒物類 ; Poisons',
    '毒物類',
    'Poisons',
    100120000
  );
  static readonly REGULATION1_LAW_SHIP_SAFETY_LAW_0006 = new RegulationId(
    200120006,
    '放射性物質等 ; Radioactive substances',
    '放射性物質等',
    'Radioactive substances',
    100120000
  );
  static readonly REGULATION1_LAW_SHIP_SAFETY_LAW_0007 = new RegulationId(
    200120007,
    '腐食性物質 ; Corrosive substances',
    '腐食性物質',
    'Corrosive substances',
    100120000
  );
  static readonly REGULATION1_LAW_SHIP_SAFETY_LAW_0008 = new RegulationId(
    200120008,
    '有害性物質 ; Toxic substances',
    '有害性物質',
    'Toxic substances',
    100120000
  );
  static readonly REGULATION1_LAW_SHIP_SAFETY_LAW_0009 = new RegulationId(
    200120009,
    '危険物船舶運送及び貯蔵規則（危規則）; Regulations for the Carriage and Storage of Dangerous Goods in Ship',
    '危険物船舶運送及び貯蔵規則（危規則）; Regulations for the Carriage and Storage of Dangerous Goods in Ship',
    'Regulations for the Carriage and Storage of Dangerous Goods in Ship',
    100120000
  );
  static readonly REGULATION1_LAW_CIVIL_AERONAUTICS_ACT_0000 = new RegulationId(
    200130000,
    '第１号　火薬類 ; Class. 1 Explosives',
    '第１号　火薬類',
    'Class. 1 Explosives',
    100130000
  );
  static readonly REGULATION1_LAW_CIVIL_AERONAUTICS_ACT_0001 = new RegulationId(
    200130001,
    '第２号　高圧ガス（引火性ガス、毒性ガス、その他のガス） ; Class. 2 High pressure gases (flammable gases, toxic gases, other gases)',
    '第２号　高圧ガス（引火性ガス、毒性ガス、その他のガス）',
    'Class. 2 High pressure gases (flammable gases, toxic gases, other gases)',
    100130000
  );
  static readonly REGULATION1_LAW_CIVIL_AERONAUTICS_ACT_0002 = new RegulationId(
    200130002,
    '第３号　引火性液体 ; Class. 3 Inflammable liquids',
    '第３号　引火性液体',
    'Class. 3 Inflammable liquids',
    100130000
  );
  static readonly REGULATION1_LAW_CIVIL_AERONAUTICS_ACT_0003 = new RegulationId(
    200130003,
    '第４号　可燃性物質類（可燃性物質、自然発火性物質、水反応可燃性物質） ; Class. 4 Combustible substances (combustible substances, spontaneous combustible substances, water-reactive combustible substances)',
    '第４号　可燃性物質類（可燃性物質、自然発火性物質、水反応可燃性物質）',
    'Class. 4 Combustible substances (combustible substances, spontaneous combustible substances, water-reactive combustible substances)',
    100130000
  );
  static readonly REGULATION1_LAW_CIVIL_AERONAUTICS_ACT_0004 = new RegulationId(
    200130004,
    '第５号　酸化性物質類（酸化性物質、有機過酸化物） ; Class. 5 Oxidizing substances (oxidizing substances, organic peroxides)',
    '第５号　酸化性物質類（酸化性物質、有機過酸化物）',
    'Class. 5 Oxidizing substances (oxidizing substances, organic peroxides)',
    100130000
  );
  static readonly REGULATION1_LAW_CIVIL_AERONAUTICS_ACT_0005 = new RegulationId(
    200130005,
    '第６号　毒物類（毒物、病毒を移しやすい物質） ; Class. 6 PoisoClassus substances (poisoClassus substances, substances that can easily transfer disease or poison)',
    '第６号　毒物類（毒物、病毒を移しやすい物質）',
    ' Class. 6 PoisoClassus substances (poisoClassus substances, substances that can easily transfer disease or poison)',
    100130000
  );
  static readonly REGULATION1_LAW_CIVIL_AERONAUTICS_ACT_0006 = new RegulationId(
    200130006,
    '第７号　放射性物質等 ; Class. 7 Radioactive substances, etc.',
    '第７号　放射性物質等',
    'Class. 7 Radioactive substances, etc.',
    100130000
  );
  static readonly REGULATION1_LAW_CIVIL_AERONAUTICS_ACT_0007 = new RegulationId(
    200130007,
    '第８号　腐食性物質 ; Class. 8 Corrosive substances',
    '第８号　腐食性物質',
    'Class. 8 Corrosive substances',
    100130000
  );
  static readonly REGULATION1_LAW_CIVIL_AERONAUTICS_ACT_0008 = new RegulationId(
    200130008,
    '第９号　その他の有害物件 ; Class. 9 Other harmful substances',
    '第９号　その他の有害物件',
    'Class. 9 Other harmful substances',
    100130000
  );
  static readonly REGULATION1_LAW_CIVIL_AERONAUTICS_ACT_0009 = new RegulationId(
    200130009,
    '第１０号　凶器 ; Class. 10 Weapons',
    '第１０号　凶器',
    'Class. 10 Weapons',
    100130000
  );
  static readonly REGULATION1_LAW_PORT_REGULATIONS_ACT_0000 = new RegulationId(
    200140000,
    'その他の危険物・腐食性物質 ; Other Harmful or Corrosive Substances',
    'その他の危険物・腐食性物質',
    'Other Harmful or Corrosive Substances',
    100140000
  );
  static readonly REGULATION1_LAW_WASTE_MANAGEMENT_AND_PUBLIC_CLEANSING_ACT_0000 =
    new RegulationId(
      200150000,
      '特別管理産業廃棄物 ; Specially Controlled Industrial Waste',
      '特別管理産業廃棄物',
      'Specially Controlled Industrial Waste',
      100150000
    );
  static readonly REGULATION1_LAW_WASTE_MANAGEMENT_AND_PUBLIC_CLEANSING_ACT_0001 =
    new RegulationId(
      200150001,
      '産業廃棄物 ; Industrial waste',
      '産業廃棄物',
      'Industrial waste',
      100150000
    );
  static readonly REGULATION1_LAW_ACT_ON_THE_CONTROL_OF_HOUSEHOLD_PRODUCTS_CONTAINING_HARMFUL_SUBSTANCES_0000 =
    new RegulationId(
      200170000,
      '有害物質 ; Hazardous Substances',
      '有害物質',
      'Hazardous Substances',
      100170000
    );
  static readonly REGULATION1_LAW_ACT_ON_THE_CONTROL_OF_HOUSEHOLD_PRODUCTS_CONTAINING_HARMFUL_SUBSTANCES_0001 =
    new RegulationId(
      200170001,
      '特定芳香族アミン ; Specific Aromatic Amine',
      '特定芳香族アミン',
      'Specific Aromatic Amine',
      100170000
    );
  static readonly REGULATION1_LAW_WATER_SUPPLY_ACT_0000 = new RegulationId(
    200230000,
    '有害物質 ; Harmful Substances',
    '有害物質',
    'Harmful Substances',
    100230000
  );
  static readonly REGULATION1_LAW_SEWERAGE_ACT_0000 = new RegulationId(
    200240000,
    '鉱油類排出規制 ; Mineral oil emission regulations',
    '鉱油類排出規制',
    'Mineral oil emission regulations',
    100240000
  );
  static readonly REGULATION1_LAW_LABOR_STANDARDS_ACT_0000 = new RegulationId(
    200250000,
    'エポキシ樹脂の硬化剤による健康障害防止',
    'エポキシ樹脂の硬化剤による健康障害防止',
    'エポキシ樹脂の硬化剤による健康障害防止',
    100250000
  );
  static readonly REGULATION2_LAW_ISHL_0000 = new RegulationId(
    300020000,
    '発火性 ; Ignitable',
    '発火性',
    'Ignitable',
    100020000
  );
  static readonly REGULATION2_LAW_ISHL_0001 = new RegulationId(
    300020001,
    '酸化性 ; Oxidizing',
    '酸化性',
    'Oxidizing',
    100020000
  );
  static readonly REGULATION2_LAW_ISHL_0002 = new RegulationId(
    300020002,
    '可燃性ガス ; Flammable Gas',
    '可燃性ガス',
    'Flammable Gas',
    100020000
  );
  static readonly REGULATION2_LAW_ISHL_0003 = new RegulationId(
    300020003,
    '引火性 ; Dangerous Substances_Flammable',
    '引火性',
    'Dangerous Substances_Flammable',
    100020000
  );
  static readonly REGULATION2_LAW_ISHL_0004 = new RegulationId(
    300020004,
    '第一類特定化学物質 ; Class I',
    '第一類特定化学物質',
    'Class I',
    100020000
  );
  static readonly REGULATION2_LAW_ISHL_0005 = new RegulationId(
    300020005,
    '第二類特定化学物質 ; Class II',
    '第二類特定化学物質',
    'Class II',
    100020000
  );
  static readonly REGULATION2_LAW_ISHL_0006 = new RegulationId(
    300020006,
    '第三類特定化学物質 ; Class III',
    '第三類特定化学物質',
    'Class III',
    100020000
  );
  static readonly REGULATION2_LAW_ISHL_0007 = new RegulationId(
    300020007,
    '第一種有機溶剤 ; Class I',
    '第一種有機溶剤',
    'Class I',
    100020000
  );
  static readonly REGULATION2_LAW_ISHL_0008 = new RegulationId(
    300020008,
    '第二種有機溶剤 ; Class II',
    '第二種有機溶剤',
    'Class II',
    100020000
  );
  static readonly REGULATION2_LAW_ISHL_0009 = new RegulationId(
    300020009,
    '第三種有機溶剤 ; Class III',
    '第三種有機溶剤',
    'Class III',
    100020000
  );
  static readonly REGULATION2_LAW_ISHL_0010 = new RegulationId(
    300020010,
    '既存化学物質 ; Strong Mutagenic Chemical Substances_Existing Chemical Substances',
    '既存化学物質',
    'Strong Mutagenic Chemical Substances_Existing Chemical Substances',
    100020000
  );
  static readonly REGULATION2_LAW_ISHL_0011 = new RegulationId(
    300020011,
    '新規届出化学物質 ; Newly Announced Chemical Substances',
    '新規届出化学物質',
    'Newly Announced Chemical Substances',
    100020000
  );
  static readonly REGULATION2_LAW_ISHL_0012 = new RegulationId(
    300020012,
    '爆発性 ; Explosive',
    '爆発性',
    'Explosive',
    100020000
  );
  static readonly REGULATION2_LAW_FIRE_SERVICE_ACT_0001 = new RegulationId(
    300040001,
    '自己反応性物質 ; Self-Reactive Substances',
    '自己反応性物質',
    'Self-Reactive Substances',
    100040000
  );
  static readonly REGULATION2_LAW_FIRE_SERVICE_ACT_0002 = new RegulationId(
    300040002,
    '酸化性固体 ; Oxidizing Solids',
    '酸化性固体',
    'Oxidizing Solids',
    100040000
  );
  static readonly REGULATION2_LAW_FIRE_SERVICE_ACT_0003 = new RegulationId(
    300040003,
    '酸化性液体 ; Oxidizing Liquids',
    '酸化性液体',
    'Oxidizing Liquids',
    100040000
  );
  static readonly REGULATION2_LAW_FIRE_SERVICE_ACT_0004 = new RegulationId(
    300040004,
    '可燃性固体 ; Flammable Solids',
    '可燃性固体',
    'Flammable Solids',
    100040000
  );
  static readonly REGULATION2_LAW_FIRE_SERVICE_ACT_0005 = new RegulationId(
    300040005,
    '引火性液体 ; Flammable Liquids',
    '引火性液体',
    'Flammable Liquids',
    100040000
  );
  static readonly REGULATION2_LAW_FIRE_SERVICE_ACT_0000 = new RegulationId(
    300040000,
    '自然発火性及び禁水性物質 ; Pyrophoric And Water-Prohibiting Substances',
    '自然発火性及び禁水性物質',
    'Pyrophoric And Water-Prohibiting Substances',
    100040000
  );
  static readonly REGULATION2_LAW_CIVIL_AERONAUTICS_ACT_0000 = new RegulationId(
    300130000,
    '施行規則第194条危険物告示別表第1',
    '施行規則第194条危険物告示別表第1',
    '施行規則第194条危険物告示別表第1',
    100130000
  );
  static readonly REGULATION3_LAW_FIRE_SERVICE_ACT_0000 = new RegulationId(
    400040000,
    '第1種 ; Class1',
    '第1種',
    'Class1',
    100040000
  );
  static readonly REGULATION3_LAW_FIRE_SERVICE_ACT_0001 = new RegulationId(
    400040001,
    '第2種 ; Class2',
    '第2種',
    'Class2',
    100040000
  );
  static readonly REGULATION3_LAW_FIRE_SERVICE_ACT_0002 = new RegulationId(
    400040002,
    '第3種 ; Class3',
    '第3種',
    'Class3',
    100040000
  );
  static readonly REGULATION3_LAW_FIRE_SERVICE_ACT_0003 = new RegulationId(
    400040003,
    '特殊引火物 ; Special Inflammable Materials',
    '特殊引火物',
    'Special Inflammable Materials',
    100040000
  );
  static readonly REGULATION3_LAW_FIRE_SERVICE_ACT_0004 = new RegulationId(
    400040004,
    'アルコール類 ; Alcohols',
    'アルコール類',
    'Alcohols',
    100040000
  );
  static readonly REGULATION3_LAW_FIRE_SERVICE_ACT_0005 = new RegulationId(
    400040005,
    '第1石油類 ; Class I Petroleums',
    '第1石油類',
    'Class I Petroleums',
    100040000
  );
  static readonly REGULATION3_LAW_FIRE_SERVICE_ACT_0006 = new RegulationId(
    400040006,
    '第2石油類 ; Class II Petroleums',
    '第2石油類',
    'Class II Petroleums',
    100040000
  );
  static readonly REGULATION3_LAW_FIRE_SERVICE_ACT_0007 = new RegulationId(
    400040007,
    '第3石油類 ; Class III Petroleums',
    '第3石油類',
    'Class III Petroleums',
    100040000
  );
  static readonly REGULATION3_LAW_FIRE_SERVICE_ACT_0008 = new RegulationId(
    400040008,
    '第4石油類 ; Class IV Petroleums',
    '第4石油類',
    'Class IV Petroleums',
    100040000
  );
  static readonly REGULATION3_LAW_FIRE_SERVICE_ACT_0009 = new RegulationId(
    400040009,
    '動植物油類 ; Oil Extracted From Plants And Animals',
    '動植物油類',
    'Oil Extracted From Plants And Animals',
    100040000
  );
  static readonly REGULATION4_LAW_FIRE_SERVICE_ACT_0000 = new RegulationId(
    500040000,
    '非水溶性 ; Water-Insoluble',
    '非水溶性',
    'Water-Insoluble',
    100040000
  );
  static readonly REGULATION4_LAW_FIRE_SERVICE_ACT_0001 = new RegulationId(
    500040001,
    '水溶性 ; Water-Soluble',
    '水溶性',
    'Water-Soluble',
    100040000
  );

  private constructor(
    readonly value: number,
    readonly text: string,
    readonly textJp: string,
    readonly textEn: string,
    readonly lawId: number
  ) {
    RegulationId._values.push(this);
  }

  static fromId(value: number): RegulationId {
    const res = RegulationId._values.find((x) => x.value === value);
    if (res) {
      return res;
    } else {
      return RegulationId.DEFAULT;
    }
  }

  static values(): RegulationId[] {
    return RegulationId._values;
  }
}

export class CompositionId {
  private static readonly _values: CompositionId[] = [];

  static readonly DEFAULT = new CompositionId(
    0,
    'なし ; None',
    'なし',
    ' None'
  );
  static readonly SUBSTANCE = new CompositionId(
    100,
    '単一製品・化学物質 ; Substance',
    '単一製品・化学物質',
    'Substance'
  );
  static readonly MIXTURES = new CompositionId(
    101,
    '混合物 ; Mixtures (preparation)',
    '混合物',
    'Mixtures (preparation)'
  );
  static readonly UVCB = new CompositionId(
    102,
    'UVCB物質 ; UVCB',
    'UVCB物質',
    'UVCB'
  );
  private constructor(
    readonly value: number,
    readonly text: string,
    readonly textJp: string,
    readonly textEn: string
  ) {
    CompositionId._values.push(this);
  }

  static fromId(value: number): CompositionId {
    const res = CompositionId._values.find((x) => x.value === value);
    if (res) {
      return res;
    } else {
      return CompositionId.DEFAULT;
    }
  }

  static values(): CompositionId[] {
    return CompositionId._values;
  }
}

export class TemparatureUnitId {
  private static readonly _values: TemparatureUnitId[] = [];

  static readonly CELSIUS = new TemparatureUnitId(100, '℃', '℃', '℃');
  static readonly FAHRENHEIT = new TemparatureUnitId(101, '℉', '℉', '℉');
  static readonly KELVIN = new TemparatureUnitId(102, 'K', 'K', 'K');

  private constructor(
    readonly value: number,
    readonly text: string,
    readonly textJp: string,
    readonly textEn: string
  ) {
    TemparatureUnitId._values.push(this);
  }

  static fromId(value: number): TemparatureUnitId {
    const res = TemparatureUnitId._values.find((x) => x.value === value);
    if (res) {
      return res;
    } else {
      return TemparatureUnitId.CELSIUS;
    }
  }

  static values(): CompositionId[] {
    return TemparatureUnitId._values;
  }
}

export class Section2GHSItemId {
  private static readonly _values: Section2GHSItemId[] = [];

  static readonly EXPLOSIVES = new Section2GHSItemId(
    1000000,
    '火薬類・爆発物 / Explosives',
    '火薬類・爆発物',
    'Explosives'
  );
  static readonly FLAMMABLE_GASES = new Section2GHSItemId(
    1001000,
    '可燃性・引火性ガス / Flammable gases （including chemically unstable gases）',
    '可燃性・引火性ガス',
    'Flammable gases （including chemically unstable gases）'
  );
  static readonly AEROSOLS = new Section2GHSItemId(
    1002000,
    '可燃性・引火性エアゾール / Aerosols',
    '可燃性・引火性エアゾール',
    'Aerosols'
  );
  static readonly OXIDIZING_GASES = new Section2GHSItemId(
    1003000,
    '支燃性・酸化性ガス / Oxidizing gases',
    '支燃性・酸化性ガス',
    'Oxidizing gases'
  );
  static readonly GASES_UNDER_PRESSURE = new Section2GHSItemId(
    1004000,
    '高圧ガス / Gases under pressure',
    '高圧ガス',
    'Gases under pressure'
  );
  static readonly FLAMMABLE_LIQUIDS = new Section2GHSItemId(
    1005000,
    '引火性液体 / Flammable liquids',
    '引火性液体',
    'Flammable liquids'
  );
  static readonly FLAMMABLE_SOLIDS = new Section2GHSItemId(
    1006000,
    '可燃性固体 / Flammable solids',
    '可燃性固体',
    'Flammable solids'
  );
  static readonly SELF_REACTIVE_SUBSTANCES_AND_MIXTURES = new Section2GHSItemId(
    1007000,
    '自己反応性化学品 / Self-reactive substances and mixtures',
    '自己反応性化学品',
    'Self-reactive substances and mixtures'
  );
  static readonly PYROPHORIC_LIQUIDS = new Section2GHSItemId(
    1008000,
    '自然発火性液体 / Pyrophoric liquids',
    '自然発火性液体',
    'Pyrophoric liquids'
  );
  static readonly PYROPHORIC_SOLIDS = new Section2GHSItemId(
    1009000,
    '自然発火性固体 / Pyrophoric solids',
    '自然発火性固体',
    'Pyrophoric solids'
  );
  static readonly SELF_HEATING_SUBSTANCES_AND_MIXTURES = new Section2GHSItemId(
    1010000,
    '自己発熱性化学品 / Self-heating substances and mixtures',
    '自己発熱性化学品',
    'Self-heating substances and mixtures'
  );
  static readonly SUBSTANCES_AND_MIXTURES = new Section2GHSItemId(
    1011000,
    '水反応可燃性化学品 / Substances and mixtures which, in contact with water, emit flamm',
    '水反応可燃性化学品',
    'Substances and mixtures which, in contact with water, emit flamm'
  );
  static readonly OXIDIZING_LIQUIDS = new Section2GHSItemId(
    1012000,
    '酸化性液体 / Oxidizing liquids',
    '酸化性液体',
    'Oxidizing liquids'
  );
  static readonly OXIDIZING_SOLIDS = new Section2GHSItemId(
    1013000,
    '酸化性固体 / Oxidizing solids',
    '酸化性固体',
    'Oxidizing solids'
  );
  static readonly ORGANIC_PEROXIDES = new Section2GHSItemId(
    1014000,
    '有機過酸化物 / Organic peroxides',
    '有機過酸化物',
    'Organic peroxides'
  );
  static readonly CORROSIVE_TO_METALS = new Section2GHSItemId(
    1015000,
    '金属腐食性物質 / Corrosive to metals',
    '金属腐食性物質',
    'Corrosive to metals'
  );
  static readonly DESENSITIZED_EXPLOSIVES = new Section2GHSItemId(
    1016000,
    '鈍性化爆発物 / Desensitized explosives',
    '鈍性化爆発物',
    'Desensitized explosives'
  );
  static readonly ACUTE_TOXICITY_ORAL = new Section2GHSItemId(
    1017000,
    '急性毒性（経口） / Acute toxicity （Oral）',
    '急性毒性（経口）',
    'Acute toxicity （Oral）'
  );
  static readonly ACUTE_TOXICITY_DERMAL = new Section2GHSItemId(
    1018000,
    '急性毒性（経皮） / Acute toxicity （Dermal）',
    '急性毒性（経皮）',
    'Acute toxicity （Dermal）'
  );
  static readonly ACUTE_TOXICITY_INHALATION_GASES = new Section2GHSItemId(
    1019000,
    '急性毒性（吸入：気体・ガス） / Acute toxicity （Inhalation: Gases）',
    '急性毒性（吸入：気体・ガス）',
    'Acute toxicity （Inhalation: Gases）'
  );
  static readonly ACUTE_TOXICITY_INHALATION_VAPOURS = new Section2GHSItemId(
    1020000,
    '急性毒性（吸入：蒸気） / Acute toxicity （Inhalation: Vapours）',
    '急性毒性（吸入：蒸気）',
    'Acute toxicity （Inhalation: Vapours）'
  );
  static readonly ACUTE_TOXICITY_INHALATION_DUSTS = new Section2GHSItemId(
    1021000,
    '急性毒性（吸入：粉じん） / Acute toxicity （Inhalation: Dusts）',
    '急性毒性（吸入：粉じん）',
    'Acute toxicity （Inhalation: Dusts）'
  );
  static readonly ACUTE_TOXICITY_INHALATION_MISTS = new Section2GHSItemId(
    1022000,
    '急性毒性（吸入：ミスト） / Acute toxicity （Inhalation: Mists）',
    '急性毒性（吸入：ミスト）',
    'Acute toxicity （Inhalation: Mists）'
  );
  static readonly SKIN_CORROSION_IRRITATION = new Section2GHSItemId(
    1023000,
    '皮膚腐食性・刺激性 / Skin corrosion/irritation',
    '皮膚腐食性・刺激性',
    'Skin corrosion/irritation'
  );
  static readonly SERIOUS_EYE_DAMAGE = new Section2GHSItemId(
    1024000,
    '眼に対する重篤な損傷・眼刺激性 / Serious eye damage/eye irritation',
    '眼に対する重篤な損傷・眼刺激性',
    'Serious eye damage/eye irritation'
  );
  static readonly RESPIRATORY_SENSITIZATION = new Section2GHSItemId(
    1025000,
    '呼吸器感作性 / Respiratory sensitization',
    '呼吸器感作性',
    'Respiratory sensitization'
  );
  static readonly SKIN_SENSITIZATION = new Section2GHSItemId(
    1026000,
    '皮膚感作性 / Skin sensitization',
    '皮膚感作性',
    'Skin sensitization'
  );
  static readonly GERM_CELL_MUTAGENICITY = new Section2GHSItemId(
    1027000,
    '生殖細胞変異原性 / Germ cell mutagenicity',
    '生殖細胞変異原性',
    'Germ cell mutagenicity'
  );
  static readonly CARCINOGENICITY = new Section2GHSItemId(
    1028000,
    '発がん性 / Carcinogenicity',
    '発がん性',
    'Carcinogenicity'
  );
  static readonly REPRODUCTIVE_TOXICITY = new Section2GHSItemId(
    1029000,
    '生殖毒性 / Reproductive toxicity',
    '生殖毒性',
    'Reproductive toxicity'
  );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE =
    new Section2GHSItemId(
      1030000,
      '特定標的臓器・全身毒性（単回ばく露） / Specific target organ toxicity - Single exposure',
      '特定標的臓器・全身毒性（単回ばく露）',
      'Specific target organ toxicity - Single exposure'
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE =
    new Section2GHSItemId(
      1031000,
      '特定標的臓器・全身毒性（反復ばく露） / Specific target organ toxicity - Repeated exposure',
      '特定標的臓器・全身毒性（反復ばく露）',
      'Specific target organ toxicity - Repeated exposure'
    );
  static readonly ASPIRATION_HAZARD = new Section2GHSItemId(
    1032000,
    '吸引性呼吸器有害性・誤えん有害性 / Aspiration hazard',
    '吸引性呼吸器有害性・誤えん有害性',
    'Aspiration hazard'
  );
  static readonly HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_ACUTE =
    new Section2GHSItemId(
      1033000,
      '水生環境急性有害性（短期急性） / Hazardous to the aquatic environment (Acute)・aquatic toxicity (Acute)',
      '水生環境急性有害性（短期急性）',
      'Hazardous to the aquatic environment (Acute)・aquatic toxicity (Acute)'
    );
  static readonly HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_LONG_TERM =
    new Section2GHSItemId(
      1034000,
      '水生環境慢性有害性（長期慢性） / Hazardous to the aquatic environment (Long-term・Chronic)・aquatic toxicity (Long-term)',
      '水生環境慢性有害性（長期慢性）',
      'Hazardous to the aquatic environment (Long-term・Chronic)・aquatic toxicity (Long-term)'
    );
  static readonly HAZARDOUS_TO_THE_OZONE_LAYER = new Section2GHSItemId(
    1035000,
    'オゾン層への有害性 / Hazardous to the ozone layer',
    'オゾン層への有害性',
    'Hazardous to the ozone layer'
  );

  private constructor(
    readonly value: number,
    readonly text: string,
    readonly textJp: string,
    readonly textEn: string
  ) {
    Section2GHSItemId._values.push(this);
  }

  static fromId(value: number): Section2GHSItemId {
    const res = Section2GHSItemId._values.find((x) => x.value === value);
    if (res) {
      return res;
    } else {
      return Section2GHSItemId.EXPLOSIVES;
    }
  }

  static values(): Section2GHSItemId[] {
    return Section2GHSItemId._values;
  }
}

export class Section2GHSCategoryId {
  private static readonly _values: Section2GHSCategoryId[] = [];

  static readonly DEFAULT = new Section2GHSCategoryId(
    0,
    '分類できない・該当データなし ; Classification not possible (No data)',
    '分類できない・該当データなし',
    'Classification not possible (No data)',
    0
  );
  static readonly NOT_CATEGORIZABLE = new Section2GHSCategoryId(
    9999999,
    '区分外 ; Not classified',
    '区分外',
    'Not classified',
    0
  );
  static readonly EXPLOSIVES_UNSTABLE_EXPLOSIVE = new Section2GHSCategoryId(
    2000000,
    '不安定爆発物 ; Unstable Explosive',
    '不安定爆発物',
    'Unstable Explosive',
    Section2GHSItemId.EXPLOSIVES.value
  );
  static readonly EXPLOSIVES_DIVISION1_1 = new Section2GHSCategoryId(
    2000001,
    '等級 1.1 ; Division 1.1',
    '等級 1.1',
    'Division 1.1',
    Section2GHSItemId.EXPLOSIVES.value
  );
  static readonly EXPLOSIVES_DIVISION1_2 = new Section2GHSCategoryId(
    2000002,
    '等級 1.2 ; Division 1.2',
    '等級 1.2',
    'Division 1.2',
    Section2GHSItemId.EXPLOSIVES.value
  );
  static readonly EXPLOSIVES_DIVISION1_3 = new Section2GHSCategoryId(
    2000003,
    '等級 1.3 ; Division 1.3',
    '等級 1.3',
    'Division 1.3',
    Section2GHSItemId.EXPLOSIVES.value
  );
  static readonly EXPLOSIVES_DIVISION1_4 = new Section2GHSCategoryId(
    2000004,
    '等級 1.4 ; Division 1.4',
    '等級 1.4',
    'Division 1.4',
    Section2GHSItemId.EXPLOSIVES.value
  );
  static readonly EXPLOSIVES_DIVISION1_5 = new Section2GHSCategoryId(
    2000005,
    '等級 1.5 ; Division 1.5',
    '等級 1.5',
    'Division 1.5',
    Section2GHSItemId.EXPLOSIVES.value
  );
  static readonly EXPLOSIVES_DIVISION1_6 = new Section2GHSCategoryId(
    2000006,
    '等級 1.6 ; Division 1.6',
    '等級 1.6',
    'Division 1.6',
    Section2GHSItemId.EXPLOSIVES.value
  );
  static readonly FLAMMABLE_GASES_CATEGORY_1 = new Section2GHSCategoryId(
    2001000,
    '区分1 ; Category1',
    '区分1',
    'Category1',
    Section2GHSItemId.FLAMMABLE_GASES.value
  );
  static readonly FLAMMABLE_GASES_CATEGORY_1A = new Section2GHSCategoryId(
    2001001,
    '区分1A ; Category1A',
    '区分1A',
    'Category1A',
    Section2GHSItemId.FLAMMABLE_GASES.value
  );
  static readonly FLAMMABLE_GASES_CATEGORY_1B = new Section2GHSCategoryId(
    2001002,
    '区分1B ; Category1B',
    '区分1B',
    'Category1B',
    Section2GHSItemId.FLAMMABLE_GASES.value
  );
  static readonly FLAMMABLE_GASES_CATEGORY_1C = new Section2GHSCategoryId(
    2001003,
    '区分1C ; Category1C',
    '区分1C',
    'Category1C',
    Section2GHSItemId.FLAMMABLE_GASES.value
  );
  static readonly FLAMMABLE_GASES_CATEGORY_2 = new Section2GHSCategoryId(
    2001004,
    '区分2 ; Category2',
    '区分2',
    'Category2',
    Section2GHSItemId.FLAMMABLE_GASES.value
  );
  static readonly FLAMMABLE_GASES_CATEGORY_2A = new Section2GHSCategoryId(
    2001005,
    '区分2A ; Category2A',
    '区分2A',
    'Category2A',
    Section2GHSItemId.FLAMMABLE_GASES.value
  );
  static readonly FLAMMABLE_GASES_CATEGORY_2B = new Section2GHSCategoryId(
    2001006,
    '区分2B ; Category2B',
    '区分2B',
    'Category2B',
    Section2GHSItemId.FLAMMABLE_GASES.value
  );
  static readonly FLAMMABLE_GASES_CATEGORY_2C = new Section2GHSCategoryId(
    2001007,
    '区分2C ; Category2C',
    '区分2C',
    'Category2C',
    Section2GHSItemId.FLAMMABLE_GASES.value
  );
  static readonly FLAMMABLE_GASES_CATEGORY_3 = new Section2GHSCategoryId(
    2001008,
    '区分3 ; Category3',
    '区分3',
    'Category3',
    Section2GHSItemId.FLAMMABLE_GASES.value
  );
  static readonly FLAMMABLE_GASES_CATEGORY_3A = new Section2GHSCategoryId(
    2001009,
    '区分3A ; Category3A',
    '区分3A',
    'Category3A',
    Section2GHSItemId.FLAMMABLE_GASES.value
  );
  static readonly FLAMMABLE_GASES_CATEGORY_3B = new Section2GHSCategoryId(
    2001010,
    '区分3B ; Category3B',
    '区分3B',
    'Category3B',
    Section2GHSItemId.FLAMMABLE_GASES.value
  );
  static readonly FLAMMABLE_GASES_CATEGORY_3C = new Section2GHSCategoryId(
    2001011,
    '区分3C ; Category3C',
    '区分3C',
    'Category3C',
    Section2GHSItemId.FLAMMABLE_GASES.value
  );
  static readonly FLAMMABLE_GASES_CATEGORY_4 = new Section2GHSCategoryId(
    2001012,
    '区分4 ; Category4',
    '区分4',
    'Category4',
    Section2GHSItemId.FLAMMABLE_GASES.value
  );
  static readonly FLAMMABLE_GASES_CATEGORY_4A = new Section2GHSCategoryId(
    2001013,
    '区分4A ; Category4A',
    '区分4A',
    'Category4A',
    Section2GHSItemId.FLAMMABLE_GASES.value
  );
  static readonly FLAMMABLE_GASES_CATEGORY_4B = new Section2GHSCategoryId(
    2001014,
    '区分4B ; Category4B',
    '区分4B',
    'Category4B',
    Section2GHSItemId.FLAMMABLE_GASES.value
  );
  static readonly FLAMMABLE_GASES_CATEGORY_4C = new Section2GHSCategoryId(
    2001015,
    '区分4C ; Category4C',
    '区分4C',
    'Category4C',
    Section2GHSItemId.FLAMMABLE_GASES.value
  );
  static readonly FLAMMABLE_GASES_CATEGORY_5 = new Section2GHSCategoryId(
    2001016,
    '区分5 ; Category5',
    '区分5',
    'Category5',
    Section2GHSItemId.FLAMMABLE_GASES.value
  );
  static readonly FLAMMABLE_GASES_CATEGORY_5A = new Section2GHSCategoryId(
    2001017,
    '区分5A ; Category5A',
    '区分5A',
    'Category5A',
    Section2GHSItemId.FLAMMABLE_GASES.value
  );
  static readonly FLAMMABLE_GASES_CATEGORY_5B = new Section2GHSCategoryId(
    2001018,
    '区分5B ; Category5B',
    '区分5B',
    'Category5B',
    Section2GHSItemId.FLAMMABLE_GASES.value
  );
  static readonly FLAMMABLE_GASES_CATEGORY_5C = new Section2GHSCategoryId(
    2001019,
    '区分5C ; Category5C',
    '区分5C',
    'Category5C',
    Section2GHSItemId.FLAMMABLE_GASES.value
  );
  static readonly AEROSOLS_CATEGORY_1 = new Section2GHSCategoryId(
    2002000,
    '区分1 ; Category1',
    '区分1',
    'Category1',
    Section2GHSItemId.AEROSOLS.value
  );
  static readonly AEROSOLS_CATEGORY_1A = new Section2GHSCategoryId(
    2002001,
    '区分1A ; Category1A',
    '区分1A',
    'Category1A',
    Section2GHSItemId.AEROSOLS.value
  );
  static readonly AEROSOLS_CATEGORY_1B = new Section2GHSCategoryId(
    2002002,
    '区分1B ; Category1B',
    '区分1B',
    'Category1B',
    Section2GHSItemId.AEROSOLS.value
  );
  static readonly AEROSOLS_CATEGORY_1C = new Section2GHSCategoryId(
    2002003,
    '区分1C ; Category1C',
    '区分1C',
    'Category1C',
    Section2GHSItemId.AEROSOLS.value
  );
  static readonly AEROSOLS_CATEGORY_2 = new Section2GHSCategoryId(
    2002004,
    '区分2 ; Category2',
    '区分2',
    'Category2',
    Section2GHSItemId.AEROSOLS.value
  );
  static readonly AEROSOLS_CATEGORY_2A = new Section2GHSCategoryId(
    2002005,
    '区分2A ; Category2A',
    '区分2A',
    'Category2A',
    Section2GHSItemId.AEROSOLS.value
  );
  static readonly AEROSOLS_CATEGORY_2B = new Section2GHSCategoryId(
    2002006,
    '区分2B ; Category2B',
    '区分2B',
    'Category2B',
    Section2GHSItemId.AEROSOLS.value
  );
  static readonly AEROSOLS_CATEGORY_2C = new Section2GHSCategoryId(
    2002007,
    '区分2C ; Category2C',
    '区分2C',
    'Category2C',
    Section2GHSItemId.AEROSOLS.value
  );
  static readonly AEROSOLS_CATEGORY_3 = new Section2GHSCategoryId(
    2002008,
    '区分3 ; Category3',
    '区分3',
    'Category3',
    Section2GHSItemId.AEROSOLS.value
  );
  static readonly AEROSOLS_CATEGORY_3A = new Section2GHSCategoryId(
    2002009,
    '区分3A ; Category3A',
    '区分3A',
    'Category3A',
    Section2GHSItemId.AEROSOLS.value
  );
  static readonly AEROSOLS_CATEGORY_3B = new Section2GHSCategoryId(
    2002010,
    '区分3B ; Category3B',
    '区分3B',
    'Category3B',
    Section2GHSItemId.AEROSOLS.value
  );
  static readonly AEROSOLS_CATEGORY_3C = new Section2GHSCategoryId(
    2002011,
    '区分3C ; Category3C',
    '区分3C',
    'Category3C',
    Section2GHSItemId.AEROSOLS.value
  );
  static readonly AEROSOLS_CATEGORY_4 = new Section2GHSCategoryId(
    2002012,
    '区分4 ; Category4',
    '区分4',
    'Category4',
    Section2GHSItemId.AEROSOLS.value
  );
  static readonly AEROSOLS_CATEGORY_4A = new Section2GHSCategoryId(
    2002013,
    '区分4A ; Category4A',
    '区分4A',
    'Category4A',
    Section2GHSItemId.AEROSOLS.value
  );
  static readonly AEROSOLS_CATEGORY_4B = new Section2GHSCategoryId(
    2002014,
    '区分4B ; Category4B',
    '区分4B',
    'Category4B',
    Section2GHSItemId.AEROSOLS.value
  );
  static readonly AEROSOLS_CATEGORY_4C = new Section2GHSCategoryId(
    2002015,
    '区分4C ; Category4C',
    '区分4C',
    'Category4C',
    Section2GHSItemId.AEROSOLS.value
  );
  static readonly AEROSOLS_CATEGORY_5 = new Section2GHSCategoryId(
    2002016,
    '区分5 ; Category5',
    '区分5',
    'Category5',
    Section2GHSItemId.AEROSOLS.value
  );
  static readonly AEROSOLS_CATEGORY_5A = new Section2GHSCategoryId(
    2002017,
    '区分5A ; Category5A',
    '区分5A',
    'Category5A',
    Section2GHSItemId.AEROSOLS.value
  );
  static readonly AEROSOLS_CATEGORY_5B = new Section2GHSCategoryId(
    2002018,
    '区分5B ; Category5B',
    '区分5B',
    'Category5B',
    Section2GHSItemId.AEROSOLS.value
  );
  static readonly AEROSOLS_CATEGORY_5C = new Section2GHSCategoryId(
    2002019,
    '区分5C ; Category5C',
    '区分5C',
    'Category5C',
    Section2GHSItemId.AEROSOLS.value
  );
  static readonly OXIDIZING_GASES_CATEGORY_1 = new Section2GHSCategoryId(
    2003000,
    '区分1 ; Category1',
    '区分1',
    'Category1',
    Section2GHSItemId.OXIDIZING_GASES.value
  );
  static readonly OXIDIZING_GASES_CATEGORY_1A = new Section2GHSCategoryId(
    2003001,
    '区分1A ; Category1A',
    '区分1A',
    'Category1A',
    Section2GHSItemId.OXIDIZING_GASES.value
  );
  static readonly OXIDIZING_GASES_CATEGORY_1B = new Section2GHSCategoryId(
    2003002,
    '区分1B ; Category1B',
    '区分1B',
    'Category1B',
    Section2GHSItemId.OXIDIZING_GASES.value
  );
  static readonly OXIDIZING_GASES_CATEGORY_1C = new Section2GHSCategoryId(
    2003003,
    '区分1C ; Category1C',
    '区分1C',
    'Category1C',
    Section2GHSItemId.OXIDIZING_GASES.value
  );
  static readonly OXIDIZING_GASES_CATEGORY_2 = new Section2GHSCategoryId(
    2003004,
    '区分2 ; Category2',
    '区分2',
    'Category2',
    Section2GHSItemId.OXIDIZING_GASES.value
  );
  static readonly OXIDIZING_GASES_CATEGORY_2A = new Section2GHSCategoryId(
    2003005,
    '区分2A ; Category2A',
    '区分2A',
    'Category2A',
    Section2GHSItemId.OXIDIZING_GASES.value
  );
  static readonly OXIDIZING_GASES_CATEGORY_2B = new Section2GHSCategoryId(
    2003006,
    '区分2B ; Category2B',
    '区分2B',
    'Category2B',
    Section2GHSItemId.OXIDIZING_GASES.value
  );
  static readonly OXIDIZING_GASES_CATEGORY_2C = new Section2GHSCategoryId(
    2003007,
    '区分2C ; Category2C',
    '区分2C',
    'Category2C',
    Section2GHSItemId.OXIDIZING_GASES.value
  );
  static readonly OXIDIZING_GASES_CATEGORY_3 = new Section2GHSCategoryId(
    2003008,
    '区分3 ; Category3',
    '区分3',
    'Category3',
    Section2GHSItemId.OXIDIZING_GASES.value
  );
  static readonly OXIDIZING_GASES_CATEGORY_3A = new Section2GHSCategoryId(
    2003009,
    '区分3A ; Category3A',
    '区分3A',
    'Category3A',
    Section2GHSItemId.OXIDIZING_GASES.value
  );
  static readonly OXIDIZING_GASES_CATEGORY_3B = new Section2GHSCategoryId(
    2003010,
    '区分3B ; Category3B',
    '区分3B',
    'Category3B',
    Section2GHSItemId.OXIDIZING_GASES.value
  );
  static readonly OXIDIZING_GASES_CATEGORY_3C = new Section2GHSCategoryId(
    2003011,
    '区分3C ; Category3C',
    '区分3C',
    'Category3C',
    Section2GHSItemId.OXIDIZING_GASES.value
  );
  static readonly OXIDIZING_GASES_CATEGORY_4 = new Section2GHSCategoryId(
    2003012,
    '区分4 ; Category4',
    '区分4',
    'Category4',
    Section2GHSItemId.OXIDIZING_GASES.value
  );
  static readonly OXIDIZING_GASES_CATEGORY_4A = new Section2GHSCategoryId(
    2003013,
    '区分4A ; Category4A',
    '区分4A',
    'Category4A',
    Section2GHSItemId.OXIDIZING_GASES.value
  );
  static readonly OXIDIZING_GASES_CATEGORY_4B = new Section2GHSCategoryId(
    2003014,
    '区分4B ; Category4B',
    '区分4B',
    'Category4B',
    Section2GHSItemId.OXIDIZING_GASES.value
  );
  static readonly OXIDIZING_GASES_CATEGORY_4C = new Section2GHSCategoryId(
    2003015,
    '区分4C ; Category4C',
    '区分4C',
    'Category4C',
    Section2GHSItemId.OXIDIZING_GASES.value
  );
  static readonly OXIDIZING_GASES_CATEGORY_5 = new Section2GHSCategoryId(
    2003016,
    '区分5 ; Category5',
    '区分5',
    'Category5',
    Section2GHSItemId.OXIDIZING_GASES.value
  );
  static readonly OXIDIZING_GASES_CATEGORY_5A = new Section2GHSCategoryId(
    2003017,
    '区分5A ; Category5A',
    '区分5A',
    'Category5A',
    Section2GHSItemId.OXIDIZING_GASES.value
  );
  static readonly OXIDIZING_GASES_CATEGORY_5B = new Section2GHSCategoryId(
    2003018,
    '区分5B ; Category5B',
    '区分5B',
    'Category5B',
    Section2GHSItemId.OXIDIZING_GASES.value
  );
  static readonly OXIDIZING_GASES_CATEGORY_5C = new Section2GHSCategoryId(
    2003019,
    '区分5C ; Category5C',
    '区分5C',
    'Category5C',
    Section2GHSItemId.OXIDIZING_GASES.value
  );
  static readonly GASES_UNDER_PRESSURE_COMPRESSEDGAS =
    new Section2GHSCategoryId(
      2004000,
      '圧縮ガス ; Compressed gas',
      '圧縮ガス',
      'Compressed gas',
      Section2GHSItemId.GASES_UNDER_PRESSURE.value
    );
  static readonly GASES_UNDER_PRESSURE_LIQUEFIEDGAS = new Section2GHSCategoryId(
    2004001,
    '液化ガス ; Liquefied gas',
    '液化ガス',
    'Liquefied gas',
    Section2GHSItemId.GASES_UNDER_PRESSURE.value
  );
  static readonly GASES_UNDER_PRESSURE_REFRIGERATEDLIQUEFIEDGAS =
    new Section2GHSCategoryId(
      2004002,
      '深冷液化ガス ; Refrigerated liquefied gas',
      '深冷液化ガス',
      'Refrigerated liquefied gas',
      Section2GHSItemId.GASES_UNDER_PRESSURE.value
    );
  static readonly GASES_UNDER_PRESSURE_DISSOLVEDGAS = new Section2GHSCategoryId(
    2004003,
    '溶解ガス ; Dissolved gas',
    '溶解ガス',
    'Dissolved gas',
    Section2GHSItemId.GASES_UNDER_PRESSURE.value
  );
  static readonly GASES_UNDER_PRESSURE_HIGH_PRESSURE =
    new Section2GHSCategoryId(
      2004004,
      '高圧ガス ; High Pressure gas',
      '高圧ガス',
      'High Pressure gas',
      Section2GHSItemId.GASES_UNDER_PRESSURE.value
    );
  static readonly FLAMMABLE_LIQUIDS_CATEGORY_1 = new Section2GHSCategoryId(
    2005000,
    '区分1 ; Category1',
    'Category1',
    'Category1',
    Section2GHSItemId.FLAMMABLE_LIQUIDS.value
  );
  static readonly FLAMMABLE_LIQUIDS_CATEGORY_1A = new Section2GHSCategoryId(
    2005001,
    '区分1A ; Category1A',
    '区分1A',
    'Category1A',
    Section2GHSItemId.FLAMMABLE_LIQUIDS.value
  );
  static readonly FLAMMABLE_LIQUIDS_CATEGORY_1B = new Section2GHSCategoryId(
    2005002,
    '区分1B ; Category1B',
    '区分1B',
    'Category1B',
    Section2GHSItemId.FLAMMABLE_LIQUIDS.value
  );
  static readonly FLAMMABLE_LIQUIDS_CATEGORY_1C = new Section2GHSCategoryId(
    2005003,
    '区分1C ; Category1C',
    '区分1C',
    'Category1C',
    Section2GHSItemId.FLAMMABLE_LIQUIDS.value
  );
  static readonly FLAMMABLE_LIQUIDS_CATEGORY_2 = new Section2GHSCategoryId(
    2005004,
    '区分2 ; Category2',
    '区分2',
    'Category2',
    Section2GHSItemId.FLAMMABLE_LIQUIDS.value
  );
  static readonly FLAMMABLE_LIQUIDS_CATEGORY_2A = new Section2GHSCategoryId(
    2005005,
    '区分2A ; Category2A',
    '区分2A',
    'Category2A',
    Section2GHSItemId.FLAMMABLE_LIQUIDS.value
  );
  static readonly FLAMMABLE_LIQUIDS_CATEGORY_2B = new Section2GHSCategoryId(
    2005006,
    '区分2B ; Category2B',
    '区分2B',
    'Category2B',
    Section2GHSItemId.FLAMMABLE_LIQUIDS.value
  );
  static readonly FLAMMABLE_LIQUIDS_CATEGORY_2C = new Section2GHSCategoryId(
    2005007,
    '区分2C ; Category2C',
    '区分2C',
    'Category2C',
    Section2GHSItemId.FLAMMABLE_LIQUIDS.value
  );
  static readonly FLAMMABLE_LIQUIDS_CATEGORY_3 = new Section2GHSCategoryId(
    2005008,
    '区分3 ; Category3',
    '区分3',
    'Category3',
    Section2GHSItemId.FLAMMABLE_LIQUIDS.value
  );
  static readonly FLAMMABLE_LIQUIDS_CATEGORY_3A = new Section2GHSCategoryId(
    2005009,
    '区分3A ; Category3A',
    '区分3A',
    'Category3A',
    Section2GHSItemId.FLAMMABLE_LIQUIDS.value
  );
  static readonly FLAMMABLE_LIQUIDS_CATEGORY_3B = new Section2GHSCategoryId(
    2005010,
    '区分3B ; Category3B',
    '区分3B',
    'Category3B',
    Section2GHSItemId.FLAMMABLE_LIQUIDS.value
  );
  static readonly FLAMMABLE_LIQUIDS_CATEGORY_3C = new Section2GHSCategoryId(
    2005011,
    '区分3C ; Category3C',
    '区分3C',
    'Category3C',
    Section2GHSItemId.FLAMMABLE_LIQUIDS.value
  );
  static readonly FLAMMABLE_LIQUIDS_CATEGORY_4 = new Section2GHSCategoryId(
    2005012,
    '区分4 ; Category4',
    '区分4',
    'Category4',
    Section2GHSItemId.FLAMMABLE_LIQUIDS.value
  );
  static readonly FLAMMABLE_LIQUIDS_CATEGORY_4A = new Section2GHSCategoryId(
    2005013,
    '区分4A ; Category4A',
    '区分4A',
    'Category4A',
    Section2GHSItemId.FLAMMABLE_LIQUIDS.value
  );
  static readonly FLAMMABLE_LIQUIDS_CATEGORY_4B = new Section2GHSCategoryId(
    2005014,
    '区分4B ; Category4B',
    '区分4B',
    'Category4B',
    Section2GHSItemId.FLAMMABLE_LIQUIDS.value
  );
  static readonly FLAMMABLE_LIQUIDS_CATEGORY_4C = new Section2GHSCategoryId(
    2005015,
    '区分4C ; Category4C',
    '区分4C',
    'Category4C',
    Section2GHSItemId.FLAMMABLE_LIQUIDS.value
  );
  static readonly FLAMMABLE_LIQUIDS_CATEGORY_5 = new Section2GHSCategoryId(
    2005016,
    '区分5 ; Category5',
    '区分5',
    'Category5',
    Section2GHSItemId.FLAMMABLE_LIQUIDS.value
  );
  static readonly FLAMMABLE_LIQUIDS_CATEGORY_5A = new Section2GHSCategoryId(
    2005017,
    '区分5A ; Category5A',
    '区分5A',
    'Category5A',
    Section2GHSItemId.FLAMMABLE_LIQUIDS.value
  );
  static readonly FLAMMABLE_LIQUIDS_CATEGORY_5B = new Section2GHSCategoryId(
    2005018,
    '区分5B ; Category5B',
    '区分5B',
    'Category5B',
    Section2GHSItemId.FLAMMABLE_LIQUIDS.value
  );
  static readonly FLAMMABLE_LIQUIDS_CATEGORY_5C = new Section2GHSCategoryId(
    2005019,
    '区分5C ; Category5C',
    '区分5C',
    'Category5C',
    Section2GHSItemId.FLAMMABLE_LIQUIDS.value
  );
  static readonly FLAMMABLE_SOLIDS_CATEGORY_1 = new Section2GHSCategoryId(
    2006000,
    '区分1 ; Category1',
    'Category1',
    'Category1',
    Section2GHSItemId.FLAMMABLE_SOLIDS.value
  );
  static readonly FLAMMABLE_SOLIDS_CATEGORY_1A = new Section2GHSCategoryId(
    2006001,
    '区分1A ; Category1A',
    '区分1A',
    'Category1A',
    Section2GHSItemId.FLAMMABLE_SOLIDS.value
  );
  static readonly FLAMMABLE_SOLIDS_CATEGORY_1B = new Section2GHSCategoryId(
    2006002,
    '区分1B ; Category1B',
    '区分1B',
    'Category1B',
    Section2GHSItemId.FLAMMABLE_SOLIDS.value
  );
  static readonly FLAMMABLE_SOLIDS_CATEGORY_1C = new Section2GHSCategoryId(
    2006003,
    '区分1C ; Category1C',
    '区分1C',
    'Category1C',
    Section2GHSItemId.FLAMMABLE_SOLIDS.value
  );
  static readonly FLAMMABLE_SOLIDS_CATEGORY_2 = new Section2GHSCategoryId(
    2006004,
    '区分2 ; Category2',
    '区分2',
    'Category2',
    Section2GHSItemId.FLAMMABLE_SOLIDS.value
  );
  static readonly FLAMMABLE_SOLIDS_CATEGORY_2A = new Section2GHSCategoryId(
    2006005,
    '区分2A ; Category2A',
    '区分2A',
    'Category2A',
    Section2GHSItemId.FLAMMABLE_SOLIDS.value
  );
  static readonly FLAMMABLE_SOLIDS_CATEGORY_2B = new Section2GHSCategoryId(
    2006006,
    '区分2B ; Category2B',
    '区分2B',
    'Category2B',
    Section2GHSItemId.FLAMMABLE_SOLIDS.value
  );
  static readonly FLAMMABLE_SOLIDS_CATEGORY_2C = new Section2GHSCategoryId(
    2006007,
    '区分2C ; Category2C',
    '区分2C',
    'Category2C',
    Section2GHSItemId.FLAMMABLE_SOLIDS.value
  );
  static readonly FLAMMABLE_SOLIDS_CATEGORY_3 = new Section2GHSCategoryId(
    2006008,
    '区分3 ; Category3',
    '区分3',
    'Category3',
    Section2GHSItemId.FLAMMABLE_SOLIDS.value
  );
  static readonly FLAMMABLE_SOLIDS_CATEGORY_3A = new Section2GHSCategoryId(
    2006009,
    '区分3A ; Category3A',
    '区分3A',
    'Category3A',
    Section2GHSItemId.FLAMMABLE_SOLIDS.value
  );
  static readonly FLAMMABLE_SOLIDS_CATEGORY_3B = new Section2GHSCategoryId(
    2006010,
    '区分3B ; Category3B',
    '区分3B',
    'Category3B',
    Section2GHSItemId.FLAMMABLE_SOLIDS.value
  );
  static readonly FLAMMABLE_SOLIDS_CATEGORY_3C = new Section2GHSCategoryId(
    2006011,
    '区分3C ; Category3C',
    '区分3C',
    'Category3C',
    Section2GHSItemId.FLAMMABLE_SOLIDS.value
  );
  static readonly FLAMMABLE_SOLIDS_CATEGORY_4 = new Section2GHSCategoryId(
    2006012,
    '区分4 ; Category4',
    '区分4',
    'Category4',
    Section2GHSItemId.FLAMMABLE_SOLIDS.value
  );
  static readonly FLAMMABLE_SOLIDS_CATEGORY_4A = new Section2GHSCategoryId(
    2006013,
    '区分4A ; Category4A',
    '区分4A',
    'Category4A',
    Section2GHSItemId.FLAMMABLE_SOLIDS.value
  );
  static readonly FLAMMABLE_SOLIDS_CATEGORY_4B = new Section2GHSCategoryId(
    2006014,
    '区分4B ; Category4B',
    '区分4B',
    'Category4B',
    Section2GHSItemId.FLAMMABLE_SOLIDS.value
  );
  static readonly FLAMMABLE_SOLIDS_CATEGORY_4C = new Section2GHSCategoryId(
    2006015,
    '区分4C ; Category4C',
    '区分4C',
    'Category4C',
    Section2GHSItemId.FLAMMABLE_SOLIDS.value
  );
  static readonly FLAMMABLE_SOLIDS_CATEGORY_5 = new Section2GHSCategoryId(
    2006016,
    '区分5 ; Category5',
    '区分5',
    'Category5',
    Section2GHSItemId.FLAMMABLE_SOLIDS.value
  );
  static readonly FLAMMABLE_SOLIDS_CATEGORY_5A = new Section2GHSCategoryId(
    2006017,
    '区分5A ; Category5A',
    '区分5A',
    'Category5A',
    Section2GHSItemId.FLAMMABLE_SOLIDS.value
  );
  static readonly FLAMMABLE_SOLIDS_CATEGORY_5B = new Section2GHSCategoryId(
    2006018,
    '区分5B ; Category5B',
    '区分5B',
    'Category5B',
    Section2GHSItemId.FLAMMABLE_SOLIDS.value
  );
  static readonly FLAMMABLE_SOLIDS_CATEGORY_5C = new Section2GHSCategoryId(
    2006019,
    '区分5C ; Category5C',
    '区分5C',
    'Category5C',
    Section2GHSItemId.FLAMMABLE_SOLIDS.value
  );
  static readonly SELF_REACTIVE_SUBSTANCES_AND_MIXTURES_TYPE_A =
    new Section2GHSCategoryId(
      2007000,
      'タイプ A ; TypeA',
      'タイプ A',
      'TypeA',
      Section2GHSItemId.SELF_REACTIVE_SUBSTANCES_AND_MIXTURES.value
    );
  static readonly SELF_REACTIVE_SUBSTANCES_AND_MIXTURES_TYPE_B =
    new Section2GHSCategoryId(
      2007001,
      'タイプ B ; TypeB',
      'タイプ B',
      'TypeB',
      Section2GHSItemId.SELF_REACTIVE_SUBSTANCES_AND_MIXTURES.value
    );
  static readonly SELF_REACTIVE_SUBSTANCES_AND_MIXTURES_TYPE_C =
    new Section2GHSCategoryId(
      2007002,
      'タイプ C ; TypeC',
      'タイプ C',
      'TypeC',
      Section2GHSItemId.SELF_REACTIVE_SUBSTANCES_AND_MIXTURES.value
    );
  static readonly SELF_REACTIVE_SUBSTANCES_AND_MIXTURES_TYPE_D =
    new Section2GHSCategoryId(
      2007003,
      'タイプ D ; TypeD',
      'タイプ D',
      'TypeD',
      Section2GHSItemId.SELF_REACTIVE_SUBSTANCES_AND_MIXTURES.value
    );
  static readonly SELF_REACTIVE_SUBSTANCES_AND_MIXTURES_TYPE_E =
    new Section2GHSCategoryId(
      2007004,
      'タイプ E ; TypeE',
      'タイプ E',
      'TypeE',
      Section2GHSItemId.SELF_REACTIVE_SUBSTANCES_AND_MIXTURES.value
    );
  static readonly SELF_REACTIVE_SUBSTANCES_AND_MIXTURES_TYPE_F =
    new Section2GHSCategoryId(
      2007005,
      'タイプ F ; TypeF',
      'タイプ F',
      'TypeF',
      Section2GHSItemId.SELF_REACTIVE_SUBSTANCES_AND_MIXTURES.value
    );
  static readonly SELF_REACTIVE_SUBSTANCES_AND_MIXTURES_TYPE_G =
    new Section2GHSCategoryId(
      2007006,
      'タイプ G ; TypeG',
      'タイプ G',
      'TypeG',
      Section2GHSItemId.SELF_REACTIVE_SUBSTANCES_AND_MIXTURES.value
    );
  static readonly PYROPHORIC_LIQUIDS_CATEGORY_1 = new Section2GHSCategoryId(
    2008000,
    '区分1 ; Category1',
    '区分1',
    'Category1',
    Section2GHSItemId.PYROPHORIC_LIQUIDS.value
  );
  static readonly PYROPHORIC_LIQUIDS_CATEGORY_1A = new Section2GHSCategoryId(
    2008001,
    '区分1A ; Category1A',
    '区分1A',
    'Category1A',
    Section2GHSItemId.PYROPHORIC_LIQUIDS.value
  );
  static readonly PYROPHORIC_LIQUIDS_CATEGORY_1B = new Section2GHSCategoryId(
    2008002,
    '区分1B ; Category1B',
    '区分1B',
    'Category1B',
    Section2GHSItemId.PYROPHORIC_LIQUIDS.value
  );
  static readonly PYROPHORIC_LIQUIDS_CATEGORY_1C = new Section2GHSCategoryId(
    2008003,
    '区分1C ; Category1C',
    '区分1C',
    'Category1C',
    Section2GHSItemId.PYROPHORIC_LIQUIDS.value
  );
  static readonly PYROPHORIC_LIQUIDS_CATEGORY_2 = new Section2GHSCategoryId(
    2008004,
    '区分2 ; Category2',
    '区分2',
    'Category2',
    Section2GHSItemId.PYROPHORIC_LIQUIDS.value
  );
  static readonly PYROPHORIC_LIQUIDS_CATEGORY_2A = new Section2GHSCategoryId(
    2008005,
    '区分2A ; Category2A',
    '区分2A',
    'Category2A',
    Section2GHSItemId.PYROPHORIC_LIQUIDS.value
  );
  static readonly PYROPHORIC_LIQUIDS_CATEGORY_2B = new Section2GHSCategoryId(
    2008006,
    '区分2B ; Category2B',
    '区分2B',
    'Category2B',
    Section2GHSItemId.PYROPHORIC_LIQUIDS.value
  );
  static readonly PYROPHORIC_LIQUIDS_CATEGORY_2C = new Section2GHSCategoryId(
    2008007,
    '区分2C ; Category2C',
    '区分2C',
    'Category2C',
    Section2GHSItemId.PYROPHORIC_LIQUIDS.value
  );
  static readonly PYROPHORIC_LIQUIDS_CATEGORY_3 = new Section2GHSCategoryId(
    2008008,
    '区分3 ; Category3',
    '区分3',
    'Category3',
    Section2GHSItemId.PYROPHORIC_LIQUIDS.value
  );
  static readonly PYROPHORIC_LIQUIDS_CATEGORY_3A = new Section2GHSCategoryId(
    2008009,
    '区分3A ; Category3A',
    '区分3A',
    'Category3A',
    Section2GHSItemId.PYROPHORIC_LIQUIDS.value
  );
  static readonly PYROPHORIC_LIQUIDS_CATEGORY_3B = new Section2GHSCategoryId(
    2008010,
    '区分3B ; Category3B',
    '区分3B',
    'Category3B',
    Section2GHSItemId.PYROPHORIC_LIQUIDS.value
  );
  static readonly PYROPHORIC_LIQUIDS_CATEGORY_3C = new Section2GHSCategoryId(
    2008011,
    '区分3C ; Category3C',
    '区分3C',
    'Category3C',
    Section2GHSItemId.PYROPHORIC_LIQUIDS.value
  );
  static readonly PYROPHORIC_LIQUIDS_CATEGORY_4 = new Section2GHSCategoryId(
    2008012,
    '区分4 ; Category4',
    '区分4',
    'Category4',
    Section2GHSItemId.PYROPHORIC_LIQUIDS.value
  );
  static readonly PYROPHORIC_LIQUIDS_CATEGORY_4A = new Section2GHSCategoryId(
    2008013,
    '区分4A ; Category4A',
    '区分4A',
    'Category4A',
    Section2GHSItemId.PYROPHORIC_LIQUIDS.value
  );
  static readonly PYROPHORIC_LIQUIDS_CATEGORY_4B = new Section2GHSCategoryId(
    2008014,
    '区分4B ; Category4B',
    '区分4B',
    'Category4B',
    Section2GHSItemId.PYROPHORIC_LIQUIDS.value
  );
  static readonly PYROPHORIC_LIQUIDS_CATEGORY_4C = new Section2GHSCategoryId(
    2008015,
    '区分4C ; Category4C',
    '区分4C',
    'Category4C',
    Section2GHSItemId.PYROPHORIC_LIQUIDS.value
  );
  static readonly PYROPHORIC_LIQUIDS_CATEGORY_5 = new Section2GHSCategoryId(
    2008016,
    '区分5 ; Category5',
    '区分5',
    'Category5',
    Section2GHSItemId.PYROPHORIC_LIQUIDS.value
  );
  static readonly PYROPHORIC_LIQUIDS_CATEGORY_5A = new Section2GHSCategoryId(
    2008017,
    '区分5 ; Category5A',
    '区分5A',
    'Category5A',
    Section2GHSItemId.PYROPHORIC_LIQUIDS.value
  );
  static readonly PYROPHORIC_LIQUIDS_CATEGORY_5B = new Section2GHSCategoryId(
    2008018,
    '区分5 ; Category5B',
    '区分5B',
    'Category5B',
    Section2GHSItemId.PYROPHORIC_LIQUIDS.value
  );
  static readonly PYROPHORIC_LIQUIDS_CATEGORY_5C = new Section2GHSCategoryId(
    2008019,
    '区分5 ; Category5C',
    '区分5C',
    'Category5C',
    Section2GHSItemId.PYROPHORIC_LIQUIDS.value
  );
  static readonly PYROPHORIC_SOLIDS_CATEGORY_1 = new Section2GHSCategoryId(
    2009000,
    '区分1 ; Category1',
    '区分1',
    'Category1',
    Section2GHSItemId.PYROPHORIC_SOLIDS.value
  );
  static readonly PYROPHORIC_SOLIDS_CATEGORY_1A = new Section2GHSCategoryId(
    2009001,
    '区分1A ; Category1A',
    '区分1A',
    'Category1A',
    Section2GHSItemId.PYROPHORIC_SOLIDS.value
  );
  static readonly PYROPHORIC_SOLIDS_CATEGORY_1B = new Section2GHSCategoryId(
    2009002,
    '区分1B ; Category1B',
    '区分1B',
    'Category1B',
    Section2GHSItemId.PYROPHORIC_SOLIDS.value
  );
  static readonly PYROPHORIC_SOLIDS_CATEGORY_1C = new Section2GHSCategoryId(
    2009003,
    '区分1C ; Category1C',
    '区分1C',
    'Category1C',
    Section2GHSItemId.PYROPHORIC_SOLIDS.value
  );
  static readonly PYROPHORIC_SOLIDS_CATEGORY_2 = new Section2GHSCategoryId(
    2009004,
    '区分2 ; Category2',
    '区分2',
    'Category2',
    Section2GHSItemId.PYROPHORIC_SOLIDS.value
  );
  static readonly PYROPHORIC_SOLIDS_CATEGORY_2A = new Section2GHSCategoryId(
    2009005,
    '区分2A ; Category2A',
    '区分2A',
    'Category2A',
    Section2GHSItemId.PYROPHORIC_SOLIDS.value
  );
  static readonly PYROPHORIC_SOLIDS_CATEGORY_2B = new Section2GHSCategoryId(
    2009006,
    '区分2B ; Category2B',
    '区分2B',
    'Category2B',
    Section2GHSItemId.PYROPHORIC_SOLIDS.value
  );
  static readonly PYROPHORIC_SOLIDS_CATEGORY_2C = new Section2GHSCategoryId(
    2009007,
    '区分2C ; Category2C',
    '区分2C',
    'Category2C',
    Section2GHSItemId.PYROPHORIC_SOLIDS.value
  );
  static readonly PYROPHORIC_SOLIDS_CATEGORY_3 = new Section2GHSCategoryId(
    2009008,
    '区分3 ; Category3',
    '区分3',
    'Category3',
    Section2GHSItemId.PYROPHORIC_SOLIDS.value
  );
  static readonly PYROPHORIC_SOLIDS_CATEGORY_3A = new Section2GHSCategoryId(
    2009009,
    '区分3A ; Category3A',
    '区分3A',
    'Category3A',
    Section2GHSItemId.PYROPHORIC_SOLIDS.value
  );
  static readonly PYROPHORIC_SOLIDS_CATEGORY_3B = new Section2GHSCategoryId(
    2009010,
    '区分3B ; Category3B',
    '区分3B',
    'Category3B',
    Section2GHSItemId.PYROPHORIC_SOLIDS.value
  );
  static readonly PYROPHORIC_SOLIDS_CATEGORY_3C = new Section2GHSCategoryId(
    2009011,
    '区分3C ; Category3C',
    '区分3C',
    'Category3C',
    Section2GHSItemId.PYROPHORIC_SOLIDS.value
  );
  static readonly PYROPHORIC_SOLIDS_CATEGORY_4 = new Section2GHSCategoryId(
    2009012,
    '区分4 ; Category4',
    '区分4',
    'Category4',
    Section2GHSItemId.PYROPHORIC_SOLIDS.value
  );
  static readonly PYROPHORIC_SOLIDS_CATEGORY_4A = new Section2GHSCategoryId(
    2009013,
    '区分4A ; Category4A',
    '区分4A',
    'Category4A',
    Section2GHSItemId.PYROPHORIC_SOLIDS.value
  );
  static readonly PYROPHORIC_SOLIDS_CATEGORY_4B = new Section2GHSCategoryId(
    2009014,
    '区分4B ; Category4B',
    '区分4B',
    'Category4B',
    Section2GHSItemId.PYROPHORIC_SOLIDS.value
  );
  static readonly PYROPHORIC_SOLIDS_CATEGORY_4C = new Section2GHSCategoryId(
    2009015,
    '区分4C ; Category4C',
    '区分4C',
    'Category4C',
    Section2GHSItemId.PYROPHORIC_SOLIDS.value
  );
  static readonly PYROPHORIC_SOLIDS_CATEGORY_5 = new Section2GHSCategoryId(
    2009016,
    '区分5 ; Category5',
    '区分5',
    'Category5',
    Section2GHSItemId.PYROPHORIC_SOLIDS.value
  );
  static readonly PYROPHORIC_SOLIDS_CATEGORY_5A = new Section2GHSCategoryId(
    2009017,
    '区分5A ; Category5A',
    '区分5A',
    'Category5A',
    Section2GHSItemId.PYROPHORIC_SOLIDS.value
  );
  static readonly PYROPHORIC_SOLIDS_CATEGORY_5B = new Section2GHSCategoryId(
    2009018,
    '区分5B ; Category5B',
    '区分5B',
    'Category5B',
    Section2GHSItemId.PYROPHORIC_SOLIDS.value
  );
  static readonly PYROPHORIC_SOLIDS_CATEGORY_5C = new Section2GHSCategoryId(
    2009019,
    '区分5C ; Category5C',
    '区分5C',
    'Category5C',
    Section2GHSItemId.PYROPHORIC_SOLIDS.value
  );
  static readonly SELF_HEATING_SUBSTANCES_AND_MIXTURES_CATEGORY_1 =
    new Section2GHSCategoryId(
      2010000,
      '区分1 ; Category1',
      '区分1',
      'Category1',
      Section2GHSItemId.SELF_HEATING_SUBSTANCES_AND_MIXTURES.value
    );
  static readonly SELF_HEATING_SUBSTANCES_AND_MIXTURES_CATEGORY_1A =
    new Section2GHSCategoryId(
      2010001,
      '区分1A ; Category1A',
      '区分1A',
      'Category1A',
      Section2GHSItemId.SELF_HEATING_SUBSTANCES_AND_MIXTURES.value
    );
  static readonly SELF_HEATING_SUBSTANCES_AND_MIXTURES_CATEGORY_1B =
    new Section2GHSCategoryId(
      2010002,
      '区分1B ; Category1B',
      '区分1B',
      'Category1B',
      Section2GHSItemId.SELF_HEATING_SUBSTANCES_AND_MIXTURES.value
    );
  static readonly SELF_HEATING_SUBSTANCES_AND_MIXTURES_CATEGORY_1C =
    new Section2GHSCategoryId(
      2010003,
      '区分1C ; Category1C',
      '区分1C',
      'Category1C',
      Section2GHSItemId.SELF_HEATING_SUBSTANCES_AND_MIXTURES.value
    );
  static readonly SELF_HEATING_SUBSTANCES_AND_MIXTURES_CATEGORY_2 =
    new Section2GHSCategoryId(
      2010004,
      '区分2 ; Category2',
      '区分2',
      'Category2',
      Section2GHSItemId.SELF_HEATING_SUBSTANCES_AND_MIXTURES.value
    );
  static readonly SELF_HEATING_SUBSTANCES_AND_MIXTURES_CATEGORY_2A =
    new Section2GHSCategoryId(
      2010005,
      '区分2A ; Category2A',
      '区分2A',
      'Category2A',
      Section2GHSItemId.SELF_HEATING_SUBSTANCES_AND_MIXTURES.value
    );
  static readonly SELF_HEATING_SUBSTANCES_AND_MIXTURES_CATEGORY_2B =
    new Section2GHSCategoryId(
      2010006,
      '区分2B ; Category2B',
      '区分2B',
      'Category2B',
      Section2GHSItemId.SELF_HEATING_SUBSTANCES_AND_MIXTURES.value
    );
  static readonly SELF_HEATING_SUBSTANCES_AND_MIXTURES_CATEGORY_2C =
    new Section2GHSCategoryId(
      2010007,
      '区分2C ; Category2C',
      '区分2C',
      'Category2C',
      Section2GHSItemId.SELF_HEATING_SUBSTANCES_AND_MIXTURES.value
    );
  static readonly SELF_HEATING_SUBSTANCES_AND_MIXTURES_CATEGORY_3 =
    new Section2GHSCategoryId(
      2010008,
      '区分3 ; Category3',
      '区分3',
      'Category3',
      Section2GHSItemId.SELF_HEATING_SUBSTANCES_AND_MIXTURES.value
    );
  static readonly SELF_HEATING_SUBSTANCES_AND_MIXTURES_CATEGORY_3A =
    new Section2GHSCategoryId(
      2010009,
      '区分3A ; Category3A',
      '区分3A',
      'Category3A',
      Section2GHSItemId.SELF_HEATING_SUBSTANCES_AND_MIXTURES.value
    );
  static readonly SELF_HEATING_SUBSTANCES_AND_MIXTURES_CATEGORY_3B =
    new Section2GHSCategoryId(
      2010010,
      '区分3B ; Category3B',
      '区分3B',
      'Category3B',
      Section2GHSItemId.SELF_HEATING_SUBSTANCES_AND_MIXTURES.value
    );
  static readonly SELF_HEATING_SUBSTANCES_AND_MIXTURES_CATEGORY_3C =
    new Section2GHSCategoryId(
      2010011,
      '区分3C ; Category3C',
      '区分3C',
      'Category3C',
      Section2GHSItemId.SELF_HEATING_SUBSTANCES_AND_MIXTURES.value
    );
  static readonly SELF_HEATING_SUBSTANCES_AND_MIXTURES_CATEGORY_4 =
    new Section2GHSCategoryId(
      2010012,
      '区分4 ; Category4',
      '区分4',
      'Category4',
      Section2GHSItemId.SELF_HEATING_SUBSTANCES_AND_MIXTURES.value
    );
  static readonly SELF_HEATING_SUBSTANCES_AND_MIXTURES_CATEGORY_4A =
    new Section2GHSCategoryId(
      2010013,
      '区分4A ; Category4A',
      '区分4A',
      'Category4A',
      Section2GHSItemId.SELF_HEATING_SUBSTANCES_AND_MIXTURES.value
    );
  static readonly SELF_HEATING_SUBSTANCES_AND_MIXTURES_CATEGORY_4B =
    new Section2GHSCategoryId(
      2010014,
      '区分4B ; Category4B',
      '区分4B',
      'Category4B',
      Section2GHSItemId.SELF_HEATING_SUBSTANCES_AND_MIXTURES.value
    );
  static readonly SELF_HEATING_SUBSTANCES_AND_MIXTURES_CATEGORY_4C =
    new Section2GHSCategoryId(
      2010015,
      '区分4C ; Category4C',
      '区分4C',
      'Category4C',
      Section2GHSItemId.SELF_HEATING_SUBSTANCES_AND_MIXTURES.value
    );
  static readonly SELF_HEATING_SUBSTANCES_AND_MIXTURES_CATEGORY_5 =
    new Section2GHSCategoryId(
      2010016,
      '区分5 ; Category5',
      '区分5',
      'Category5',
      Section2GHSItemId.SELF_HEATING_SUBSTANCES_AND_MIXTURES.value
    );
  static readonly SELF_HEATING_SUBSTANCES_AND_MIXTURES_CATEGORY_5A =
    new Section2GHSCategoryId(
      2010017,
      '区分5A ; Category5A',
      '区分5A',
      'Category5A',
      Section2GHSItemId.SELF_HEATING_SUBSTANCES_AND_MIXTURES.value
    );
  static readonly SELF_HEATING_SUBSTANCES_AND_MIXTURES_CATEGORY_5B =
    new Section2GHSCategoryId(
      2010018,
      '区分5B ; Category5B',
      '区分5B',
      'Category5B',
      Section2GHSItemId.SELF_HEATING_SUBSTANCES_AND_MIXTURES.value
    );
  static readonly SELF_HEATING_SUBSTANCES_AND_MIXTURES_CATEGORY_5C =
    new Section2GHSCategoryId(
      2010019,
      '区分5C ; Category5C',
      '区分5C',
      'Category5C',
      Section2GHSItemId.SELF_HEATING_SUBSTANCES_AND_MIXTURES.value
    );
  static readonly SUBSTANCES_AND_MIXTURES_CATEGORY_1 =
    new Section2GHSCategoryId(
      2011000,
      '区分1 ; Category1',
      '区分1',
      'Category1',
      Section2GHSItemId.SUBSTANCES_AND_MIXTURES.value
    );
  static readonly SUBSTANCES_AND_MIXTURES_CATEGORY_1A =
    new Section2GHSCategoryId(
      2011001,
      '区分1A ; Category1A',
      '区分1A',
      'Category1A',
      Section2GHSItemId.SUBSTANCES_AND_MIXTURES.value
    );
  static readonly SUBSTANCES_AND_MIXTURES_CATEGORY_1B =
    new Section2GHSCategoryId(
      2011002,
      '区分1B ; Category1B',
      '区分1B',
      'Category1B',
      Section2GHSItemId.SUBSTANCES_AND_MIXTURES.value
    );
  static readonly SUBSTANCES_AND_MIXTURES_CATEGORY_1C =
    new Section2GHSCategoryId(
      2011003,
      '区分1C ; Category1C',
      '区分1C',
      'Category1C',
      Section2GHSItemId.SUBSTANCES_AND_MIXTURES.value
    );
  static readonly SUBSTANCES_AND_MIXTURES_CATEGORY_2 =
    new Section2GHSCategoryId(
      2011004,
      '区分2 ; Category2',
      '区分2',
      'Category2',
      Section2GHSItemId.SUBSTANCES_AND_MIXTURES.value
    );
  static readonly SUBSTANCES_AND_MIXTURES_CATEGORY_2A =
    new Section2GHSCategoryId(
      2011005,
      '区分2A ; Category2A',
      '区分2A',
      'Category2A',
      Section2GHSItemId.SUBSTANCES_AND_MIXTURES.value
    );
  static readonly SUBSTANCES_AND_MIXTURES_CATEGORY_2B =
    new Section2GHSCategoryId(
      2011006,
      '区分2B ; Category2B',
      '区分2B',
      'Category2B',
      Section2GHSItemId.SUBSTANCES_AND_MIXTURES.value
    );
  static readonly SUBSTANCES_AND_MIXTURES_CATEGORY_2C =
    new Section2GHSCategoryId(
      2011007,
      '区分2C ; Category2C',
      '区分2C',
      'Category2C',
      Section2GHSItemId.SUBSTANCES_AND_MIXTURES.value
    );
  static readonly SUBSTANCES_AND_MIXTURES_CATEGORY_3 =
    new Section2GHSCategoryId(
      2011008,
      '区分3 ; Category3',
      '区分3',
      'Category3',
      Section2GHSItemId.SUBSTANCES_AND_MIXTURES.value
    );
  static readonly SUBSTANCES_AND_MIXTURES_CATEGORY_3A =
    new Section2GHSCategoryId(
      2011009,
      '区分3A ; Category3A',
      '区分3A',
      'Category3A',
      Section2GHSItemId.SUBSTANCES_AND_MIXTURES.value
    );
  static readonly SUBSTANCES_AND_MIXTURES_CATEGORY_3B =
    new Section2GHSCategoryId(
      2011010,
      '区分3B ; Category3B',
      '区分3B',
      'Category3B',
      Section2GHSItemId.SUBSTANCES_AND_MIXTURES.value
    );
  static readonly SUBSTANCES_AND_MIXTURES_CATEGORY_3C =
    new Section2GHSCategoryId(
      2011011,
      '区分3C ; Category3C',
      '区分3C',
      'Category3C',
      Section2GHSItemId.SUBSTANCES_AND_MIXTURES.value
    );
  static readonly SUBSTANCES_AND_MIXTURES_CATEGORY_4 =
    new Section2GHSCategoryId(
      2011012,
      '区分4 ; Category4',
      '区分4',
      'Category4',
      Section2GHSItemId.SUBSTANCES_AND_MIXTURES.value
    );
  static readonly SUBSTANCES_AND_MIXTURES_CATEGORY_4A =
    new Section2GHSCategoryId(
      2011013,
      '区分4A ; Category4A',
      '区分4A',
      'Category4A',
      Section2GHSItemId.SUBSTANCES_AND_MIXTURES.value
    );
  static readonly SUBSTANCES_AND_MIXTURES_CATEGORY_4B =
    new Section2GHSCategoryId(
      2011014,
      '区分4B ; Category4B',
      '区分4B',
      'Category4B',
      Section2GHSItemId.SUBSTANCES_AND_MIXTURES.value
    );
  static readonly SUBSTANCES_AND_MIXTURES_CATEGORY_4C =
    new Section2GHSCategoryId(
      2011015,
      '区分4C ; Category4C',
      '区分4C',
      'Category4C',
      Section2GHSItemId.SUBSTANCES_AND_MIXTURES.value
    );
  static readonly SUBSTANCES_AND_MIXTURES_CATEGORY_5 =
    new Section2GHSCategoryId(
      2011016,
      '区分5 ; Category5',
      '区分5',
      'Category5',
      Section2GHSItemId.SUBSTANCES_AND_MIXTURES.value
    );
  static readonly SUBSTANCES_AND_MIXTURES_CATEGORY_5A =
    new Section2GHSCategoryId(
      2011017,
      '区分5A ; Category5A',
      '区分5A',
      'Category5A',
      Section2GHSItemId.SUBSTANCES_AND_MIXTURES.value
    );
  static readonly SUBSTANCES_AND_MIXTURES_CATEGORY_5B =
    new Section2GHSCategoryId(
      2011018,
      '区分5B ; Category5B',
      '区分5B',
      'Category5B',
      Section2GHSItemId.SUBSTANCES_AND_MIXTURES.value
    );
  static readonly SUBSTANCES_AND_MIXTURES_CATEGORY_5C =
    new Section2GHSCategoryId(
      2011019,
      '区分5C ; Category5C',
      '区分5C',
      'Category5C',
      Section2GHSItemId.SUBSTANCES_AND_MIXTURES.value
    );
  static readonly OXIDIZING_LIQUIDS_CATEGORY_1 = new Section2GHSCategoryId(
    2012000,
    '区分1 ; Category1',
    '区分1',
    'Category1',
    Section2GHSItemId.OXIDIZING_LIQUIDS.value
  );
  static readonly OXIDIZING_LIQUIDS_CATEGORY_1A = new Section2GHSCategoryId(
    2012001,
    '区分1A ; Category1A',
    '区分1A',
    'Category1A',
    Section2GHSItemId.OXIDIZING_LIQUIDS.value
  );
  static readonly OXIDIZING_LIQUIDS_CATEGORY_1B = new Section2GHSCategoryId(
    2012002,
    '区分1B ; Category1B',
    '区分1B',
    'Category1B',
    Section2GHSItemId.OXIDIZING_LIQUIDS.value
  );
  static readonly OXIDIZING_LIQUIDS_CATEGORY_1C = new Section2GHSCategoryId(
    2012003,
    '区分1C ; Category1C',
    '区分1C',
    'Category1C',
    Section2GHSItemId.OXIDIZING_LIQUIDS.value
  );
  static readonly OXIDIZING_LIQUIDS_CATEGORY_2 = new Section2GHSCategoryId(
    2012004,
    '区分2 ; Category2',
    '区分2',
    'Category2',
    Section2GHSItemId.OXIDIZING_LIQUIDS.value
  );
  static readonly OXIDIZING_LIQUIDS_CATEGORY_2A = new Section2GHSCategoryId(
    2012005,
    '区分2A ; Category2A',
    '区分2A',
    'Category2A',
    Section2GHSItemId.OXIDIZING_LIQUIDS.value
  );
  static readonly OXIDIZING_LIQUIDS_CATEGORY_2B = new Section2GHSCategoryId(
    2012006,
    '区分2B ; Category2B',
    '区分2B',
    'Category2B',
    Section2GHSItemId.OXIDIZING_LIQUIDS.value
  );
  static readonly OXIDIZING_LIQUIDS_CATEGORY_2C = new Section2GHSCategoryId(
    2012007,
    '区分2C ; Category2C',
    '区分2C',
    'Category2C',
    Section2GHSItemId.OXIDIZING_LIQUIDS.value
  );
  static readonly OXIDIZING_LIQUIDS_CATEGORY_3 = new Section2GHSCategoryId(
    2012008,
    '区分3 ; Category3',
    '区分3',
    'Category3',
    Section2GHSItemId.OXIDIZING_LIQUIDS.value
  );
  static readonly OXIDIZING_LIQUIDS_CATEGORY_3A = new Section2GHSCategoryId(
    2012009,
    '区分3A ; Category3A',
    '区分3A',
    'Category3A',
    Section2GHSItemId.OXIDIZING_LIQUIDS.value
  );
  static readonly OXIDIZING_LIQUIDS_CATEGORY_3B = new Section2GHSCategoryId(
    2012010,
    '区分3B ; Category3B',
    '区分3B',
    'Category3B',
    Section2GHSItemId.OXIDIZING_LIQUIDS.value
  );
  static readonly OXIDIZING_LIQUIDS_CATEGORY_3C = new Section2GHSCategoryId(
    2012011,
    '区分3C ; Category3C',
    '区分3C',
    'Category3C',
    Section2GHSItemId.OXIDIZING_LIQUIDS.value
  );
  static readonly OXIDIZING_LIQUIDS_CATEGORY_4 = new Section2GHSCategoryId(
    2012012,
    '区分4 ; Category4',
    '区分4',
    'Category4',
    Section2GHSItemId.OXIDIZING_LIQUIDS.value
  );
  static readonly OXIDIZING_LIQUIDS_CATEGORY_4A = new Section2GHSCategoryId(
    2012013,
    '区分4A ; Category4A',
    '区分4A',
    'Category4A',
    Section2GHSItemId.OXIDIZING_LIQUIDS.value
  );
  static readonly OXIDIZING_LIQUIDS_CATEGORY_4B = new Section2GHSCategoryId(
    2012014,
    '区分4B ; Category4B',
    '区分4B',
    'Category4B',
    Section2GHSItemId.OXIDIZING_LIQUIDS.value
  );
  static readonly OXIDIZING_LIQUIDS_CATEGORY_4C = new Section2GHSCategoryId(
    2012015,
    '区分4C ; Category4C',
    '区分4C',
    'Category4C',
    Section2GHSItemId.OXIDIZING_LIQUIDS.value
  );
  static readonly OXIDIZING_LIQUIDS_CATEGORY_5 = new Section2GHSCategoryId(
    2012016,
    '区分5 ; Category5',
    '区分5',
    'Category5',
    Section2GHSItemId.OXIDIZING_LIQUIDS.value
  );
  static readonly OXIDIZING_LIQUIDS_CATEGORY_5A = new Section2GHSCategoryId(
    2012017,
    '区分5A ; Category5A',
    '区分5A',
    'Category5A',
    Section2GHSItemId.OXIDIZING_LIQUIDS.value
  );
  static readonly OXIDIZING_LIQUIDS_CATEGORY_5B = new Section2GHSCategoryId(
    2012018,
    '区分5B ; Category5B',
    '区分5B',
    'Category5B',
    Section2GHSItemId.OXIDIZING_LIQUIDS.value
  );
  static readonly OXIDIZING_LIQUIDS_CATEGORY_5C = new Section2GHSCategoryId(
    2012019,
    '区分5C ; Category5C',
    '区分5C',
    'Category5C',
    Section2GHSItemId.OXIDIZING_LIQUIDS.value
  );
  static readonly OXIDIZING_SOLIDS_CATEGORY_1 = new Section2GHSCategoryId(
    2013000,
    '区分1 ; Category1',
    '区分1',
    'Category1',
    Section2GHSItemId.OXIDIZING_SOLIDS.value
  );
  static readonly OXIDIZING_SOLIDS_CATEGORY_1A = new Section2GHSCategoryId(
    2013001,
    '区分1A ; Category1A',
    '区分1A',
    'Category1A',
    Section2GHSItemId.OXIDIZING_SOLIDS.value
  );
  static readonly OXIDIZING_SOLIDS_CATEGORY_1B = new Section2GHSCategoryId(
    2013002,
    '区分1B ; Category1B',
    '区分1B',
    'Category1B',
    Section2GHSItemId.OXIDIZING_SOLIDS.value
  );
  static readonly OXIDIZING_SOLIDS_CATEGORY_1C = new Section2GHSCategoryId(
    2013003,
    '区分1C ; Category1C',
    '区分1C',
    'Category1C',
    Section2GHSItemId.OXIDIZING_SOLIDS.value
  );
  static readonly OXIDIZING_SOLIDS_CATEGORY_2 = new Section2GHSCategoryId(
    2013004,
    '区分2 ; Category2',
    '区分2',
    'Category2',
    Section2GHSItemId.OXIDIZING_SOLIDS.value
  );
  static readonly OXIDIZING_SOLIDS_CATEGORY_2A = new Section2GHSCategoryId(
    2013005,
    '区分2A ; Category2A',
    '区分2A',
    'Category2A',
    Section2GHSItemId.OXIDIZING_SOLIDS.value
  );
  static readonly OXIDIZING_SOLIDS_CATEGORY_2B = new Section2GHSCategoryId(
    2013006,
    '区分2B ; Category2B',
    '区分2B',
    'Category2B',
    Section2GHSItemId.OXIDIZING_SOLIDS.value
  );
  static readonly OXIDIZING_SOLIDS_CATEGORY_2C = new Section2GHSCategoryId(
    2013007,
    '区分2C ; Category2C',
    '区分2C',
    'Category2C',
    Section2GHSItemId.OXIDIZING_SOLIDS.value
  );
  static readonly OXIDIZING_SOLIDS_CATEGORY_3 = new Section2GHSCategoryId(
    2013008,
    '区分3 ; Category3',
    '区分3',
    'Category3',
    Section2GHSItemId.OXIDIZING_SOLIDS.value
  );
  static readonly OXIDIZING_SOLIDS_CATEGORY_3A = new Section2GHSCategoryId(
    2013009,
    '区分3A ; Category3A',
    '区分3A',
    'Category3A',
    Section2GHSItemId.OXIDIZING_SOLIDS.value
  );
  static readonly OXIDIZING_SOLIDS_CATEGORY_3B = new Section2GHSCategoryId(
    2013010,
    '区分3B ; Category3B',
    '区分3B',
    'Category3B',
    Section2GHSItemId.OXIDIZING_SOLIDS.value
  );
  static readonly OXIDIZING_SOLIDS_CATEGORY_3C = new Section2GHSCategoryId(
    2013011,
    '区分3C ; Category3C',
    '区分3C',
    'Category3C',
    Section2GHSItemId.OXIDIZING_SOLIDS.value
  );
  static readonly OXIDIZING_SOLIDS_CATEGORY_4 = new Section2GHSCategoryId(
    2013012,
    '区分4 ; Category4',
    '区分4',
    'Category4',
    Section2GHSItemId.OXIDIZING_SOLIDS.value
  );
  static readonly OXIDIZING_SOLIDS_CATEGORY_4A = new Section2GHSCategoryId(
    2013013,
    '区分4A ; Category4A',
    '区分4A',
    'Category4A',
    Section2GHSItemId.OXIDIZING_SOLIDS.value
  );
  static readonly OXIDIZING_SOLIDS_CATEGORY_4B = new Section2GHSCategoryId(
    2013014,
    '区分4B ; Category4B',
    '区分4B',
    'Category4B',
    Section2GHSItemId.OXIDIZING_SOLIDS.value
  );
  static readonly OXIDIZING_SOLIDS_CATEGORY_4C = new Section2GHSCategoryId(
    2013015,
    '区分4C ; Category4C',
    '区分4C',
    'Category4C',
    Section2GHSItemId.OXIDIZING_SOLIDS.value
  );
  static readonly OXIDIZING_SOLIDS_CATEGORY_5 = new Section2GHSCategoryId(
    2013016,
    '区分5 ; Category5',
    '区分5',
    'Category5',
    Section2GHSItemId.OXIDIZING_SOLIDS.value
  );
  static readonly OXIDIZING_SOLIDS_CATEGORY_5A = new Section2GHSCategoryId(
    2013017,
    '区分5A ; Category5A',
    '区分5A',
    'Category5A',
    Section2GHSItemId.OXIDIZING_SOLIDS.value
  );
  static readonly OXIDIZING_SOLIDS_CATEGORY_5B = new Section2GHSCategoryId(
    2013018,
    '区分5B ; Category5B',
    '区分5B',
    'Category5B',
    Section2GHSItemId.OXIDIZING_SOLIDS.value
  );
  static readonly OXIDIZING_SOLIDS_CATEGORY_5C = new Section2GHSCategoryId(
    2013019,
    '区分5C ; Category5C',
    '区分5C',
    'Category5C',
    Section2GHSItemId.OXIDIZING_SOLIDS.value
  );
  static readonly ORGANIC_PEROXIDES_TYPEA = new Section2GHSCategoryId(
    2014000,
    'タイプ A ; TypeA',
    'タイプ A',
    'TypeA',
    Section2GHSItemId.ORGANIC_PEROXIDES.value
  );
  static readonly ORGANIC_PEROXIDES_TYPEB = new Section2GHSCategoryId(
    2014001,
    'タイプ B ; TypeB',
    'タイプ B',
    'TypeB',
    Section2GHSItemId.ORGANIC_PEROXIDES.value
  );
  static readonly ORGANIC_PEROXIDES_TYPEC = new Section2GHSCategoryId(
    2014002,
    'タイプ C ; TypeC',
    'タイプ C',
    'TypeC',
    Section2GHSItemId.ORGANIC_PEROXIDES.value
  );
  static readonly ORGANIC_PEROXIDES_TYPED = new Section2GHSCategoryId(
    2014003,
    'タイプ D ; TypeD',
    'タイプ D',
    'TypeD',
    Section2GHSItemId.ORGANIC_PEROXIDES.value
  );
  static readonly ORGANIC_PEROXIDES_TYPEE = new Section2GHSCategoryId(
    2014004,
    'タイプ E ; TypeE',
    'タイプ E',
    'TypeE',
    Section2GHSItemId.ORGANIC_PEROXIDES.value
  );
  static readonly ORGANIC_PEROXIDES_TYPEF = new Section2GHSCategoryId(
    2014005,
    'タイプ F ; TypeF',
    'タイプ F',
    'TypeF',
    Section2GHSItemId.ORGANIC_PEROXIDES.value
  );
  static readonly ORGANIC_PEROXIDES_TYPEG = new Section2GHSCategoryId(
    2014006,
    'タイプ G ; TypeG',
    'タイプ G',
    'TypeG',
    Section2GHSItemId.ORGANIC_PEROXIDES.value
  );
  static readonly CORROSIVE_TO_METALS_CATEGORY_1 = new Section2GHSCategoryId(
    2015000,
    '区分1 ; Category1',
    '区分1',
    'Category1',
    Section2GHSItemId.CORROSIVE_TO_METALS.value
  );
  static readonly CORROSIVE_TO_METALS_CATEGORY_1A = new Section2GHSCategoryId(
    2015001,
    '区分1A ; Category1A',
    '区分1A',
    'Category1A',
    Section2GHSItemId.CORROSIVE_TO_METALS.value
  );
  static readonly CORROSIVE_TO_METALS_CATEGORY_1B = new Section2GHSCategoryId(
    2015002,
    '区分1B ; Category1B',
    '区分1B',
    'Category1B',
    Section2GHSItemId.CORROSIVE_TO_METALS.value
  );
  static readonly CORROSIVE_TO_METALS_CATEGORY_1C = new Section2GHSCategoryId(
    2015003,
    '区分1C ; Category1C',
    '区分1C',
    'Category1C',
    Section2GHSItemId.CORROSIVE_TO_METALS.value
  );
  static readonly CORROSIVE_TO_METALS_CATEGORY_2 = new Section2GHSCategoryId(
    2015004,
    '区分2 ; Category2',
    '区分2',
    'Category2',
    Section2GHSItemId.CORROSIVE_TO_METALS.value
  );
  static readonly CORROSIVE_TO_METALS_CATEGORY_2A = new Section2GHSCategoryId(
    2015005,
    '区分2A ; Category2A',
    '区分2A',
    'Category2A',
    Section2GHSItemId.CORROSIVE_TO_METALS.value
  );
  static readonly CORROSIVE_TO_METALS_CATEGORY_2B = new Section2GHSCategoryId(
    2015006,
    '区分2B ; Category2B',
    '区分2B',
    'Category2B',
    Section2GHSItemId.CORROSIVE_TO_METALS.value
  );
  static readonly CORROSIVE_TO_METALS_CATEGORY_2C = new Section2GHSCategoryId(
    2015007,
    '区分2C ; Category2C',
    '区分2C',
    'Category2C',
    Section2GHSItemId.CORROSIVE_TO_METALS.value
  );
  static readonly CORROSIVE_TO_METALS_CATEGORY_3 = new Section2GHSCategoryId(
    2015008,
    '区分3 ; Category3',
    '区分3',
    'Category3',
    Section2GHSItemId.CORROSIVE_TO_METALS.value
  );
  static readonly CORROSIVE_TO_METALS_CATEGORY_3A = new Section2GHSCategoryId(
    2015009,
    '区分3A ; Category3A',
    '区分3A',
    'Category3A',
    Section2GHSItemId.CORROSIVE_TO_METALS.value
  );
  static readonly CORROSIVE_TO_METALS_CATEGORY_3B = new Section2GHSCategoryId(
    2015010,
    '区分3B ; Category3B',
    '区分3B',
    'Category3B',
    Section2GHSItemId.CORROSIVE_TO_METALS.value
  );
  static readonly CORROSIVE_TO_METALS_CATEGORY_3C = new Section2GHSCategoryId(
    2015011,
    '区分3C ; Category3C',
    '区分3C',
    'Category3C',
    Section2GHSItemId.CORROSIVE_TO_METALS.value
  );
  static readonly CORROSIVE_TO_METALS_CATEGORY_4 = new Section2GHSCategoryId(
    2015012,
    '区分4 ; Category4',
    '区分4',
    'Category4',
    Section2GHSItemId.CORROSIVE_TO_METALS.value
  );
  static readonly CORROSIVE_TO_METALS_CATEGORY_4A = new Section2GHSCategoryId(
    2015013,
    '区分4A ; Category4A',
    '区分4A',
    'Category4A',
    Section2GHSItemId.CORROSIVE_TO_METALS.value
  );
  static readonly CORROSIVE_TO_METALS_CATEGORY_4B = new Section2GHSCategoryId(
    2015014,
    '区分4B ; Category4B',
    '区分4B',
    'Category4B',
    Section2GHSItemId.CORROSIVE_TO_METALS.value
  );
  static readonly CORROSIVE_TO_METALS_CATEGORY_4C = new Section2GHSCategoryId(
    2015015,
    '区分4C ; Category4C',
    '区分4C',
    'Category4C',
    Section2GHSItemId.CORROSIVE_TO_METALS.value
  );
  static readonly CORROSIVE_TO_METALS_CATEGORY_5 = new Section2GHSCategoryId(
    2015016,
    '区分5 ; Category5',
    '区分5',
    'Category5',
    Section2GHSItemId.CORROSIVE_TO_METALS.value
  );
  static readonly CORROSIVE_TO_METALS_CATEGORY_5A = new Section2GHSCategoryId(
    2015017,
    '区分5A ; Category5A',
    '区分5A',
    'Category5A',
    Section2GHSItemId.CORROSIVE_TO_METALS.value
  );
  static readonly CORROSIVE_TO_METALS_CATEGORY_5B = new Section2GHSCategoryId(
    2015018,
    '区分5B ; Category5B',
    '区分5B',
    'Category5B',
    Section2GHSItemId.CORROSIVE_TO_METALS.value
  );
  static readonly CORROSIVE_TO_METALS_CATEGORY_5C = new Section2GHSCategoryId(
    2015019,
    '区分5C ; Category5C',
    '区分5C',
    'Category5C',
    Section2GHSItemId.CORROSIVE_TO_METALS.value
  );
  static readonly DESENSITIZED_EXPLOSIVES_CATEGORY_1 =
    new Section2GHSCategoryId(
      2016000,
      '区分1 ; Category1',
      '区分1',
      'Category1',
      Section2GHSItemId.DESENSITIZED_EXPLOSIVES.value
    );
  static readonly DESENSITIZED_EXPLOSIVES_CATEGORY_1A =
    new Section2GHSCategoryId(
      2016001,
      '区分1A ; Category1A',
      '区分1A',
      'Category1A',
      Section2GHSItemId.DESENSITIZED_EXPLOSIVES.value
    );
  static readonly DESENSITIZED_EXPLOSIVES_CATEGORY_1B =
    new Section2GHSCategoryId(
      2016002,
      '区分1B ; Category1B',
      '区分1B',
      'Category1B',
      Section2GHSItemId.DESENSITIZED_EXPLOSIVES.value
    );
  static readonly DESENSITIZED_EXPLOSIVES_CATEGORY_1C =
    new Section2GHSCategoryId(
      2016003,
      '区分1C ; Category1C',
      '区分1C',
      'Category1C',
      Section2GHSItemId.DESENSITIZED_EXPLOSIVES.value
    );
  static readonly DESENSITIZED_EXPLOSIVES_CATEGORY_2 =
    new Section2GHSCategoryId(
      2016004,
      '区分2 ; Category2',
      '区分2',
      'Category2',
      Section2GHSItemId.DESENSITIZED_EXPLOSIVES.value
    );
  static readonly DESENSITIZED_EXPLOSIVES_CATEGORY_2A =
    new Section2GHSCategoryId(
      2016005,
      '区分2A ; Category2A',
      '区分2A',
      'Category2A',
      Section2GHSItemId.DESENSITIZED_EXPLOSIVES.value
    );
  static readonly DESENSITIZED_EXPLOSIVES_CATEGORY_2B =
    new Section2GHSCategoryId(
      2016006,
      '区分2B ; Category2B',
      '区分2B',
      'Category2B',
      Section2GHSItemId.DESENSITIZED_EXPLOSIVES.value
    );
  static readonly DESENSITIZED_EXPLOSIVES_CATEGORY_2C =
    new Section2GHSCategoryId(
      2016007,
      '区分2C ; Category2C',
      '区分2C',
      'Category2C',
      Section2GHSItemId.DESENSITIZED_EXPLOSIVES.value
    );
  static readonly DESENSITIZED_EXPLOSIVES_CATEGORY_3 =
    new Section2GHSCategoryId(
      2016008,
      '区分3 ; Category3',
      '区分3',
      'Category3',
      Section2GHSItemId.DESENSITIZED_EXPLOSIVES.value
    );
  static readonly DESENSITIZED_EXPLOSIVES_CATEGORY_3A =
    new Section2GHSCategoryId(
      2016009,
      '区分3A ; Category3A',
      '区分3A',
      'Category3A',
      Section2GHSItemId.DESENSITIZED_EXPLOSIVES.value
    );
  static readonly DESENSITIZED_EXPLOSIVES_CATEGORY_3B =
    new Section2GHSCategoryId(
      2016010,
      '区分3B ; Category3B',
      '区分3B',
      'Category3B',
      Section2GHSItemId.DESENSITIZED_EXPLOSIVES.value
    );
  static readonly DESENSITIZED_EXPLOSIVES_CATEGORY_3C =
    new Section2GHSCategoryId(
      2016011,
      '区分3C ; Category3C',
      '区分3C',
      'Category3C',
      Section2GHSItemId.DESENSITIZED_EXPLOSIVES.value
    );
  static readonly DESENSITIZED_EXPLOSIVES_CATEGORY_4 =
    new Section2GHSCategoryId(
      2016012,
      '区分4 ; Category4',
      '区分4',
      'Category4',
      Section2GHSItemId.DESENSITIZED_EXPLOSIVES.value
    );
  static readonly DESENSITIZED_EXPLOSIVES_CATEGORY_4A =
    new Section2GHSCategoryId(
      2016013,
      '区分4A ; Category4A',
      '区分4A',
      'Category4A',
      Section2GHSItemId.DESENSITIZED_EXPLOSIVES.value
    );
  static readonly DESENSITIZED_EXPLOSIVES_CATEGORY_4B =
    new Section2GHSCategoryId(
      2016014,
      '区分4B ; Category4B',
      '区分4B',
      'Category4B',
      Section2GHSItemId.DESENSITIZED_EXPLOSIVES.value
    );
  static readonly DESENSITIZED_EXPLOSIVES_CATEGORY_4C =
    new Section2GHSCategoryId(
      2016015,
      '区分4C ; Category4C',
      '区分4C',
      'Category4C',
      Section2GHSItemId.DESENSITIZED_EXPLOSIVES.value
    );
  static readonly DESENSITIZED_EXPLOSIVES_CATEGORY_5 =
    new Section2GHSCategoryId(
      2016016,
      '区分5 ; Category5',
      '区分5',
      'Category5',
      Section2GHSItemId.DESENSITIZED_EXPLOSIVES.value
    );
  static readonly DESENSITIZED_EXPLOSIVES_CATEGORY_5A =
    new Section2GHSCategoryId(
      2016017,
      '区分5A ; Category5A',
      '区分5A',
      'Category5A',
      Section2GHSItemId.DESENSITIZED_EXPLOSIVES.value
    );
  static readonly DESENSITIZED_EXPLOSIVES_CATEGORY_5B =
    new Section2GHSCategoryId(
      2016018,
      '区分5B ; Category5B',
      '区分5B',
      'Category5B',
      Section2GHSItemId.DESENSITIZED_EXPLOSIVES.value
    );
  static readonly DESENSITIZED_EXPLOSIVES_CATEGORY_5C =
    new Section2GHSCategoryId(
      2016019,
      '区分5C ; Category5C',
      '区分5C',
      'Category5C',
      Section2GHSItemId.DESENSITIZED_EXPLOSIVES.value
    );
  static readonly ACUTE_TOXICITY_ORAL_CATEGORY_1 = new Section2GHSCategoryId(
    2017000,
    '区分1 ; Category1',
    '区分1',
    'Category1',
    Section2GHSItemId.ACUTE_TOXICITY_ORAL.value
  );
  static readonly ACUTE_TOXICITY_ORAL_CATEGORY_1A = new Section2GHSCategoryId(
    2017001,
    '区分1A ; Category1A',
    '区分1A',
    'Category1A',
    Section2GHSItemId.ACUTE_TOXICITY_ORAL.value
  );
  static readonly ACUTE_TOXICITY_ORAL_CATEGORY_1B = new Section2GHSCategoryId(
    2017002,
    '区分1B ; Category1B',
    '区分1B',
    'Category1B',
    Section2GHSItemId.ACUTE_TOXICITY_ORAL.value
  );
  static readonly ACUTE_TOXICITY_ORAL_CATEGORY_1C = new Section2GHSCategoryId(
    2017003,
    '区分1C ; Category1C',
    '区分1C',
    'Category1C',
    Section2GHSItemId.ACUTE_TOXICITY_ORAL.value
  );
  static readonly ACUTE_TOXICITY_ORAL_CATEGORY_2 = new Section2GHSCategoryId(
    2017004,
    '区分2 ; Category2',
    '区分2',
    'Category2',
    Section2GHSItemId.ACUTE_TOXICITY_ORAL.value
  );
  static readonly ACUTE_TOXICITY_ORAL_CATEGORY_2A = new Section2GHSCategoryId(
    2017005,
    '区分2A ; Category2A',
    '区分2A',
    'Category2A',
    Section2GHSItemId.ACUTE_TOXICITY_ORAL.value
  );
  static readonly ACUTE_TOXICITY_ORAL_CATEGORY_2B = new Section2GHSCategoryId(
    2017006,
    '区分2B ; Category2B',
    '区分2B',
    'Category2B',
    Section2GHSItemId.ACUTE_TOXICITY_ORAL.value
  );
  static readonly ACUTE_TOXICITY_ORAL_CATEGORY_2C = new Section2GHSCategoryId(
    2017007,
    '区分2C ; Category2C',
    '区分2C',
    'Category2C',
    Section2GHSItemId.ACUTE_TOXICITY_ORAL.value
  );
  static readonly ACUTE_TOXICITY_ORAL_CATEGORY_3 = new Section2GHSCategoryId(
    2017008,
    '区分3 ; Category3',
    '区分3',
    'Category3',
    Section2GHSItemId.ACUTE_TOXICITY_ORAL.value
  );
  static readonly ACUTE_TOXICITY_ORAL_CATEGORY_3A = new Section2GHSCategoryId(
    2017009,
    '区分3A ; Category3A',
    '区分3A',
    'Category3A',
    Section2GHSItemId.ACUTE_TOXICITY_ORAL.value
  );
  static readonly ACUTE_TOXICITY_ORAL_CATEGORY_3B = new Section2GHSCategoryId(
    2017010,
    '区分3B ; Category3B',
    '区分3B',
    'Category3B',
    Section2GHSItemId.ACUTE_TOXICITY_ORAL.value
  );
  static readonly ACUTE_TOXICITY_ORAL_CATEGORY_3C = new Section2GHSCategoryId(
    2017011,
    '区分3C ; Category3C',
    '区分3C',
    'Category3C',
    Section2GHSItemId.ACUTE_TOXICITY_ORAL.value
  );
  static readonly ACUTE_TOXICITY_ORAL_CATEGORY_4 = new Section2GHSCategoryId(
    2017012,
    '区分4 ; Category4',
    '区分4',
    'Category4',
    Section2GHSItemId.ACUTE_TOXICITY_ORAL.value
  );
  static readonly ACUTE_TOXICITY_ORAL_CATEGORY_4A = new Section2GHSCategoryId(
    2017013,
    '区分4A ; Category4A',
    '区分4A',
    'Category4A',
    Section2GHSItemId.ACUTE_TOXICITY_ORAL.value
  );
  static readonly ACUTE_TOXICITY_ORAL_CATEGORY_4B = new Section2GHSCategoryId(
    2017014,
    '区分4B ; Category4B',
    '区分4B',
    'Category4B',
    Section2GHSItemId.ACUTE_TOXICITY_ORAL.value
  );
  static readonly ACUTE_TOXICITY_ORAL_CATEGORY_4C = new Section2GHSCategoryId(
    2017015,
    '区分4C ; Category4C',
    '区分4C',
    'Category4C',
    Section2GHSItemId.ACUTE_TOXICITY_ORAL.value
  );
  static readonly ACUTE_TOXICITY_ORAL_CATEGORY_5 = new Section2GHSCategoryId(
    2017016,
    '区分5 ; Category5',
    '区分5',
    'Category5',
    Section2GHSItemId.ACUTE_TOXICITY_ORAL.value
  );
  static readonly ACUTE_TOXICITY_ORAL_CATEGORY_5A = new Section2GHSCategoryId(
    2017017,
    '区分5A ; Category5A',
    '区分5A',
    'Category5A',
    Section2GHSItemId.ACUTE_TOXICITY_ORAL.value
  );
  static readonly ACUTE_TOXICITY_ORAL_CATEGORY_5B = new Section2GHSCategoryId(
    2017018,
    '区分5B ; Category5B',
    '区分5B',
    'Category5B',
    Section2GHSItemId.ACUTE_TOXICITY_ORAL.value
  );
  static readonly ACUTE_TOXICITY_ORAL_CATEGORY_5C = new Section2GHSCategoryId(
    2017019,
    '区分5C ; Category5C',
    '区分5C',
    'Category5C',
    Section2GHSItemId.ACUTE_TOXICITY_ORAL.value
  );
  static readonly ACUTE_TOXICITY_DERMAL_CATEGORY_1 = new Section2GHSCategoryId(
    2018000,
    '区分1 ; Category1',
    '区分1',
    'Category1',
    Section2GHSItemId.ACUTE_TOXICITY_DERMAL.value
  );
  static readonly ACUTE_TOXICITY_DERMAL_CATEGORY_1A = new Section2GHSCategoryId(
    2018001,
    '区分1A ; Category1A',
    '区分1A',
    'Category1A',
    Section2GHSItemId.ACUTE_TOXICITY_DERMAL.value
  );
  static readonly ACUTE_TOXICITY_DERMAL_CATEGORY_1B = new Section2GHSCategoryId(
    2018002,
    '区分1B ; Category1B',
    '区分1B',
    'Category1B',
    Section2GHSItemId.ACUTE_TOXICITY_DERMAL.value
  );
  static readonly ACUTE_TOXICITY_DERMAL_CATEGORY_1C = new Section2GHSCategoryId(
    2018003,
    '区分1C ; Category1C',
    '区分1C',
    'Category1C',
    Section2GHSItemId.ACUTE_TOXICITY_DERMAL.value
  );
  static readonly ACUTE_TOXICITY_DERMAL_CATEGORY_2 = new Section2GHSCategoryId(
    2018004,
    '区分2 ; Category2',
    '区分2',
    'Category2',
    Section2GHSItemId.ACUTE_TOXICITY_DERMAL.value
  );
  static readonly ACUTE_TOXICITY_DERMAL_CATEGORY_2A = new Section2GHSCategoryId(
    2018005,
    '区分2A ; Category2A',
    '区分2A',
    'Category2A',
    Section2GHSItemId.ACUTE_TOXICITY_DERMAL.value
  );
  static readonly ACUTE_TOXICITY_DERMAL_CATEGORY_2B = new Section2GHSCategoryId(
    2018006,
    '区分2B ; Category2B',
    '区分2B',
    'Category2B',
    Section2GHSItemId.ACUTE_TOXICITY_DERMAL.value
  );
  static readonly ACUTE_TOXICITY_DERMAL_CATEGORY_2C = new Section2GHSCategoryId(
    2018007,
    '区分2C ; Category2C',
    '区分2C',
    'Category2C',
    Section2GHSItemId.ACUTE_TOXICITY_DERMAL.value
  );
  static readonly ACUTE_TOXICITY_DERMAL_CATEGORY_3 = new Section2GHSCategoryId(
    2018008,
    '区分3 ; Category3',
    '区分3',
    'Category3',
    Section2GHSItemId.ACUTE_TOXICITY_DERMAL.value
  );
  static readonly ACUTE_TOXICITY_DERMAL_CATEGORY_3A = new Section2GHSCategoryId(
    2018009,
    '区分3A ; Category3A',
    '区分3A',
    'Category3A',
    Section2GHSItemId.ACUTE_TOXICITY_DERMAL.value
  );
  static readonly ACUTE_TOXICITY_DERMAL_CATEGORY_3B = new Section2GHSCategoryId(
    2018010,
    '区分3B ; Category3B',
    '区分3B',
    'Category3B',
    Section2GHSItemId.ACUTE_TOXICITY_DERMAL.value
  );
  static readonly ACUTE_TOXICITY_DERMAL_CATEGORY_3C = new Section2GHSCategoryId(
    2018011,
    '区分3C ; Category3C',
    '区分3C',
    'Category3C',
    Section2GHSItemId.ACUTE_TOXICITY_DERMAL.value
  );
  static readonly ACUTE_TOXICITY_DERMAL_CATEGORY_4 = new Section2GHSCategoryId(
    2018012,
    '区分4 ; Category4',
    '区分4',
    'Category4',
    Section2GHSItemId.ACUTE_TOXICITY_DERMAL.value
  );
  static readonly ACUTE_TOXICITY_DERMAL_CATEGORY_4A = new Section2GHSCategoryId(
    2018013,
    '区分4A ; Category4A',
    '区分4A',
    'Category4A',
    Section2GHSItemId.ACUTE_TOXICITY_DERMAL.value
  );
  static readonly ACUTE_TOXICITY_DERMAL_CATEGORY_4B = new Section2GHSCategoryId(
    2018014,
    '区分4B ; Category4B',
    '区分4B',
    'Category4B',
    Section2GHSItemId.ACUTE_TOXICITY_DERMAL.value
  );
  static readonly ACUTE_TOXICITY_DERMAL_CATEGORY_4C = new Section2GHSCategoryId(
    2018015,
    '区分4C ; Category4C',
    '区分4C',
    'Category4C',
    Section2GHSItemId.ACUTE_TOXICITY_DERMAL.value
  );
  static readonly ACUTE_TOXICITY_DERMAL_CATEGORY_5 = new Section2GHSCategoryId(
    2018016,
    '区分5 ; Category5',
    '区分5',
    'Category5',
    Section2GHSItemId.ACUTE_TOXICITY_DERMAL.value
  );
  static readonly ACUTE_TOXICITY_DERMAL_CATEGORY_5A = new Section2GHSCategoryId(
    2018017,
    '区分5A ; Category5A',
    '区分5A',
    'Category5A',
    Section2GHSItemId.ACUTE_TOXICITY_DERMAL.value
  );
  static readonly ACUTE_TOXICITY_DERMAL_CATEGORY_5B = new Section2GHSCategoryId(
    2018018,
    '区分5B ; Category5B',
    '区分5B',
    'Category5B',
    Section2GHSItemId.ACUTE_TOXICITY_DERMAL.value
  );
  static readonly ACUTE_TOXICITY_DERMAL_CATEGORY_5C = new Section2GHSCategoryId(
    2018019,
    '区分5C ; Category5C',
    '区分5C',
    'Category5C',
    Section2GHSItemId.ACUTE_TOXICITY_DERMAL.value
  );
  static readonly ACUTE_TOXICITY_INHALATION_GASES_CATEGORY_1 =
    new Section2GHSCategoryId(
      2019000,
      '区分1 ; Category1',
      '区分1',
      'Category1',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_GASES.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_GASES_CATEGORY_1A =
    new Section2GHSCategoryId(
      2019001,
      '区分1A ; Category1A',
      '区分1A',
      'Category1A',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_GASES.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_GASES_CATEGORY_1B =
    new Section2GHSCategoryId(
      2019002,
      '区分1B ; Category1B',
      '区分1B',
      'Category1B',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_GASES.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_GASES_CATEGORY_1C =
    new Section2GHSCategoryId(
      2019003,
      '区分1C ; Category1C',
      '区分1C',
      'Category1C',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_GASES.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_GASES_CATEGORY_2 =
    new Section2GHSCategoryId(
      2019004,
      '区分2 ; Category2',
      '区分2',
      'Category2',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_GASES.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_GASES_CATEGORY_2A =
    new Section2GHSCategoryId(
      2019005,
      '区分2A ; Category2A',
      '区分2A',
      'Category2A',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_GASES.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_GASES_CATEGORY_2B =
    new Section2GHSCategoryId(
      2019006,
      '区分2B ; Category2B',
      '区分2B',
      'Category2B',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_GASES.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_GASES_CATEGORY_2C =
    new Section2GHSCategoryId(
      2019007,
      '区分2C ; Category2C',
      '区分2C',
      'Category2C',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_GASES.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_GASES_CATEGORY_3 =
    new Section2GHSCategoryId(
      2019008,
      '区分3 ; Category3',
      '区分3',
      'Category3',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_GASES.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_GASES_CATEGORY_3A =
    new Section2GHSCategoryId(
      2019009,
      '区分3A ; Category3A',
      '区分3A',
      'Category3A',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_GASES.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_GASES_CATEGORY_3B =
    new Section2GHSCategoryId(
      2019010,
      '区分3B ; Category3B',
      '区分3B',
      'Category3B',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_GASES.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_GASES_CATEGORY_3C =
    new Section2GHSCategoryId(
      2019011,
      '区分3C ; Category3C',
      '区分3C',
      'Category3C',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_GASES.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_GASES_CATEGORY_4 =
    new Section2GHSCategoryId(
      2019012,
      '区分4 ; Category4',
      '区分4',
      'Category4',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_GASES.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_GASES_CATEGORY_4A =
    new Section2GHSCategoryId(
      2019013,
      '区分4A ; Category4A',
      '区分4A',
      'Category4A',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_GASES.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_GASES_CATEGORY_4B =
    new Section2GHSCategoryId(
      2019014,
      '区分4B ; Category4B',
      '区分4B',
      'Category4B',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_GASES.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_GASES_CATEGORY_4C =
    new Section2GHSCategoryId(
      2019015,
      '区分4C ; Category4C',
      '区分4C',
      'Category4C',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_GASES.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_GASES_CATEGORY_5 =
    new Section2GHSCategoryId(
      2019016,
      '区分5 ; Category5',
      '区分5',
      'Category5',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_GASES.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_GASES_CATEGORY_5A =
    new Section2GHSCategoryId(
      2019017,
      '区分5A ; Category5A',
      '区分5A',
      'Category5A',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_GASES.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_GASES_CATEGORY_5B =
    new Section2GHSCategoryId(
      2019018,
      '区分5B ; Category5B',
      '区分5B',
      'Category5B',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_GASES.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_GASES_CATEGORY_5C =
    new Section2GHSCategoryId(
      2019019,
      '区分5C ; Category5C',
      '区分5C',
      'Category5C',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_GASES.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_VAPOURS_CATEGORY_1 =
    new Section2GHSCategoryId(
      2020000,
      '区分1 ; Category1',
      '区分1',
      'Category1',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_VAPOURS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_VAPOURS_CATEGORY_1A =
    new Section2GHSCategoryId(
      2020001,
      '区分1A ; Category1A',
      '区分1A',
      'Category1A',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_VAPOURS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_VAPOURS_CATEGORY_1B =
    new Section2GHSCategoryId(
      2020002,
      '区分1B ; Category1B',
      '区分1B',
      'Category1B',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_VAPOURS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_VAPOURS_CATEGORY_1C =
    new Section2GHSCategoryId(
      2020003,
      '区分1C ; Category1C',
      '区分1C',
      'Category1C',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_VAPOURS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_VAPOURS_CATEGORY_2 =
    new Section2GHSCategoryId(
      2020004,
      '区分2 ; Category2',
      '区分2',
      'Category2',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_VAPOURS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_VAPOURS_CATEGORY_2A =
    new Section2GHSCategoryId(
      2020005,
      '区分2A ; Category2A',
      '区分2A',
      'Category2A',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_VAPOURS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_VAPOURS_CATEGORY_2B =
    new Section2GHSCategoryId(
      2020006,
      '区分2B ; Category2B',
      '区分2B',
      'Category2B',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_VAPOURS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_VAPOURS_CATEGORY_2C =
    new Section2GHSCategoryId(
      2020007,
      '区分2C ; Category2C',
      '区分2C',
      'Category2C',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_VAPOURS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_VAPOURS_CATEGORY_3 =
    new Section2GHSCategoryId(
      2020008,
      '区分3 ; Category3',
      '区分3',
      'Category3',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_VAPOURS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_VAPOURS_CATEGORY_3A =
    new Section2GHSCategoryId(
      2020009,
      '区分3A ; Category3A',
      '区分3A',
      'Category3A',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_VAPOURS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_VAPOURS_CATEGORY_3B =
    new Section2GHSCategoryId(
      2020010,
      '区分3B ; Category3B',
      '区分3B',
      'Category3B',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_VAPOURS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_VAPOURS_CATEGORY_3C =
    new Section2GHSCategoryId(
      2020011,
      '区分3C ; Category3C',
      '区分3C',
      'Category3C',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_VAPOURS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_VAPOURS_CATEGORY_4 =
    new Section2GHSCategoryId(
      2020012,
      '区分4 ; Category4',
      '区分4',
      'Category4',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_VAPOURS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_VAPOURS_CATEGORY_4A =
    new Section2GHSCategoryId(
      2020013,
      '区分4A ; Category4A',
      '区分4A',
      'Category4A',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_VAPOURS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_VAPOURS_CATEGORY_4B =
    new Section2GHSCategoryId(
      2020014,
      '区分4B ; Category4B',
      '区分4B',
      'Category4B',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_VAPOURS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_VAPOURS_CATEGORY_4C =
    new Section2GHSCategoryId(
      2020015,
      '区分4C ; Category4C',
      '区分4C',
      'Category4C',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_VAPOURS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_VAPOURS_CATEGORY_5 =
    new Section2GHSCategoryId(
      2020016,
      '区分5 ; Category5',
      '区分5',
      'Category5',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_VAPOURS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_VAPOURS_CATEGORY_5A =
    new Section2GHSCategoryId(
      2020017,
      '区分5A ; Category5A',
      '区分5A',
      'Category5A',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_VAPOURS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_VAPOURS_CATEGORY_5B =
    new Section2GHSCategoryId(
      2020018,
      '区分5B ; Category5B',
      '区分5B',
      'Category5B',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_VAPOURS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_VAPOURS_CATEGORY_5C =
    new Section2GHSCategoryId(
      2020019,
      '区分5C ; Category5C',
      '区分5C',
      'Category5C',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_VAPOURS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_DUSTS_CATEGORY_1 =
    new Section2GHSCategoryId(
      2021000,
      '区分1 ; Category1',
      '区分1',
      'Category1',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_DUSTS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_DUSTS_CATEGORY_1A =
    new Section2GHSCategoryId(
      2021001,
      '区分1A ; Category1A',
      '区分1A',
      'Category1A',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_DUSTS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_DUSTS_CATEGORY_1B =
    new Section2GHSCategoryId(
      2021002,
      '区分1B ; Category1B',
      '区分1B',
      'Category1B',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_DUSTS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_DUSTS_CATEGORY_1C =
    new Section2GHSCategoryId(
      2021003,
      '区分1C ; Category1C',
      '区分1C',
      'Category1C',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_DUSTS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_DUSTS_CATEGORY_2 =
    new Section2GHSCategoryId(
      2021004,
      '区分2 ; Category2',
      '区分2',
      'Category2',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_DUSTS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_DUSTS_CATEGORY_2A =
    new Section2GHSCategoryId(
      2021005,
      '区分2A ; Category2A',
      '区分2A',
      'Category2A',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_DUSTS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_DUSTS_CATEGORY_2B =
    new Section2GHSCategoryId(
      2021006,
      '区分2B ; Category2B',
      '区分2B',
      'Category2B',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_DUSTS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_DUSTS_CATEGORY_2C =
    new Section2GHSCategoryId(
      2021007,
      '区分2C ; Category2C',
      '区分2C',
      'Category2C',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_DUSTS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_DUSTS_CATEGORY_3 =
    new Section2GHSCategoryId(
      2021008,
      '区分3 ; Category3',
      '区分3',
      'Category3',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_DUSTS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_DUSTS_CATEGORY_3A =
    new Section2GHSCategoryId(
      2021009,
      '区分3A ; Category3A',
      '区分3A',
      'Category3A',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_DUSTS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_DUSTS_CATEGORY_3B =
    new Section2GHSCategoryId(
      2021010,
      '区分3B ; Category3B',
      '区分3B',
      'Category3B',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_DUSTS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_DUSTS_CATEGORY_3C =
    new Section2GHSCategoryId(
      2021011,
      '区分3C ; Category3C',
      '区分3C',
      'Category3C',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_DUSTS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_DUSTS_CATEGORY_4 =
    new Section2GHSCategoryId(
      2021012,
      '区分4 ; Category4',
      '区分4',
      'Category4',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_DUSTS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_DUSTS_CATEGORY_4A =
    new Section2GHSCategoryId(
      2021013,
      '区分4A ; Category4A',
      '区分4A',
      'Category4A',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_DUSTS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_DUSTS_CATEGORY_4B =
    new Section2GHSCategoryId(
      2021014,
      '区分4B ; Category4B',
      '区分4B',
      'Category4B',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_DUSTS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_DUSTS_CATEGORY_4C =
    new Section2GHSCategoryId(
      2021015,
      '区分4C ; Category4C',
      '区分4C',
      'Category4C',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_DUSTS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_DUSTS_CATEGORY_5 =
    new Section2GHSCategoryId(
      2021016,
      '区分5 ; Category5',
      '区分5',
      'Category5',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_DUSTS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_DUSTS_CATEGORY_5A =
    new Section2GHSCategoryId(
      2021017,
      '区分5A ; Category5A',
      '区分5A',
      'Category5A',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_DUSTS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_DUSTS_CATEGORY_5B =
    new Section2GHSCategoryId(
      2021018,
      '区分5B ; Category5B',
      '区分5B',
      'Category5B',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_DUSTS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_DUSTS_CATEGORY_5C =
    new Section2GHSCategoryId(
      2021019,
      '区分5C ; Category5C',
      '区分5C',
      'Category5C',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_DUSTS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_MISTS_CATEGORY_1 =
    new Section2GHSCategoryId(
      2022000,
      '区分1 ; Category1',
      '区分1',
      'Category1',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_MISTS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_MISTS_CATEGORY_1A =
    new Section2GHSCategoryId(
      2022001,
      '区分1A ; Category1A',
      '区分1A',
      'Category1A',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_MISTS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_MISTS_CATEGORY_1B =
    new Section2GHSCategoryId(
      2022002,
      '区分1B ; Category1B',
      '区分1B',
      'Category1B',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_MISTS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_MISTS_CATEGORY_1C =
    new Section2GHSCategoryId(
      2022003,
      '区分1C ; Category1C',
      '区分1C',
      'Category1C',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_MISTS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_MISTS_CATEGORY_2 =
    new Section2GHSCategoryId(
      2022004,
      '区分2 ; Category2',
      '区分2',
      'Category2',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_MISTS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_MISTS_CATEGORY_2A =
    new Section2GHSCategoryId(
      2022005,
      '区分2A ; Category2A',
      '区分2A',
      'Category2A',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_MISTS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_MISTS_CATEGORY_2B =
    new Section2GHSCategoryId(
      2022006,
      '区分2B ; Category2B',
      '区分2B',
      'Category2B',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_MISTS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_MISTS_CATEGORY_2C =
    new Section2GHSCategoryId(
      2022007,
      '区分2C ; Category2C',
      '区分2C',
      'Category2C',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_MISTS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_MISTS_CATEGORY_3 =
    new Section2GHSCategoryId(
      2022008,
      '区分3 ; Category3',
      '区分3',
      'Category3',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_MISTS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_MISTS_CATEGORY_3A =
    new Section2GHSCategoryId(
      2022009,
      '区分3A ; Category3A',
      '区分3A',
      'Category3A',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_MISTS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_MISTS_CATEGORY_3B =
    new Section2GHSCategoryId(
      2022010,
      '区分3B ; Category3B',
      '区分3B',
      'Category3B',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_MISTS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_MISTS_CATEGORY_3C =
    new Section2GHSCategoryId(
      2022011,
      '区分3C ; Category3C',
      '区分3C',
      'Category3C',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_MISTS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_MISTS_CATEGORY_4 =
    new Section2GHSCategoryId(
      2022012,
      '区分4 ; Category4',
      '区分4',
      'Category4',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_MISTS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_MISTS_CATEGORY_4A =
    new Section2GHSCategoryId(
      2022013,
      '区分4A ; Category4A',
      '区分4A',
      'Category4A',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_MISTS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_MISTS_CATEGORY_4B =
    new Section2GHSCategoryId(
      2022014,
      '区分4B ; Category4B',
      '区分4B',
      'Category4B',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_MISTS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_MISTS_CATEGORY_4C =
    new Section2GHSCategoryId(
      2022015,
      '区分4C ; Category4C',
      '区分4C',
      'Category4C',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_MISTS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_MISTS_CATEGORY_5 =
    new Section2GHSCategoryId(
      2022016,
      '区分5 ; Category5',
      '区分5',
      'Category5',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_MISTS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_MISTS_CATEGORY_5A =
    new Section2GHSCategoryId(
      2022017,
      '区分5A ; Category5A',
      '区分5A',
      'Category5A',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_MISTS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_MISTS_CATEGORY_5B =
    new Section2GHSCategoryId(
      2022018,
      '区分5B ; Category5B',
      '区分5B',
      'Category5B',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_MISTS.value
    );
  static readonly ACUTE_TOXICITY_INHALATION_MISTS_CATEGORY_5C =
    new Section2GHSCategoryId(
      2022019,
      '区分5C ; Category5C',
      '区分5C',
      'Category5C',
      Section2GHSItemId.ACUTE_TOXICITY_INHALATION_MISTS.value
    );
  static readonly SKIN_CORROSION_IRRITATION_CATEGORY_1 =
    new Section2GHSCategoryId(
      2023000,
      '区分1 ; Category1',
      '区分1',
      'Category1',
      Section2GHSItemId.SKIN_CORROSION_IRRITATION.value
    );
  static readonly SKIN_CORROSION_IRRITATION_CATEGORY_1A =
    new Section2GHSCategoryId(
      2023001,
      '区分1A ; Category1A',
      '区分1A',
      'Category1A',
      Section2GHSItemId.SKIN_CORROSION_IRRITATION.value
    );
  static readonly SKIN_CORROSION_IRRITATION_CATEGORY_1B =
    new Section2GHSCategoryId(
      2023002,
      '区分1B ; Category1B',
      '区分1B',
      'Category1B',
      Section2GHSItemId.SKIN_CORROSION_IRRITATION.value
    );
  static readonly SKIN_CORROSION_IRRITATION_CATEGORY_1C =
    new Section2GHSCategoryId(
      2023003,
      '区分1C ; Category1C',
      '区分1C',
      'Category1C',
      Section2GHSItemId.SKIN_CORROSION_IRRITATION.value
    );
  static readonly SKIN_CORROSION_IRRITATION_CATEGORY_2 =
    new Section2GHSCategoryId(
      2023004,
      '区分2 ; Category2',
      '区分2',
      'Category2',
      Section2GHSItemId.SKIN_CORROSION_IRRITATION.value
    );
  static readonly SKIN_CORROSION_IRRITATION_CATEGORY_2A =
    new Section2GHSCategoryId(
      2023005,
      '区分2A ; Category2A',
      '区分2A',
      'Category2A',
      Section2GHSItemId.SKIN_CORROSION_IRRITATION.value
    );
  static readonly SKIN_CORROSION_IRRITATION_CATEGORY_2B =
    new Section2GHSCategoryId(
      2023006,
      '区分2B ; Category2B',
      '区分2B',
      'Category2B',
      Section2GHSItemId.SKIN_CORROSION_IRRITATION.value
    );
  static readonly SKIN_CORROSION_IRRITATION_CATEGORY_2C =
    new Section2GHSCategoryId(
      2023007,
      '区分2C ; Category2C',
      '区分2C',
      'Category2C',
      Section2GHSItemId.SKIN_CORROSION_IRRITATION.value
    );
  static readonly SKIN_CORROSION_IRRITATION_CATEGORY_3 =
    new Section2GHSCategoryId(
      2023008,
      '区分3 ; Category3',
      '区分3',
      'Category3',
      Section2GHSItemId.SKIN_CORROSION_IRRITATION.value
    );
  static readonly SKIN_CORROSION_IRRITATION_CATEGORY_3A =
    new Section2GHSCategoryId(
      2023009,
      '区分3A ; Category3A',
      '区分3A',
      'Category3A',
      Section2GHSItemId.SKIN_CORROSION_IRRITATION.value
    );
  static readonly SKIN_CORROSION_IRRITATION_CATEGORY_3B =
    new Section2GHSCategoryId(
      2023010,
      '区分3B ; Category3B',
      '区分3B',
      'Category3B',
      Section2GHSItemId.SKIN_CORROSION_IRRITATION.value
    );
  static readonly SKIN_CORROSION_IRRITATION_CATEGORY_3C =
    new Section2GHSCategoryId(
      2023011,
      '区分3C ; Category3C',
      '区分3C',
      'Category3C',
      Section2GHSItemId.SKIN_CORROSION_IRRITATION.value
    );
  static readonly SKIN_CORROSION_IRRITATION_CATEGORY_4 =
    new Section2GHSCategoryId(
      2023012,
      '区分4 ; Category4',
      '区分4',
      'Category4',
      Section2GHSItemId.SKIN_CORROSION_IRRITATION.value
    );
  static readonly SKIN_CORROSION_IRRITATION_CATEGORY_4A =
    new Section2GHSCategoryId(
      2023013,
      '区分4A ; Category4A',
      '区分4A',
      'Category4A',
      Section2GHSItemId.SKIN_CORROSION_IRRITATION.value
    );
  static readonly SKIN_CORROSION_IRRITATION_CATEGORY_4B =
    new Section2GHSCategoryId(
      2023014,
      '区分4B ; Category4B',
      '区分4B',
      'Category4B',
      Section2GHSItemId.SKIN_CORROSION_IRRITATION.value
    );
  static readonly SKIN_CORROSION_IRRITATION_CATEGORY_4C =
    new Section2GHSCategoryId(
      2023015,
      '区分4C ; Category4C',
      '区分4C',
      'Category4C',
      Section2GHSItemId.SKIN_CORROSION_IRRITATION.value
    );
  static readonly SKIN_CORROSION_IRRITATION_CATEGORY_5 =
    new Section2GHSCategoryId(
      2023016,
      '区分5 ; Category5',
      '区分5',
      'Category5',
      Section2GHSItemId.SKIN_CORROSION_IRRITATION.value
    );
  static readonly SKIN_CORROSION_IRRITATION_CATEGORY_5A =
    new Section2GHSCategoryId(
      2023017,
      '区分5A ; Category5A',
      '区分5A',
      'Category5A',
      Section2GHSItemId.SKIN_CORROSION_IRRITATION.value
    );
  static readonly SKIN_CORROSION_IRRITATION_CATEGORY_5B =
    new Section2GHSCategoryId(
      2023018,
      '区分5B ; Category5B',
      '区分5B',
      'Category5B',
      Section2GHSItemId.SKIN_CORROSION_IRRITATION.value
    );
  static readonly SKIN_CORROSION_IRRITATION_CATEGORY_5C =
    new Section2GHSCategoryId(
      2023019,
      '区分5C ; Category5C',
      '区分5C',
      'Category5C',
      Section2GHSItemId.SKIN_CORROSION_IRRITATION.value
    );
  static readonly SERIOUS_EYE_DAMAGE_CATEGORY_1 = new Section2GHSCategoryId(
    2024000,
    '区分1 ; Category1',
    '区分1',
    'Category1',
    Section2GHSItemId.SERIOUS_EYE_DAMAGE.value
  );
  static readonly SERIOUS_EYE_DAMAGE_CATEGORY_1A = new Section2GHSCategoryId(
    2024001,
    '区分1A ; Category1A',
    '区分1A',
    'Category1A',
    Section2GHSItemId.SERIOUS_EYE_DAMAGE.value
  );
  static readonly SERIOUS_EYE_DAMAGE_CATEGORY_1B = new Section2GHSCategoryId(
    2024002,
    '区分1B ; Category1B',
    '区分1B',
    'Category1B',
    Section2GHSItemId.SERIOUS_EYE_DAMAGE.value
  );
  static readonly SERIOUS_EYE_DAMAGE_CATEGORY_1C = new Section2GHSCategoryId(
    2024003,
    '区分1C ; Category1C',
    '区分1C',
    'Category1C',
    Section2GHSItemId.SERIOUS_EYE_DAMAGE.value
  );
  static readonly SERIOUS_EYE_DAMAGE_CATEGORY_2 = new Section2GHSCategoryId(
    2024004,
    '区分2 ; Category2',
    '区分2',
    'Category2',
    Section2GHSItemId.SERIOUS_EYE_DAMAGE.value
  );
  static readonly SERIOUS_EYE_DAMAGE_CATEGORY_2A = new Section2GHSCategoryId(
    2024005,
    '区分2A ; Category2A',
    '区分2A',
    'Category2A',
    Section2GHSItemId.SERIOUS_EYE_DAMAGE.value
  );
  static readonly SERIOUS_EYE_DAMAGE_CATEGORY_2B = new Section2GHSCategoryId(
    2024006,
    '区分2B ; Category2B',
    '区分2B',
    'Category2B',
    Section2GHSItemId.SERIOUS_EYE_DAMAGE.value
  );
  static readonly SERIOUS_EYE_DAMAGE_CATEGORY_2C = new Section2GHSCategoryId(
    2024007,
    '区分2C ; Category2C',
    '区分2C',
    'Category2C',
    Section2GHSItemId.SERIOUS_EYE_DAMAGE.value
  );
  static readonly SERIOUS_EYE_DAMAGE_CATEGORY_3 = new Section2GHSCategoryId(
    2024008,
    '区分3 ; Category3',
    '区分3',
    'Category3',
    Section2GHSItemId.SERIOUS_EYE_DAMAGE.value
  );
  static readonly SERIOUS_EYE_DAMAGE_CATEGORY_3A = new Section2GHSCategoryId(
    2024009,
    '区分3A ; Category3A',
    '区分3A',
    'Category3A',
    Section2GHSItemId.SERIOUS_EYE_DAMAGE.value
  );
  static readonly SERIOUS_EYE_DAMAGE_CATEGORY_3B = new Section2GHSCategoryId(
    2024010,
    '区分3B ; Category3B',
    '区分3B',
    'Category3B',
    Section2GHSItemId.SERIOUS_EYE_DAMAGE.value
  );
  static readonly SERIOUS_EYE_DAMAGE_CATEGORY_3C = new Section2GHSCategoryId(
    2024011,
    '区分3C ; Category3C',
    '区分3C',
    'Category3C',
    Section2GHSItemId.SERIOUS_EYE_DAMAGE.value
  );
  static readonly SERIOUS_EYE_DAMAGE_CATEGORY_4 = new Section2GHSCategoryId(
    2024012,
    '区分4 ; Category4',
    '区分4',
    'Category4',
    Section2GHSItemId.SERIOUS_EYE_DAMAGE.value
  );
  static readonly SERIOUS_EYE_DAMAGE_CATEGORY_4A = new Section2GHSCategoryId(
    2024013,
    '区分4A ; Category4A',
    '区分4A',
    'Category4A',
    Section2GHSItemId.SERIOUS_EYE_DAMAGE.value
  );
  static readonly SERIOUS_EYE_DAMAGE_CATEGORY_4B = new Section2GHSCategoryId(
    2024014,
    '区分4B ; Category4B',
    '区分4B',
    'Category4B',
    Section2GHSItemId.SERIOUS_EYE_DAMAGE.value
  );
  static readonly SERIOUS_EYE_DAMAGE_CATEGORY_4C = new Section2GHSCategoryId(
    2024015,
    '区分4C ; Category4C',
    '区分4C',
    'Category4C',
    Section2GHSItemId.SERIOUS_EYE_DAMAGE.value
  );
  static readonly SERIOUS_EYE_DAMAGE_CATEGORY_5 = new Section2GHSCategoryId(
    2024016,
    '区分5 ; Category5',
    '区分5',
    'Category5',
    Section2GHSItemId.SERIOUS_EYE_DAMAGE.value
  );
  static readonly SERIOUS_EYE_DAMAGE_CATEGORY_5A = new Section2GHSCategoryId(
    2024017,
    '区分5A ; Category5A',
    '区分5A',
    'Category5A',
    Section2GHSItemId.SERIOUS_EYE_DAMAGE.value
  );
  static readonly SERIOUS_EYE_DAMAGE_CATEGORY_5B = new Section2GHSCategoryId(
    2024018,
    '区分5B ; Category5B',
    '区分5B',
    'Category5B',
    Section2GHSItemId.SERIOUS_EYE_DAMAGE.value
  );
  static readonly SERIOUS_EYE_DAMAGE_CATEGORY_5C = new Section2GHSCategoryId(
    2024019,
    '区分5C ; Category5C',
    '区分5C',
    'Category5C',
    Section2GHSItemId.SERIOUS_EYE_DAMAGE.value
  );
  static readonly RESPIRATORY_SENSITIZATION_CATEGORY_1 =
    new Section2GHSCategoryId(
      2025000,
      '区分1 ; Category1',
      '区分1',
      'Category1',
      Section2GHSItemId.RESPIRATORY_SENSITIZATION.value
    );
  static readonly RESPIRATORY_SENSITIZATION_CATEGORY_1A =
    new Section2GHSCategoryId(
      2025001,
      '区分1A ; Category1A',
      '区分1A',
      'Category1A',
      Section2GHSItemId.RESPIRATORY_SENSITIZATION.value
    );
  static readonly RESPIRATORY_SENSITIZATION_CATEGORY_1B =
    new Section2GHSCategoryId(
      2025002,
      '区分1B ; Category1B',
      '区分1B',
      'Category1B',
      Section2GHSItemId.RESPIRATORY_SENSITIZATION.value
    );
  static readonly RESPIRATORY_SENSITIZATION_CATEGORY_1C =
    new Section2GHSCategoryId(
      2025003,
      '区分1C ; Category1C',
      '区分1C',
      'Category1C',
      Section2GHSItemId.RESPIRATORY_SENSITIZATION.value
    );
  static readonly RESPIRATORY_SENSITIZATION_CATEGORY_2 =
    new Section2GHSCategoryId(
      2025004,
      '区分2 ; Category2',
      '区分2',
      'Category2',
      Section2GHSItemId.RESPIRATORY_SENSITIZATION.value
    );
  static readonly RESPIRATORY_SENSITIZATION_CATEGORY_2A =
    new Section2GHSCategoryId(
      2025005,
      '区分2A ; Category2A',
      '区分2A',
      'Category2A',
      Section2GHSItemId.RESPIRATORY_SENSITIZATION.value
    );
  static readonly RESPIRATORY_SENSITIZATION_CATEGORY_2B =
    new Section2GHSCategoryId(
      2025006,
      '区分2B ; Category2B',
      '区分2B',
      'Category2B',
      Section2GHSItemId.RESPIRATORY_SENSITIZATION.value
    );
  static readonly RESPIRATORY_SENSITIZATION_CATEGORY_2C =
    new Section2GHSCategoryId(
      2025007,
      '区分2C ; Category2C',
      '区分2C',
      'Category2C',
      Section2GHSItemId.RESPIRATORY_SENSITIZATION.value
    );
  static readonly RESPIRATORY_SENSITIZATION_CATEGORY_3 =
    new Section2GHSCategoryId(
      2025008,
      '区分3 ; Category3',
      '区分3',
      'Category3',
      Section2GHSItemId.RESPIRATORY_SENSITIZATION.value
    );
  static readonly RESPIRATORY_SENSITIZATION_CATEGORY_3A =
    new Section2GHSCategoryId(
      2025009,
      '区分3A ; Category3A',
      '区分3A',
      'Category3A',
      Section2GHSItemId.RESPIRATORY_SENSITIZATION.value
    );
  static readonly RESPIRATORY_SENSITIZATION_CATEGORY_3B =
    new Section2GHSCategoryId(
      2025010,
      '区分3B ; Category3B',
      '区分3B',
      'Category3B',
      Section2GHSItemId.RESPIRATORY_SENSITIZATION.value
    );
  static readonly RESPIRATORY_SENSITIZATION_CATEGORY_3C =
    new Section2GHSCategoryId(
      2025011,
      '区分3C ; Category3C',
      '区分3C',
      'Category3C',
      Section2GHSItemId.RESPIRATORY_SENSITIZATION.value
    );
  static readonly RESPIRATORY_SENSITIZATION_CATEGORY_4 =
    new Section2GHSCategoryId(
      2025012,
      '区分4 ; Category4',
      '区分4',
      'Category4',
      Section2GHSItemId.RESPIRATORY_SENSITIZATION.value
    );
  static readonly RESPIRATORY_SENSITIZATION_CATEGORY_4A =
    new Section2GHSCategoryId(
      2025013,
      '区分4A ; Category4A',
      '区分4A',
      'Category4A',
      Section2GHSItemId.RESPIRATORY_SENSITIZATION.value
    );
  static readonly RESPIRATORY_SENSITIZATION_CATEGORY_4B =
    new Section2GHSCategoryId(
      2025014,
      '区分4B ; Category4B',
      '区分4B',
      'Category4B',
      Section2GHSItemId.RESPIRATORY_SENSITIZATION.value
    );
  static readonly RESPIRATORY_SENSITIZATION_CATEGORY_4C =
    new Section2GHSCategoryId(
      2025015,
      '区分4C ; Category4C',
      '区分4C',
      'Category4C',
      Section2GHSItemId.RESPIRATORY_SENSITIZATION.value
    );
  static readonly RESPIRATORY_SENSITIZATION_CATEGORY_5 =
    new Section2GHSCategoryId(
      2025016,
      '区分5 ; Category5',
      '区分5',
      'Category5',
      Section2GHSItemId.RESPIRATORY_SENSITIZATION.value
    );
  static readonly RESPIRATORY_SENSITIZATION_CATEGORY_5A =
    new Section2GHSCategoryId(
      2025017,
      '区分5A ; Category5A',
      '区分5A',
      'Category5A',
      Section2GHSItemId.RESPIRATORY_SENSITIZATION.value
    );
  static readonly RESPIRATORY_SENSITIZATION_CATEGORY_5B =
    new Section2GHSCategoryId(
      2025018,
      '区分5B ; Category5B',
      '区分5B',
      'Category5B',
      Section2GHSItemId.RESPIRATORY_SENSITIZATION.value
    );
  static readonly RESPIRATORY_SENSITIZATION_CATEGORY_5C =
    new Section2GHSCategoryId(
      2025019,
      '区分5C ; Category5C',
      '区分5C',
      'Category5C',
      Section2GHSItemId.RESPIRATORY_SENSITIZATION.value
    );
  static readonly SKIN_SENSITIZATION_CATEGORY_1 = new Section2GHSCategoryId(
    2026000,
    '区分1 ; Category1',
    '区分1',
    'Category1',
    Section2GHSItemId.SKIN_SENSITIZATION.value
  );
  static readonly SKIN_SENSITIZATION_CATEGORY_1A = new Section2GHSCategoryId(
    2026001,
    '区分1A ; Category1A',
    '区分1A',
    'Category1A',
    Section2GHSItemId.SKIN_SENSITIZATION.value
  );
  static readonly SKIN_SENSITIZATION_CATEGORY_1B = new Section2GHSCategoryId(
    2026002,
    '区分1B ; Category1B',
    '区分1B',
    'Category1B',
    Section2GHSItemId.SKIN_SENSITIZATION.value
  );
  static readonly SKIN_SENSITIZATION_CATEGORY_1C = new Section2GHSCategoryId(
    2026003,
    '区分1C ; Category1C',
    '区分1C',
    'Category1C',
    Section2GHSItemId.SKIN_SENSITIZATION.value
  );
  static readonly SKIN_SENSITIZATION_CATEGORY_2 = new Section2GHSCategoryId(
    2026004,
    '区分2 ; Category2',
    '区分2',
    'Category2',
    Section2GHSItemId.SKIN_SENSITIZATION.value
  );
  static readonly SKIN_SENSITIZATION_CATEGORY_2A = new Section2GHSCategoryId(
    2026005,
    '区分2A ; Category2A',
    '区分2A',
    'Category2A',
    Section2GHSItemId.SKIN_SENSITIZATION.value
  );
  static readonly SKIN_SENSITIZATION_CATEGORY_2B = new Section2GHSCategoryId(
    2026006,
    '区分2B ; Category2B',
    '区分2B',
    'Category2B',
    Section2GHSItemId.SKIN_SENSITIZATION.value
  );
  static readonly SKIN_SENSITIZATION_CATEGORY_2C = new Section2GHSCategoryId(
    2026007,
    '区分2C ; Category2C',
    '区分2C',
    'Category2C',
    Section2GHSItemId.SKIN_SENSITIZATION.value
  );
  static readonly SKIN_SENSITIZATION_CATEGORY_3 = new Section2GHSCategoryId(
    2026008,
    '区分3 ; Category3',
    '区分3',
    'Category3',
    Section2GHSItemId.SKIN_SENSITIZATION.value
  );
  static readonly SKIN_SENSITIZATION_CATEGORY_3A = new Section2GHSCategoryId(
    2026009,
    '区分3A ; Category3A',
    '区分3A',
    'Category3A',
    Section2GHSItemId.SKIN_SENSITIZATION.value
  );
  static readonly SKIN_SENSITIZATION_CATEGORY_3B = new Section2GHSCategoryId(
    2026010,
    '区分3B ; Category3B',
    '区分3B',
    'Category3B',
    Section2GHSItemId.SKIN_SENSITIZATION.value
  );
  static readonly SKIN_SENSITIZATION_CATEGORY_3C = new Section2GHSCategoryId(
    2026011,
    '区分3C ; Category3C',
    '区分3C',
    'Category3C',
    Section2GHSItemId.SKIN_SENSITIZATION.value
  );
  static readonly SKIN_SENSITIZATION_CATEGORY_4 = new Section2GHSCategoryId(
    2026012,
    '区分4 ; Category4',
    '区分4',
    'Category4',
    Section2GHSItemId.SKIN_SENSITIZATION.value
  );
  static readonly SKIN_SENSITIZATION_CATEGORY_4A = new Section2GHSCategoryId(
    2026013,
    '区分4A ; Category4A',
    '区分4A',
    'Category4A',
    Section2GHSItemId.SKIN_SENSITIZATION.value
  );
  static readonly SKIN_SENSITIZATION_CATEGORY_4B = new Section2GHSCategoryId(
    2026014,
    '区分4B ; Category4B',
    '区分4B',
    'Category4B',
    Section2GHSItemId.SKIN_SENSITIZATION.value
  );
  static readonly SKIN_SENSITIZATION_CATEGORY_4C = new Section2GHSCategoryId(
    2026015,
    '区分4C ; Category4C',
    '区分4C',
    'Category4C',
    Section2GHSItemId.SKIN_SENSITIZATION.value
  );
  static readonly SKIN_SENSITIZATION_CATEGORY_5 = new Section2GHSCategoryId(
    2026016,
    '区分5 ; Category5',
    '区分5',
    'Category5',
    Section2GHSItemId.SKIN_SENSITIZATION.value
  );
  static readonly SKIN_SENSITIZATION_CATEGORY_5A = new Section2GHSCategoryId(
    2026017,
    '区分5A ; Category5A',
    '区分5A',
    'Category5A',
    Section2GHSItemId.SKIN_SENSITIZATION.value
  );
  static readonly SKIN_SENSITIZATION_CATEGORY_5B = new Section2GHSCategoryId(
    2026018,
    '区分5B ; Category5B',
    '区分5B',
    'Category5B',
    Section2GHSItemId.SKIN_SENSITIZATION.value
  );
  static readonly SKIN_SENSITIZATION_CATEGORY_5C = new Section2GHSCategoryId(
    2026019,
    '区分5C ; Category5C',
    '区分5C',
    'Category5C',
    Section2GHSItemId.SKIN_SENSITIZATION.value
  );
  static readonly GERM_CELL_MUTAGENICITY_CATEGORY_1 = new Section2GHSCategoryId(
    2027000,
    '区分1 ; Category1',
    '区分1',
    'Category1',
    Section2GHSItemId.GERM_CELL_MUTAGENICITY.value
  );
  static readonly GERM_CELL_MUTAGENICITY_CATEGORY_1A =
    new Section2GHSCategoryId(
      2027001,
      '区分1A ; Category1A',
      '区分1A',
      'Category1A',
      Section2GHSItemId.GERM_CELL_MUTAGENICITY.value
    );
  static readonly GERM_CELL_MUTAGENICITY_CATEGORY_1B =
    new Section2GHSCategoryId(
      2027002,
      '区分1B ; Category1B',
      '区分1B',
      'Category1B',
      Section2GHSItemId.GERM_CELL_MUTAGENICITY.value
    );
  static readonly GERM_CELL_MUTAGENICITY_CATEGORY_1C =
    new Section2GHSCategoryId(
      2027003,
      '区分1C ; Category1C',
      '区分1C',
      'Category1C',
      Section2GHSItemId.GERM_CELL_MUTAGENICITY.value
    );
  static readonly GERM_CELL_MUTAGENICITY_CATEGORY_2 = new Section2GHSCategoryId(
    2027004,
    '区分2 ; Category2',
    '区分2',
    'Category2',
    Section2GHSItemId.GERM_CELL_MUTAGENICITY.value
  );
  static readonly GERM_CELL_MUTAGENICITY_CATEGORY_2A =
    new Section2GHSCategoryId(
      2027005,
      '区分2A ; Category2A',
      '区分2A',
      'Category2A',
      Section2GHSItemId.GERM_CELL_MUTAGENICITY.value
    );
  static readonly GERM_CELL_MUTAGENICITY_CATEGORY_2B =
    new Section2GHSCategoryId(
      2027006,
      '区分2B ; Category2B',
      '区分2B',
      'Category2B',
      Section2GHSItemId.GERM_CELL_MUTAGENICITY.value
    );
  static readonly GERM_CELL_MUTAGENICITY_CATEGORY_2C =
    new Section2GHSCategoryId(
      2027007,
      '区分2C ; Category2C',
      '区分2C',
      'Category2C',
      Section2GHSItemId.GERM_CELL_MUTAGENICITY.value
    );
  static readonly GERM_CELL_MUTAGENICITY_CATEGORY_3 = new Section2GHSCategoryId(
    2027008,
    '区分3 ; Category3',
    '区分3',
    'Category3',
    Section2GHSItemId.GERM_CELL_MUTAGENICITY.value
  );
  static readonly GERM_CELL_MUTAGENICITY_CATEGORY_3A =
    new Section2GHSCategoryId(
      2027009,
      '区分3A ; Category3A',
      '区分3A',
      'Category3A',
      Section2GHSItemId.GERM_CELL_MUTAGENICITY.value
    );
  static readonly GERM_CELL_MUTAGENICITY_CATEGORY_3B =
    new Section2GHSCategoryId(
      2027010,
      '区分3B ; Category3B',
      '区分3B',
      'Category3B',
      Section2GHSItemId.GERM_CELL_MUTAGENICITY.value
    );
  static readonly GERM_CELL_MUTAGENICITY_CATEGORY_3C =
    new Section2GHSCategoryId(
      2027011,
      '区分3C ; Category3C',
      '区分3C',
      'Category3C',
      Section2GHSItemId.GERM_CELL_MUTAGENICITY.value
    );
  static readonly GERM_CELL_MUTAGENICITY_CATEGORY_4 = new Section2GHSCategoryId(
    2027012,
    '区分4 ; Category4',
    '区分4',
    'Category4',
    Section2GHSItemId.GERM_CELL_MUTAGENICITY.value
  );
  static readonly GERM_CELL_MUTAGENICITY_CATEGORY_4A =
    new Section2GHSCategoryId(
      2027013,
      '区分4A ; Category4A',
      '区分4A',
      'Category4A',
      Section2GHSItemId.GERM_CELL_MUTAGENICITY.value
    );
  static readonly GERM_CELL_MUTAGENICITY_CATEGORY_4B =
    new Section2GHSCategoryId(
      2027014,
      '区分4B ; Category4B',
      '区分4B',
      'Category4B',
      Section2GHSItemId.GERM_CELL_MUTAGENICITY.value
    );
  static readonly GERM_CELL_MUTAGENICITY_CATEGORY_4C =
    new Section2GHSCategoryId(
      2027015,
      '区分4C ; Category4C',
      '区分4C',
      'Category4C',
      Section2GHSItemId.GERM_CELL_MUTAGENICITY.value
    );
  static readonly GERM_CELL_MUTAGENICITY_CATEGORY_5 = new Section2GHSCategoryId(
    2027016,
    '区分5 ; Category5',
    '区分5',
    'Category5',
    Section2GHSItemId.GERM_CELL_MUTAGENICITY.value
  );
  static readonly GERM_CELL_MUTAGENICITY_CATEGORY_5A =
    new Section2GHSCategoryId(
      2027017,
      '区分5A ; Category5A',
      '区分5A',
      'Category5A',
      Section2GHSItemId.GERM_CELL_MUTAGENICITY.value
    );
  static readonly GERM_CELL_MUTAGENICITY_CATEGORY_5B =
    new Section2GHSCategoryId(
      2027018,
      '区分5B ; Category5B',
      '区分5B',
      'Category5B',
      Section2GHSItemId.GERM_CELL_MUTAGENICITY.value
    );
  static readonly GERM_CELL_MUTAGENICITY_CATEGORY_5C =
    new Section2GHSCategoryId(
      2027019,
      '区分5C ; Category5C',
      '区分5C',
      'Category5C',
      Section2GHSItemId.GERM_CELL_MUTAGENICITY.value
    );
  static readonly CARCINOGENICITY_CATEGORY_1 = new Section2GHSCategoryId(
    2028000,
    '区分1 ; Category1',
    '区分1',
    'Category1',
    Section2GHSItemId.CARCINOGENICITY.value
  );
  static readonly CARCINOGENICITY_CATEGORY_1A = new Section2GHSCategoryId(
    2028001,
    '区分1A ; Category1A',
    '区分1A',
    'Category1A',
    Section2GHSItemId.CARCINOGENICITY.value
  );
  static readonly CARCINOGENICITY_CATEGORY_1B = new Section2GHSCategoryId(
    2028002,
    '区分1B ; Category1B',
    '区分1B',
    'Category1B',
    Section2GHSItemId.CARCINOGENICITY.value
  );
  static readonly CARCINOGENICITY_CATEGORY_1C = new Section2GHSCategoryId(
    2028003,
    '区分1C ; Category1C',
    '区分1C',
    'Category1C',
    Section2GHSItemId.CARCINOGENICITY.value
  );
  static readonly CARCINOGENICITY_CATEGORY_2 = new Section2GHSCategoryId(
    2028004,
    '区分2 ; Category2',
    '区分2',
    'Category2',
    Section2GHSItemId.CARCINOGENICITY.value
  );
  static readonly CARCINOGENICITY_CATEGORY_2A = new Section2GHSCategoryId(
    2028005,
    '区分2A ; Category2A',
    '区分2A',
    'Category2A',
    Section2GHSItemId.CARCINOGENICITY.value
  );
  static readonly CARCINOGENICITY_CATEGORY_2B = new Section2GHSCategoryId(
    2028006,
    '区分2B ; Category2B',
    '区分2B',
    'Category2B',
    Section2GHSItemId.CARCINOGENICITY.value
  );
  static readonly CARCINOGENICITY_CATEGORY_2C = new Section2GHSCategoryId(
    2028007,
    '区分2C ; Category2C',
    '区分2C',
    'Category2C',
    Section2GHSItemId.CARCINOGENICITY.value
  );
  static readonly CARCINOGENICITY_CATEGORY_3 = new Section2GHSCategoryId(
    2028008,
    '区分3 ; Category3',
    '区分3',
    'Category3',
    Section2GHSItemId.CARCINOGENICITY.value
  );
  static readonly CARCINOGENICITY_CATEGORY_3A = new Section2GHSCategoryId(
    2028009,
    '区分3A ; Category3A',
    '区分3A',
    'Category3A',
    Section2GHSItemId.CARCINOGENICITY.value
  );
  static readonly CARCINOGENICITY_CATEGORY_3B = new Section2GHSCategoryId(
    2028010,
    '区分3B ; Category3B',
    '区分3B',
    'Category3B',
    Section2GHSItemId.CARCINOGENICITY.value
  );
  static readonly CARCINOGENICITY_CATEGORY_3C = new Section2GHSCategoryId(
    2028011,
    '区分3C ; Category3C',
    '区分3C',
    'Category3C',
    Section2GHSItemId.CARCINOGENICITY.value
  );
  static readonly CARCINOGENICITY_CATEGORY_4 = new Section2GHSCategoryId(
    2028012,
    '区分4 ; Category4',
    '区分4',
    'Category4',
    Section2GHSItemId.CARCINOGENICITY.value
  );
  static readonly CARCINOGENICITY_CATEGORY_4A = new Section2GHSCategoryId(
    2028013,
    '区分4A ; Category4A',
    '区分4A',
    'Category4A',
    Section2GHSItemId.CARCINOGENICITY.value
  );
  static readonly CARCINOGENICITY_CATEGORY_4B = new Section2GHSCategoryId(
    2028014,
    '区分4B ; Category4B',
    '区分4B',
    'Category4B',
    Section2GHSItemId.CARCINOGENICITY.value
  );
  static readonly CARCINOGENICITY_CATEGORY_4C = new Section2GHSCategoryId(
    2028015,
    '区分4C ; Category4C',
    '区分4C',
    'Category4C',
    Section2GHSItemId.CARCINOGENICITY.value
  );
  static readonly CARCINOGENICITY_CATEGORY_5 = new Section2GHSCategoryId(
    2028016,
    '区分5 ; Category5',
    '区分5',
    'Category5',
    Section2GHSItemId.CARCINOGENICITY.value
  );
  static readonly CARCINOGENICITY_CATEGORY_5A = new Section2GHSCategoryId(
    2028017,
    '区分5A ; Category5A',
    '区分5A',
    'Category5A',
    Section2GHSItemId.CARCINOGENICITY.value
  );
  static readonly CARCINOGENICITY_CATEGORY_5B = new Section2GHSCategoryId(
    2028018,
    '区分5B ; Category5B',
    '区分5B',
    'Category5B',
    Section2GHSItemId.CARCINOGENICITY.value
  );
  static readonly CARCINOGENICITY_CATEGORY_5C = new Section2GHSCategoryId(
    2028019,
    '区分5C ; Category5C',
    '区分5C',
    'Category5C',
    Section2GHSItemId.CARCINOGENICITY.value
  );
  static readonly REPRODUCTIVE_TOXICITY_CATEGORY_1 = new Section2GHSCategoryId(
    2029000,
    '区分1 ; Category1',
    '区分1',
    'Category1',
    Section2GHSItemId.REPRODUCTIVE_TOXICITY.value
  );
  static readonly REPRODUCTIVE_TOXICITY_CATEGORY_1A = new Section2GHSCategoryId(
    2029001,
    '区分1A ; Category1A',
    '区分1A',
    'Category1A',
    Section2GHSItemId.REPRODUCTIVE_TOXICITY.value
  );
  static readonly REPRODUCTIVE_TOXICITY_CATEGORY_1B = new Section2GHSCategoryId(
    2029002,
    '区分1B ; Category1B',
    '区分1B',
    'Category1B',
    Section2GHSItemId.REPRODUCTIVE_TOXICITY.value
  );
  static readonly REPRODUCTIVE_TOXICITY_CATEGORY_1C = new Section2GHSCategoryId(
    2029003,
    '区分1C ; Category1C',
    '区分1C',
    'Category1C',
    Section2GHSItemId.REPRODUCTIVE_TOXICITY.value
  );
  static readonly REPRODUCTIVE_TOXICITY_CATEGORY_2 = new Section2GHSCategoryId(
    2029004,
    '区分2 ; Category2',
    '区分2',
    'Category2',
    Section2GHSItemId.REPRODUCTIVE_TOXICITY.value
  );
  static readonly REPRODUCTIVE_TOXICITY_CATEGORY_2A = new Section2GHSCategoryId(
    2029005,
    '区分2A ; Category2A',
    '区分2A',
    'Category2A',
    Section2GHSItemId.REPRODUCTIVE_TOXICITY.value
  );
  static readonly REPRODUCTIVE_TOXICITY_CATEGORY_2B = new Section2GHSCategoryId(
    2029006,
    '区分2B ; Category2B',
    '区分2B',
    'Category2B',
    Section2GHSItemId.REPRODUCTIVE_TOXICITY.value
  );
  static readonly REPRODUCTIVE_TOXICITY_CATEGORY_2C = new Section2GHSCategoryId(
    2029007,
    '区分2C ; Category2C',
    '区分2C',
    'Category2C',
    Section2GHSItemId.REPRODUCTIVE_TOXICITY.value
  );
  static readonly REPRODUCTIVE_TOXICITY_CATEGORY_3 = new Section2GHSCategoryId(
    2029008,
    '区分3 ; Category3',
    '区分3',
    'Category3',
    Section2GHSItemId.REPRODUCTIVE_TOXICITY.value
  );
  static readonly REPRODUCTIVE_TOXICITY_CATEGORY_3A = new Section2GHSCategoryId(
    2029009,
    '区分3A ; Category3A',
    '区分3A',
    'Category3A',
    Section2GHSItemId.REPRODUCTIVE_TOXICITY.value
  );
  static readonly REPRODUCTIVE_TOXICITY_CATEGORY_3B = new Section2GHSCategoryId(
    2029010,
    '区分3B ; Category3B',
    '区分3B',
    'Category3B',
    Section2GHSItemId.REPRODUCTIVE_TOXICITY.value
  );
  static readonly REPRODUCTIVE_TOXICITY_CATEGORY_3C = new Section2GHSCategoryId(
    2029011,
    '区分3C ; Category3C',
    '区分3C',
    'Category3C',
    Section2GHSItemId.REPRODUCTIVE_TOXICITY.value
  );
  static readonly REPRODUCTIVE_TOXICITY_CATEGORY_4 = new Section2GHSCategoryId(
    2029012,
    '区分4 ; Category4',
    '区分4',
    'Category4',
    Section2GHSItemId.REPRODUCTIVE_TOXICITY.value
  );
  static readonly REPRODUCTIVE_TOXICITY_CATEGORY_4A = new Section2GHSCategoryId(
    2029013,
    '区分4A ; Category4A',
    '区分4A',
    'Category4A',
    Section2GHSItemId.REPRODUCTIVE_TOXICITY.value
  );
  static readonly REPRODUCTIVE_TOXICITY_CATEGORY_4B = new Section2GHSCategoryId(
    2029014,
    '区分4B ; Category4B',
    '区分4B',
    'Category4B',
    Section2GHSItemId.REPRODUCTIVE_TOXICITY.value
  );
  static readonly REPRODUCTIVE_TOXICITY_CATEGORY_4C = new Section2GHSCategoryId(
    2029015,
    '区分4C ; Category4C',
    '区分4C',
    'Category4C',
    Section2GHSItemId.REPRODUCTIVE_TOXICITY.value
  );
  static readonly REPRODUCTIVE_TOXICITY_CATEGORY_5 = new Section2GHSCategoryId(
    2029016,
    '区分5 ; Category5',
    '区分5',
    'Category5',
    Section2GHSItemId.REPRODUCTIVE_TOXICITY.value
  );
  static readonly REPRODUCTIVE_TOXICITY_CATEGORY_5A = new Section2GHSCategoryId(
    2029017,
    '区分5A ; Category5A',
    '区分5A',
    'Category5A',
    Section2GHSItemId.REPRODUCTIVE_TOXICITY.value
  );
  static readonly REPRODUCTIVE_TOXICITY_CATEGORY_5B = new Section2GHSCategoryId(
    2029018,
    '区分5B ; Category5B',
    '区分5B',
    'Category5B',
    Section2GHSItemId.REPRODUCTIVE_TOXICITY.value
  );
  static readonly REPRODUCTIVE_TOXICITY_CATEGORY_5C = new Section2GHSCategoryId(
    2029019,
    '区分5C ; Category5C',
    '区分5C',
    'Category5C',
    Section2GHSItemId.REPRODUCTIVE_TOXICITY.value
  );
  static readonly REPRODUCTIVE_TOXICITY_CATEGORY_ADDITIONAL =
    new Section2GHSCategoryId(
      2029020,
      '追加区分 ; Additional Category',
      '追加区分',
      'Additional Category',
      Section2GHSItemId.REPRODUCTIVE_TOXICITY.value
    );
  static readonly REPRODUCTIVE_TOXICITY_NOT_CATEGORY_ADDITIONAL =
    new Section2GHSCategoryId(
      2029021,
      '追加区分に該当しない; Not classified as Additional Category',
      '追加区分に該当しない',
      'Not classified as Additional Category',
      Section2GHSItemId.REPRODUCTIVE_TOXICITY.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_CATEGORY_1 =
    new Section2GHSCategoryId(
      2030000,
      '区分1 ; Category1',
      '区分1',
      'Category1',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_CATEGORY_1A =
    new Section2GHSCategoryId(
      2030001,
      '区分1A ; Category1A',
      '区分1A',
      'Category1A',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_CATEGORY_1B =
    new Section2GHSCategoryId(
      2030002,
      '区分1B ; Category1B',
      '区分1B',
      'Category1B',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_CATEGORY_1C =
    new Section2GHSCategoryId(
      2030003,
      '区分1C ; Category1C',
      '区分1C',
      'Category1C',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_CATEGORY_2 =
    new Section2GHSCategoryId(
      2030004,
      '区分2 ; Category2',
      '区分2',
      'Category2',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_CATEGORY_2A =
    new Section2GHSCategoryId(
      2030005,
      '区分2A ; Category2A',
      '区分2A',
      'Category2A',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_CATEGORY_2B =
    new Section2GHSCategoryId(
      2030006,
      '区分2B ; Category2B',
      '区分2B',
      'Category2B',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_CATEGORY_2C =
    new Section2GHSCategoryId(
      2030007,
      '区分2C ; Category2C',
      '区分2C',
      'Category2C',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_CATEGORY_3 =
    new Section2GHSCategoryId(
      2030008,
      '区分3 ; Category3',
      '区分3',
      'Category3',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_CATEGORY_3A =
    new Section2GHSCategoryId(
      2030009,
      '区分3A ; Category3A',
      '区分3A',
      'Category3A',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_CATEGORY_3B =
    new Section2GHSCategoryId(
      2030010,
      '区分3B ; Category3B',
      '区分3B',
      'Category3B',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_CATEGORY_3C =
    new Section2GHSCategoryId(
      2030011,
      '区分3C ; Category3C',
      '区分3C',
      'Category3C',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_CATEGORY_4 =
    new Section2GHSCategoryId(
      2030012,
      '区分4 ; Category4',
      '区分4',
      'Category4',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_CATEGORY_4A =
    new Section2GHSCategoryId(
      2030013,
      '区分4 ; Category4A',
      '区分4A',
      'Category4A',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_CATEGORY_4B =
    new Section2GHSCategoryId(
      2030014,
      '区分4 ; Category4B',
      '区分4B',
      'Category4B',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_CATEGORY_4C =
    new Section2GHSCategoryId(
      2030015,
      '区分4 ; Category4C',
      '区分4C',
      'Category4C',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_CATEGORY_5 =
    new Section2GHSCategoryId(
      2030016,
      '区分5 ; Category5',
      '区分5',
      'Category5',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_CATEGORY_5A =
    new Section2GHSCategoryId(
      2030017,
      '区分5A ; Category5A',
      '区分5A',
      'Category5A',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_CATEGORY_5B =
    new Section2GHSCategoryId(
      2030018,
      '区分5B ; Category5B',
      '区分5B',
      'Category5B',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_CATEGORY_5C =
    new Section2GHSCategoryId(
      2030019,
      '区分5C ; Category5C',
      '区分5C',
      'Category5C',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_CATEGORY_1 =
    new Section2GHSCategoryId(
      2031000,
      '区分1 ; Category1',
      '区分1',
      'Category1',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_CATEGORY_1A =
    new Section2GHSCategoryId(
      2031001,
      '区分1A ; Category1A',
      '区分1A',
      'Category1A',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_CATEGORY_1B =
    new Section2GHSCategoryId(
      2031002,
      '区分1B ; Category1B',
      '区分1B',
      'Category1B',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_CATEGORY_1C =
    new Section2GHSCategoryId(
      2031003,
      '区分1C ; Category1C',
      '区分1C',
      'Category1C',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_CATEGORY_2 =
    new Section2GHSCategoryId(
      2031004,
      '区分2 ; Category2',
      '区分2',
      'Category2',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_CATEGORY_2A =
    new Section2GHSCategoryId(
      2031005,
      '区分2A ; Category2A',
      '区分2A',
      'Category2A',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_CATEGORY_2B =
    new Section2GHSCategoryId(
      2031006,
      '区分2B ; Category2B',
      '区分2B',
      'Category2B',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_CATEGORY_2C =
    new Section2GHSCategoryId(
      2031007,
      '区分2C ; Category2C',
      '区分2C',
      'Category2C',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_CATEGORY_3 =
    new Section2GHSCategoryId(
      2031008,
      '区分3 ; Category3',
      '区分3',
      'Category3',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_CATEGORY_3A =
    new Section2GHSCategoryId(
      2031009,
      '区分3A ; Category3A',
      '区分3A',
      'Category3A',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_CATEGORY_3B =
    new Section2GHSCategoryId(
      2031010,
      '区分3B ; Category3B',
      '区分3B',
      'Category3B',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_CATEGORY_3C =
    new Section2GHSCategoryId(
      2031011,
      '区分3C ; Category3C',
      '区分3C',
      'Category3C',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_CATEGORY_4 =
    new Section2GHSCategoryId(
      2031012,
      '区分4 ; Category4',
      '区分4',
      'Category4',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_CATEGORY_4A =
    new Section2GHSCategoryId(
      2031013,
      '区分4A ; Category4A',
      '区分4A',
      'Category4A',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_CATEGORY_4B =
    new Section2GHSCategoryId(
      2031014,
      '区分4B ; Category4B',
      '区分4B',
      'Category4B',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_CATEGORY_4C =
    new Section2GHSCategoryId(
      2031015,
      '区分4C ; Category4C',
      '区分4C',
      'Category4C',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_CATEGORY_5 =
    new Section2GHSCategoryId(
      2031016,
      '区分5 ; Category5',
      '区分5',
      'Category5',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_CATEGORY_5A =
    new Section2GHSCategoryId(
      2031017,
      '区分5A ; Category5A',
      '区分5A',
      'Category5A',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_CATEGORY_5B =
    new Section2GHSCategoryId(
      2031018,
      '区分5B ; Category5B',
      '区分5B',
      'Category5B',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_CATEGORY_5C =
    new Section2GHSCategoryId(
      2031019,
      '区分5C ; Category5C',
      '区分5C',
      'Category5C',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly ASPIRATION_HAZARD_CATEGORY_1 = new Section2GHSCategoryId(
    2032000,
    '区分1 ; Category1',
    '区分1',
    'Category1',
    Section2GHSItemId.ASPIRATION_HAZARD.value
  );
  static readonly ASPIRATION_HAZARD_CATEGORY_1A = new Section2GHSCategoryId(
    2032001,
    '区分1A ; Category1A',
    '区分1A',
    'Category1A',
    Section2GHSItemId.ASPIRATION_HAZARD.value
  );
  static readonly ASPIRATION_HAZARD_CATEGORY_1B = new Section2GHSCategoryId(
    2032002,
    '区分1B ; Category1B',
    '区分1B',
    'Category1B',
    Section2GHSItemId.ASPIRATION_HAZARD.value
  );
  static readonly ASPIRATION_HAZARD_CATEGORY_1C = new Section2GHSCategoryId(
    2032003,
    '区分1C ; Category1C',
    '区分1C',
    'Category1C',
    Section2GHSItemId.ASPIRATION_HAZARD.value
  );
  static readonly ASPIRATION_HAZARD_CATEGORY_2 = new Section2GHSCategoryId(
    2032004,
    '区分2 ; Category2',
    '区分2',
    'Category2',
    Section2GHSItemId.ASPIRATION_HAZARD.value
  );
  static readonly ASPIRATION_HAZARD_CATEGORY_2A = new Section2GHSCategoryId(
    2032005,
    '区分2A ; Category2A',
    '区分2A',
    'Category2A',
    Section2GHSItemId.ASPIRATION_HAZARD.value
  );
  static readonly ASPIRATION_HAZARD_CATEGORY_2B = new Section2GHSCategoryId(
    2032006,
    '区分2B ; Category2B',
    '区分2B',
    'Category2B',
    Section2GHSItemId.ASPIRATION_HAZARD.value
  );
  static readonly ASPIRATION_HAZARD_CATEGORY_2C = new Section2GHSCategoryId(
    2032007,
    '区分2C ; Category2C',
    '区分2C',
    'Category2C',
    Section2GHSItemId.ASPIRATION_HAZARD.value
  );
  static readonly ASPIRATION_HAZARD_CATEGORY_3 = new Section2GHSCategoryId(
    2032008,
    '区分3 ; Category3',
    '区分3',
    'Category3',
    Section2GHSItemId.ASPIRATION_HAZARD.value
  );
  static readonly ASPIRATION_HAZARD_CATEGORY_3A = new Section2GHSCategoryId(
    2032009,
    '区分3A ; Category3A',
    '区分3A',
    'Category3A',
    Section2GHSItemId.ASPIRATION_HAZARD.value
  );
  static readonly ASPIRATION_HAZARD_CATEGORY_3B = new Section2GHSCategoryId(
    2032010,
    '区分3B ; Category3B',
    '区分3B',
    'Category3B',
    Section2GHSItemId.ASPIRATION_HAZARD.value
  );
  static readonly ASPIRATION_HAZARD_CATEGORY_3C = new Section2GHSCategoryId(
    2032011,
    '区分3C ; Category3C',
    '区分3C',
    'Category3C',
    Section2GHSItemId.ASPIRATION_HAZARD.value
  );
  static readonly ASPIRATION_HAZARD_CATEGORY_4 = new Section2GHSCategoryId(
    2032012,
    '区分4 ; Category4',
    '区分4',
    'Category4',
    Section2GHSItemId.ASPIRATION_HAZARD.value
  );
  static readonly ASPIRATION_HAZARD_CATEGORY_4A = new Section2GHSCategoryId(
    2032013,
    '区分4A ; Category4A',
    '区分4A',
    'Category4A',
    Section2GHSItemId.ASPIRATION_HAZARD.value
  );
  static readonly ASPIRATION_HAZARD_CATEGORY_4B = new Section2GHSCategoryId(
    2032014,
    '区分4B ; Category4B',
    '区分4B',
    'Category4B',
    Section2GHSItemId.ASPIRATION_HAZARD.value
  );
  static readonly ASPIRATION_HAZARD_CATEGORY_4C = new Section2GHSCategoryId(
    2032015,
    '区分4C ; Category4C',
    '区分4C',
    'Category4C',
    Section2GHSItemId.ASPIRATION_HAZARD.value
  );
  static readonly ASPIRATION_HAZARD_CATEGORY_5 = new Section2GHSCategoryId(
    2032016,
    '区分5 ; Category5',
    '区分5',
    'Category5',
    Section2GHSItemId.ASPIRATION_HAZARD.value
  );
  static readonly ASPIRATION_HAZARD_CATEGORY_5A = new Section2GHSCategoryId(
    2032017,
    '区分5A ; Category5A',
    '区分5A',
    'Category5A',
    Section2GHSItemId.ASPIRATION_HAZARD.value
  );
  static readonly ASPIRATION_HAZARD_CATEGORY_5B = new Section2GHSCategoryId(
    2032018,
    '区分5B ; Category5B',
    '区分5B',
    'Category5B',
    Section2GHSItemId.ASPIRATION_HAZARD.value
  );
  static readonly ASPIRATION_HAZARD_CATEGORY_5C = new Section2GHSCategoryId(
    2032019,
    '区分5C ; Category5C',
    '区分5C',
    'Category5C',
    Section2GHSItemId.ASPIRATION_HAZARD.value
  );
  static readonly HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_ACUTE_CATEGORY_1 =
    new Section2GHSCategoryId(
      2033000,
      '区分1 ; Category1',
      '区分1',
      'Category1',
      Section2GHSItemId.HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_ACUTE.value
    );
  static readonly HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_ACUTE_CATEGORY_1A =
    new Section2GHSCategoryId(
      2033001,
      '区分1A ; Category1A',
      '区分1A',
      'Category1A',
      Section2GHSItemId.HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_ACUTE.value
    );
  static readonly HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_ACUTE_CATEGORY_1B =
    new Section2GHSCategoryId(
      2033002,
      '区分1B ; Category1B',
      '区分1B',
      'Category1B',
      Section2GHSItemId.HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_ACUTE.value
    );
  static readonly HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_ACUTE_CATEGORY_1C =
    new Section2GHSCategoryId(
      2033003,
      '区分1C ; Category1C',
      '区分1C',
      'Category1C',
      Section2GHSItemId.HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_ACUTE.value
    );
  static readonly HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_ACUTE_CATEGORY_2 =
    new Section2GHSCategoryId(
      2033004,
      '区分2 ; Category2',
      '区分2',
      'Category2',
      Section2GHSItemId.HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_ACUTE.value
    );
  static readonly HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_ACUTE_CATEGORY_2A =
    new Section2GHSCategoryId(
      2033005,
      '区分2A ; Category2A',
      '区分2A',
      'Category2A',
      Section2GHSItemId.HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_ACUTE.value
    );
  static readonly HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_ACUTE_CATEGORY_2B =
    new Section2GHSCategoryId(
      2033006,
      '区分2B ; Category2B',
      '区分2B',
      'Category2B',
      Section2GHSItemId.HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_ACUTE.value
    );
  static readonly HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_ACUTE_CATEGORY_2C =
    new Section2GHSCategoryId(
      2033007,
      '区分2C ; Category2C',
      '区分2C',
      'Category2C',
      Section2GHSItemId.HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_ACUTE.value
    );
  static readonly HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_ACUTE_CATEGORY_3 =
    new Section2GHSCategoryId(
      2033008,
      '区分3 ; Category3',
      '区分3',
      'Category3',
      Section2GHSItemId.HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_ACUTE.value
    );
  static readonly HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_ACUTE_CATEGORY_3A =
    new Section2GHSCategoryId(
      2033009,
      '区分3A ; Category3A',
      '区分3A',
      'Category3A',
      Section2GHSItemId.HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_ACUTE.value
    );
  static readonly HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_ACUTE_CATEGORY_3B =
    new Section2GHSCategoryId(
      2033010,
      '区分3B ; Category3B',
      '区分3B',
      'Category3B',
      Section2GHSItemId.HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_ACUTE.value
    );
  static readonly HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_ACUTE_CATEGORY_3C =
    new Section2GHSCategoryId(
      2033011,
      '区分3C ; Category3C',
      '区分3C',
      'Category3C',
      Section2GHSItemId.HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_ACUTE.value
    );
  static readonly HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_ACUTE_CATEGORY_4 =
    new Section2GHSCategoryId(
      2033012,
      '区分4 ; Category4',
      '区分4',
      'Category4',
      Section2GHSItemId.HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_ACUTE.value
    );
  static readonly HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_ACUTE_CATEGORY_4A =
    new Section2GHSCategoryId(
      2033013,
      '区分4A ; Category4A',
      '区分4A',
      'Category4A',
      Section2GHSItemId.HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_ACUTE.value
    );
  static readonly HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_ACUTE_CATEGORY_4B =
    new Section2GHSCategoryId(
      2033014,
      '区分4B ; Category4B',
      '区分4B',
      'Category4B',
      Section2GHSItemId.HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_ACUTE.value
    );
  static readonly HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_ACUTE_CATEGORY_4C =
    new Section2GHSCategoryId(
      2033015,
      '区分4C ; Category4C',
      '区分4C',
      'Category4C',
      Section2GHSItemId.HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_ACUTE.value
    );
  static readonly HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_ACUTE_CATEGORY_5 =
    new Section2GHSCategoryId(
      2033016,
      '区分5 ; Category5',
      '区分5',
      'Category5',
      Section2GHSItemId.HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_ACUTE.value
    );
  static readonly HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_ACUTE_CATEGORY_5A =
    new Section2GHSCategoryId(
      2033017,
      '区分5A ; Category5A',
      '区分5A',
      'Category5A',
      Section2GHSItemId.HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_ACUTE.value
    );
  static readonly HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_ACUTE_CATEGORY_5B =
    new Section2GHSCategoryId(
      2033018,
      '区分5B ; Category5B',
      '区分5B',
      'Category5B',
      Section2GHSItemId.HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_ACUTE.value
    );
  static readonly HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_ACUTE_CATEGORY_5C =
    new Section2GHSCategoryId(
      2033019,
      '区分5C ; Category5C',
      '区分5C',
      'Category5C',
      Section2GHSItemId.HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_ACUTE.value
    );
  static readonly HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_LONG_TERM_CATEGORY_1 =
    new Section2GHSCategoryId(
      2034000,
      '区分1 ; Category1',
      '区分1',
      'Category1',
      Section2GHSItemId.HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_LONG_TERM.value
    );
  static readonly HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_LONG_TERM_CATEGORY_1A =
    new Section2GHSCategoryId(
      2034001,
      '区分1A ; Category1A',
      '区分1A',
      'Category1A',
      Section2GHSItemId.HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_LONG_TERM.value
    );
  static readonly HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_LONG_TERM_CATEGORY_1B =
    new Section2GHSCategoryId(
      2034002,
      '区分1B ; Category1B',
      '区分1B',
      'Category1B',
      Section2GHSItemId.HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_LONG_TERM.value
    );
  static readonly HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_LONG_TERM_CATEGORY_1C =
    new Section2GHSCategoryId(
      2034003,
      '区分1C ; Category1C',
      '区分1C',
      'Category1C',
      Section2GHSItemId.HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_LONG_TERM.value
    );
  static readonly HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_LONG_TERM_CATEGORY_2 =
    new Section2GHSCategoryId(
      2034004,
      '区分2 ; Category2',
      '区分2',
      'Category2',
      Section2GHSItemId.HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_LONG_TERM.value
    );
  static readonly HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_LONG_TERM_CATEGORY_2A =
    new Section2GHSCategoryId(
      2034005,
      '区分2A ; Category2A',
      '区分2A',
      'Category2A',
      Section2GHSItemId.HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_LONG_TERM.value
    );
  static readonly HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_LONG_TERM_CATEGORY_2B =
    new Section2GHSCategoryId(
      2034006,
      '区分2B ; Category2B',
      '区分2B',
      'Category2B',
      Section2GHSItemId.HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_LONG_TERM.value
    );
  static readonly HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_LONG_TERM_CATEGORY_2C =
    new Section2GHSCategoryId(
      2034007,
      '区分2C ; Category2C',
      '区分2C',
      'Category2C',
      Section2GHSItemId.HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_LONG_TERM.value
    );
  static readonly HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_LONG_TERM_CATEGORY_3 =
    new Section2GHSCategoryId(
      2034008,
      '区分3 ; Category3',
      '区分3',
      'Category3',
      Section2GHSItemId.HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_LONG_TERM.value
    );
  static readonly HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_LONG_TERM_CATEGORY_3A =
    new Section2GHSCategoryId(
      2034009,
      '区分3A ; Category3A',
      '区分3A',
      'Category3A',
      Section2GHSItemId.HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_LONG_TERM.value
    );
  static readonly HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_LONG_TERM_CATEGORY_3B =
    new Section2GHSCategoryId(
      2034010,
      '区分3B ; Category3B',
      '区分3B',
      'Category3B',
      Section2GHSItemId.HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_LONG_TERM.value
    );
  static readonly HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_LONG_TERM_CATEGORY_3C =
    new Section2GHSCategoryId(
      2034011,
      '区分3C ; Category3C',
      '区分3C',
      'Category3C',
      Section2GHSItemId.HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_LONG_TERM.value
    );
  static readonly HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_LONG_TERM_CATEGORY_4 =
    new Section2GHSCategoryId(
      2034012,
      '区分4 ; Category4',
      '区分4',
      'Category4',
      Section2GHSItemId.HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_LONG_TERM.value
    );
  static readonly HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_LONG_TERM_CATEGORY_4A =
    new Section2GHSCategoryId(
      2034013,
      '区分4A ; Category4A',
      '区分4A',
      'Category4A',
      Section2GHSItemId.HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_LONG_TERM.value
    );
  static readonly HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_LONG_TERM_CATEGORY_4B =
    new Section2GHSCategoryId(
      2034014,
      '区分4B ; Category4B',
      '区分4B',
      'Category4B',
      Section2GHSItemId.HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_LONG_TERM.value
    );
  static readonly HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_LONG_TERM_CATEGORY_4C =
    new Section2GHSCategoryId(
      2034015,
      '区分4C ; Category4C',
      '区分4C',
      'Category4C',
      Section2GHSItemId.HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_LONG_TERM.value
    );
  static readonly HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_LONG_TERM_CATEGORY_5 =
    new Section2GHSCategoryId(
      2034016,
      '区分5 ; Category5',
      '区分5',
      'Category5',
      Section2GHSItemId.HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_LONG_TERM.value
    );
  static readonly HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_LONG_TERM_CATEGORY_5A =
    new Section2GHSCategoryId(
      2034017,
      '区分5A ; Category5A',
      '区分5A',
      'Category5A',
      Section2GHSItemId.HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_LONG_TERM.value
    );
  static readonly HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_LONG_TERM_CATEGORY_5B =
    new Section2GHSCategoryId(
      2034018,
      '区分5B ; Category5B',
      '区分5B',
      'Category5B',
      Section2GHSItemId.HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_LONG_TERM.value
    );
  static readonly HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_LONG_TERM_CATEGORY_5C =
    new Section2GHSCategoryId(
      2034019,
      '区分5C ; Category5C',
      '区分5C',
      'Category5C',
      Section2GHSItemId.HAZARDOUS_TO_THE_AQUATIC_ENVIRONMENT_LONG_TERM.value
    );
  static readonly HAZARDOUS_TO_THE_OZONE_LAYER_CATEGORY_1 =
    new Section2GHSCategoryId(
      2035000,
      '区分1 ; Category1',
      '区分1',
      'Category1',
      Section2GHSItemId.HAZARDOUS_TO_THE_OZONE_LAYER.value
    );
  static readonly HAZARDOUS_TO_THE_OZONE_LAYER_CATEGORY_1A =
    new Section2GHSCategoryId(
      2035001,
      '区分1A ; Category1A',
      '区分1A',
      'Category1A',
      Section2GHSItemId.HAZARDOUS_TO_THE_OZONE_LAYER.value
    );
  static readonly HAZARDOUS_TO_THE_OZONE_LAYER_CATEGORY_1B =
    new Section2GHSCategoryId(
      2035002,
      '区分1B ; Category1B',
      '区分1B',
      'Category1B',
      Section2GHSItemId.HAZARDOUS_TO_THE_OZONE_LAYER.value
    );
  static readonly HAZARDOUS_TO_THE_OZONE_LAYER_CATEGORY_1C =
    new Section2GHSCategoryId(
      2035003,
      '区分1C ; Category1C',
      '区分1C',
      'Category1C',
      Section2GHSItemId.HAZARDOUS_TO_THE_OZONE_LAYER.value
    );
  static readonly HAZARDOUS_TO_THE_OZONE_LAYER_CATEGORY_2 =
    new Section2GHSCategoryId(
      2035004,
      '区分2 ; Category2',
      '区分2',
      'Category2',
      Section2GHSItemId.HAZARDOUS_TO_THE_OZONE_LAYER.value
    );
  static readonly HAZARDOUS_TO_THE_OZONE_LAYER_CATEGORY_2A =
    new Section2GHSCategoryId(
      2035005,
      '区分2A ; Category2A',
      '区分2A',
      'Category2A',
      Section2GHSItemId.HAZARDOUS_TO_THE_OZONE_LAYER.value
    );
  static readonly HAZARDOUS_TO_THE_OZONE_LAYER_CATEGORY_2B =
    new Section2GHSCategoryId(
      2035006,
      '区分2B ; Category2B',
      '区分2B',
      'Category2B',
      Section2GHSItemId.HAZARDOUS_TO_THE_OZONE_LAYER.value
    );
  static readonly HAZARDOUS_TO_THE_OZONE_LAYER_CATEGORY_2C =
    new Section2GHSCategoryId(
      2035007,
      '区分2C ; Category2C',
      '区分2C',
      'Category2C',
      Section2GHSItemId.HAZARDOUS_TO_THE_OZONE_LAYER.value
    );
  static readonly HAZARDOUS_TO_THE_OZONE_LAYER_CATEGORY_3 =
    new Section2GHSCategoryId(
      2035008,
      '区分3 ; Category3',
      '区分3',
      'Category3',
      Section2GHSItemId.HAZARDOUS_TO_THE_OZONE_LAYER.value
    );
  static readonly HAZARDOUS_TO_THE_OZONE_LAYER_CATEGORY_3A =
    new Section2GHSCategoryId(
      2035009,
      '区分3A ; Category3A',
      '区分3A',
      'Category3A',
      Section2GHSItemId.HAZARDOUS_TO_THE_OZONE_LAYER.value
    );
  static readonly HAZARDOUS_TO_THE_OZONE_LAYER_CATEGORY_3B =
    new Section2GHSCategoryId(
      2035010,
      '区分3B ; Category3B',
      '区分3B',
      'Category3B',
      Section2GHSItemId.HAZARDOUS_TO_THE_OZONE_LAYER.value
    );
  static readonly HAZARDOUS_TO_THE_OZONE_LAYER_CATEGORY_3C =
    new Section2GHSCategoryId(
      2035011,
      '区分3C ; Category3C',
      '区分3C',
      'Category3C',
      Section2GHSItemId.HAZARDOUS_TO_THE_OZONE_LAYER.value
    );
  static readonly HAZARDOUS_TO_THE_OZONE_LAYER_CATEGORY_4 =
    new Section2GHSCategoryId(
      2035012,
      '区分4 ; Category4',
      '区分4',
      'Category4',
      Section2GHSItemId.HAZARDOUS_TO_THE_OZONE_LAYER.value
    );
  static readonly HAZARDOUS_TO_THE_OZONE_LAYER_CATEGORY_4A =
    new Section2GHSCategoryId(
      2035013,
      '区分4A ; Category4A',
      '区分4A',
      'Category4A',
      Section2GHSItemId.HAZARDOUS_TO_THE_OZONE_LAYER.value
    );
  static readonly HAZARDOUS_TO_THE_OZONE_LAYER_CATEGORY_4B =
    new Section2GHSCategoryId(
      2035014,
      '区分4B ; Category4B',
      '区分4B',
      'Category4B',
      Section2GHSItemId.HAZARDOUS_TO_THE_OZONE_LAYER.value
    );
  static readonly HAZARDOUS_TO_THE_OZONE_LAYER_CATEGORY_4C =
    new Section2GHSCategoryId(
      2035015,
      '区分4C ; Category4C',
      '区分4C',
      'Category4C',
      Section2GHSItemId.HAZARDOUS_TO_THE_OZONE_LAYER.value
    );
  static readonly HAZARDOUS_TO_THE_OZONE_LAYER_CATEGORY_5 =
    new Section2GHSCategoryId(
      2035016,
      '区分5 ; Category5',
      '区分5',
      'Category5',
      Section2GHSItemId.HAZARDOUS_TO_THE_OZONE_LAYER.value
    );
  static readonly HAZARDOUS_TO_THE_OZONE_LAYER_CATEGORY_5A =
    new Section2GHSCategoryId(
      2035017,
      '区分5A ; Category5A',
      '区分5A',
      'Category5A',
      Section2GHSItemId.HAZARDOUS_TO_THE_OZONE_LAYER.value
    );
  static readonly HAZARDOUS_TO_THE_OZONE_LAYER_CATEGORY_5B =
    new Section2GHSCategoryId(
      2035018,
      '区分5B ; Category5B',
      '区分5B',
      'Category5B',
      Section2GHSItemId.HAZARDOUS_TO_THE_OZONE_LAYER.value
    );
  static readonly HAZARDOUS_TO_THE_OZONE_LAYER_CATEGORY_5C =
    new Section2GHSCategoryId(
      2035019,
      '区分5C ; Category5C',
      '区分5C',
      'Category5C',
      Section2GHSItemId.HAZARDOUS_TO_THE_OZONE_LAYER.value
    );
  private constructor(
    readonly value: number,
    readonly text: string,
    readonly textJp: string,
    readonly textEn: string,
    readonly itemId: number
  ) {
    Section2GHSCategoryId._values.push(this);
  }

  static fromId(value: number): Section2GHSCategoryId {
    const res = Section2GHSCategoryId._values.find((x) => x.value === value);
    if (res) {
      return res;
    } else {
      return Section2GHSCategoryId.DEFAULT;
    }
  }

  static values(): Section2GHSCategoryId[] {
    return Section2GHSCategoryId._values;
  }

  static valuesFromItemId(id: number): Section2GHSCategoryId[] {
    return Section2GHSCategoryId._values.filter((x) => x.itemId == id);
  }
}

export class Section2GHSHazardId {
  private static readonly _values: Section2GHSHazardId[] = [];

  static readonly DEFAULT = new Section2GHSHazardId(
    0,
    '分類できない・該当データなし ; Classification not possible (No data)',
    '分類できない・該当データなし',
    'Classification not possible (No data)',
    0
  );
  static readonly REPRODUCTIVE_TOXICITY_EFFECTS_ON_OR_THROUGH_LACTATION =
    new Section2GHSHazardId(
      3029000,
      '授乳に対するまたは授乳を介した影響 / 授乳影響 ; Effects on or via lactation',
      '授乳に対するまたは授乳を介した影響 / 授乳影響',
      'Effects on or via lactation',
      Section2GHSItemId.REPRODUCTIVE_TOXICITY.value
    );
  static readonly REPRODUCTIVE_TOXICITY_MALFORMATIONS = new Section2GHSHazardId(
    3029001,
    '奇形 ; Malformations',
    '奇形',
    'Malformations',
    Section2GHSItemId.REPRODUCTIVE_TOXICITY.value
  );
  static readonly REPRODUCTIVE_TOXICITY_WEIGHT_LOSS = new Section2GHSHazardId(
    3029002,
    '体重減少 ; Weight Loss',
    '体重減少',
    'Weight Loss',
    Section2GHSItemId.REPRODUCTIVE_TOXICITY.value
  );
  static readonly REPRODUCTIVE_TOXICITY_HYDROCEPHALUS = new Section2GHSHazardId(
    3029003,
    '水頭症 ; Hydrocephalus',
    '水頭症',
    'Hydrocephalus',
    Section2GHSItemId.REPRODUCTIVE_TOXICITY.value
  );
  static readonly REPRODUCTIVE_TOXICITY_OSSIFICATION_DELAY =
    new Section2GHSHazardId(
      3029004,
      '骨化遅延 ; Delay of ossification',
      '骨化遅延',
      'Delay of ossification',
      Section2GHSItemId.REPRODUCTIVE_TOXICITY.value
    );
  static readonly REPRODUCTIVE_TOXICITY_DEVELOPMENTAL_EFFECTS =
    new Section2GHSHazardId(
      3029005,
      '発生への影響 ; Developmental Effects',
      '発生への影響',
      'Developmental Effects',
      Section2GHSItemId.REPRODUCTIVE_TOXICITY.value
    );
  static readonly REPRODUCTIVE_TOXICITY_ADVERSE_EFFECTS_ON_FERTILITY_OR_FETUS =
    new Section2GHSHazardId(
      3029006,
      '生殖能又は胎児への悪影響のおそれ ; Adverse effects on fertility or fetus',
      '生殖能又は胎児への悪影響のおそれ',
      'Adverse effects on fertility or fetus',
      Section2GHSItemId.REPRODUCTIVE_TOXICITY.value
    );
  static readonly REPRODUCTIVE_TOXICITY_SUSPECTED_ADVERSE_EFFECTS_ON_FERTILITY_OR_FETUS =
    new Section2GHSHazardId(
      3029007,
      '生殖能又は胎児への悪影響のおそれの疑い ; Suspected adverse effects on fertility or fetus',
      '生殖能又は胎児への悪影響のおそれの疑い',
      'Suspected adverse effects on fertility or fetus',
      Section2GHSItemId.REPRODUCTIVE_TOXICITY.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_RESPIRATORY_TRACT_IRRITATION =
    new Section2GHSHazardId(
      3030000,
      '気道刺激性 ; Respiratory Tract Irritation',
      '気道刺激性',
      'Respiratory Tract Irritation',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_ANESTHETIC_PROPERTIES =
    new Section2GHSHazardId(
      3030001,
      '麻酔性 ; Anesthesia',
      '麻酔性',
      'Anesthesia',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_ANESTHETIC_EFFECTS =
    new Section2GHSHazardId(
      3030002,
      '麻酔作用 ; Anesthesia',
      '麻酔作用',
      'Anesthesia',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_ANESTHETIC_EFFECT =
    new Section2GHSHazardId(
      3030003,
      '麻酔効果 ; Anesthesia Effect',
      '麻酔効果',
      'Anesthesia Effect',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_MAY_CAUSE_DROWSINESS_OR_DIZZINESS =
    new Section2GHSHazardId(
      3030004,
      '眠気又はめまいのおそれ ; May Cause Drowsiness or Dizziness',
      '眠気又はめまいのおそれ',
      'May Cause Drowsiness or Dizziness',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_RESPIRATORY_SYSTEM =
    new Section2GHSHazardId(
      3030005,
      '呼吸器系 ; Respiratory System',
      '呼吸器系',
      'Respiratory System',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_RESPIRATORY_TRACT =
    new Section2GHSHazardId(
      3030006,
      '呼吸器 ; Respiratory apparatus',
      '呼吸器',
      'Respiratory apparatus',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_BRONCHIAL =
    new Section2GHSHazardId(
      3030007,
      '気管支 ; Bronchi',
      '気管支',
      'Bronchi',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_AIRWAY =
    new Section2GHSHazardId(
      3030008,
      '気道 ; Respiratory tract',
      '気道',
      'Respiratory tract',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_UPPER_AIRWAY =
    new Section2GHSHazardId(
      3030009,
      '上気道 ; Upper respiratory tract',
      '上気道',
      'Upper respiratory tract',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_NASAL_CAVITY =
    new Section2GHSHazardId(
      3030010,
      '鼻腔 ; Nasal Cavity',
      '鼻腔',
      'Nasal Cavity',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_NASAL_MUCOSA =
    new Section2GHSHazardId(
      3030011,
      '鼻粘膜 ; Nasal Mucosa',
      '鼻粘膜',
      'Nasal Mucosa',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_NASOPHARYNX =
    new Section2GHSHazardId(
      3030012,
      '鼻咽頭 ; Nasopharynx',
      '鼻咽頭',
      'Nasopharynx',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_NASAL_TISSUE =
    new Section2GHSHazardId(
      3030013,
      '鼻組織 ; Nasal Tissue',
      '鼻組織',
      'Nasal Tissue',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_LOWER_AIRWAY =
    new Section2GHSHazardId(
      3030014,
      '下気道 ; Lower Respiratory Tract',
      '下気道',
      'Lower Respiratory Tract',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_LARYNX =
    new Section2GHSHazardId(
      3030015,
      '喉頭 ; Larynx',
      '喉頭',
      'Larynx',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_TRACHEA =
    new Section2GHSHazardId(
      3030016,
      '気管 ; Trachea',
      '気管',
      'Trachea',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_LUNG =
    new Section2GHSHazardId(
      3030017,
      '肺 ; Lung',
      '肺',
      'Lung',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_PNEUMOCONIOSIS =
    new Section2GHSHazardId(
      3030018,
      'じん肺 ; Pneumoconiosis',
      'じん肺',
      'Pneumoconiosis',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_BRAIN =
    new Section2GHSHazardId(
      3030019,
      '脳 ; Brain',
      '脳',
      'Brain',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_SPINAL_CORD =
    new Section2GHSHazardId(
      3030020,
      '脊髄 ; Spinal Cord',
      '脊髄',
      'Spinal Cord',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_NERVOUS =
    new Section2GHSHazardId(
      3030021,
      '神経 ; Nervous',
      '神経',
      'Nervous',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_NERVOUS_SYSTEM =
    new Section2GHSHazardId(
      3030022,
      '神経系 ; Nervous System',
      '神経系',
      'Nervous System',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_CENTRAL_NERVOUS_SYSTEM =
    new Section2GHSHazardId(
      3030023,
      '中枢神経系 ; Central Nervous System',
      '中枢神経系',
      'Central Nervous System',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_CRANIAL_NERVOUS_SYSTEM =
    new Section2GHSHazardId(
      3030024,
      '脳神経系 ; Cranial Nervous System',
      '脳神経系',
      'Cranial Nervous System',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_OPTIC_NERVOUS_SYSTEM =
    new Section2GHSHazardId(
      3030025,
      '視神経系 ; Optic Nervous System',
      '視神経系',
      'Optic Nervous System',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_PERIPHERAL_NERVOUS_SYSTEM =
    new Section2GHSHazardId(
      3030026,
      '末梢神経系 ; Peripheral Nervous System',
      '末梢神経系',
      'Peripheral Nervous System',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_AUTONOMIC_NERVOUS_SYSTEM =
    new Section2GHSHazardId(
      3030027,
      '自律神経系 ; Autonomic Nervous System',
      '自律神経系',
      'Autonomic Nervous System',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_MOTOR_NERVE =
    new Section2GHSHazardId(
      3030028,
      '運動神経系 ; Motor Nerve',
      '運動神経系',
      'Motor Nerve',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_SENSORY_ORGAN =
    new Section2GHSHazardId(
      3030029,
      '感覚器 ; Sensory Organ',
      '感覚器',
      'Sensory Organ',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_VISUAL_SYSTEM =
    new Section2GHSHazardId(
      3030030,
      '視覚系 ; Visual System',
      '視覚系',
      'Visual System',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_EYE =
    new Section2GHSHazardId(
      3030031,
      '眼 ; Eyes',
      '眼',
      'Eyes',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_AMPHIBLESTRODE =
    new Section2GHSHazardId(
      3030032,
      '眼網膜 ; Retina',
      '眼網膜',
      'Retina',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_EARING =
    new Section2GHSHazardId(
      3030033,
      '聴覚; Hearing',
      '聴覚',
      'earing',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_AUDITORY_SYSTEM =
    new Section2GHSHazardId(
      3030034,
      '聴覚器 ; Auditory Organ',
      '聴覚器',
      'Auditory Organ',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_BLOOD_SYSTEM =
    new Section2GHSHazardId(
      3030035,
      '血液系 ; Blood System',
      '血液系',
      'Blood System',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_BLOOD_COAGULATION_SYSTEM =
    new Section2GHSHazardId(
      3030036,
      '血液凝固系 ; Blood Coagulation System',
      '血液凝固系',
      'Blood Coagulation System',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_HEMATOPOIETIC_SYSTEM =
    new Section2GHSHazardId(
      3030037,
      '造血系 ; Hematopoietic System',
      '造血系',
      'Hematopoietic System',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_BLOOD =
    new Section2GHSHazardId(
      3030038,
      '血液 ; Blood',
      '血液',
      'Blood',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_BONE_MARROW =
    new Section2GHSHazardId(
      3030039,
      '骨髄 ; Bone Marrow',
      '骨髄',
      'Bone Marrow',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_LYMPHATIC_SYSTEM =
    new Section2GHSHazardId(
      3030040,
      'リンパ系 ; Lymphatic System',
      'リンパ系',
      'Lymphatic System',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_LYMPH_NODE =
    new Section2GHSHazardId(
      3030041,
      'リンパ節 ; Lymph Node',
      'リンパ節',
      'Lymph Node',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_MESENTERIC_LYMPH_NODES =
    new Section2GHSHazardId(
      3030042,
      '腸間膜リンパ節 ; Mesenteric Lymph Nodes',
      '腸間膜リンパ節',
      'Mesenteric Lymph Nodes',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_THYMUS =
    new Section2GHSHazardId(
      3030043,
      '胸腺 ; Thymus',
      '胸腺',
      'Thymus',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_SPLEEN =
    new Section2GHSHazardId(
      3030044,
      '脾臓 ; Spleen',
      '脾臓',
      'Spleen',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_IMMUNE_SYSTEM =
    new Section2GHSHazardId(
      3030045,
      '免疫系 ; Immune System',
      '免疫系',
      'Immune System',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_CIRCULATORY_SYSTEM =
    new Section2GHSHazardId(
      3030046,
      '循環器系 ; Circulatory System',
      '循環器系',
      'Circulatory System',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_HEART =
    new Section2GHSHazardId(
      3030047,
      '心臓 ; Heart',
      '心臓',
      'Heart',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_CARDIOVASCULAR_SYSTEM =
    new Section2GHSHazardId(
      3030048,
      '心血管系 ; Cardiovascular System',
      '心血管系',
      'Cardiovascular System',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_VASCULAR_SYSTEM =
    new Section2GHSHazardId(
      3030049,
      '血管系 ; Blood Vascular System',
      '血管系',
      'Blood Vascular System',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_CEREBRAL_BLOOD_VESSEL =
    new Section2GHSHazardId(
      3030050,
      '脳血管 ; Cerebral Vascula',
      '脳血管',
      'Cerebral Vascula',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_INTERNAL_ORGAN =
    new Section2GHSHazardId(
      3030051,
      '臓器 ; Internal Organ',
      '臓器',
      'Internal Organ',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_DIGESTIVE_SYSTEM =
    new Section2GHSHazardId(
      3030052,
      '消化器系 ; Digestive System',
      '消化器系',
      'Digestive System',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_DIGESTIVE_TRACT =
    new Section2GHSHazardId(
      3030053,
      '消化管 ; Digestive Tract',
      '消化管',
      'Digestive Tract',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_ORAL_CAVITY =
    new Section2GHSHazardId(
      3030054,
      '口腔 ; Oral Cavity',
      '口腔',
      'Oral Cavity',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_ESOPHAGUS =
    new Section2GHSHazardId(
      3030055,
      '食道 ; Esophagus',
      '食道',
      'Esophagus',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_STOMACH =
    new Section2GHSHazardId(
      3030056,
      '胃 ; Stomach',
      '胃',
      'Stomach',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_INTESTINE =
    new Section2GHSHazardId(
      3030057,
      '腸管 ; Intestinal Tract',
      '腸管',
      'Intestinal Tract',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_SMALL_INTESTINE =
    new Section2GHSHazardId(
      3030058,
      '小腸 ; Small Intestine',
      '小腸',
      'Small Intestine',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_DUODENUM =
    new Section2GHSHazardId(
      3030059,
      '十二指腸 ; Duodenum',
      '十二指腸',
      'Duodenum',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_LARGE_INTESTINE =
    new Section2GHSHazardId(
      3030060,
      '大腸 ; Large Intestine',
      '大腸',
      'Large Intestine',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_SALIVARY_GLAND =
    new Section2GHSHazardId(
      3030061,
      '唾液腺 ; Salivary Gland',
      '唾液腺',
      'Salivary Gland',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_SUBMANDIBULAR_GLAND =
    new Section2GHSHazardId(
      3030062,
      '顎下腺 ; Submandibular Gland',
      '顎下腺',
      'Submandibular Gland',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_PANCREAS =
    new Section2GHSHazardId(
      3030063,
      '膵臓 ; Pancreas',
      '膵臓',
      'Pancreas',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_OOTH =
    new Section2GHSHazardId(
      3030064,
      '歯; Teeth',
      '歯',
      'eeth',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_UMS =
    new Section2GHSHazardId(
      3030065,
      '歯肉; Gums',
      '歯肉',
      'ums',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_PERIODONTAL_TISSUE =
    new Section2GHSHazardId(
      3030066,
      '歯周組織 ; Periodontal Tissue',
      '歯周組織',
      'Periodontal Tissue',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_LIVER =
    new Section2GHSHazardId(
      3030067,
      '肝臓 ; Liver',
      '肝臓',
      'Liver',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_GALLBLADDER =
    new Section2GHSHazardId(
      3030068,
      '胆嚢 ; Gallbladder',
      '胆嚢',
      'Gallbladder',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_URINARY_SYSTEM =
    new Section2GHSHazardId(
      3030069,
      '泌尿器 ; Urinary Organs',
      '泌尿器',
      'Urinary Organs',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_KIDNEY =
    new Section2GHSHazardId(
      3030070,
      '腎臓 ; Kidney',
      '腎臓',
      'Kidney',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_BLADDER =
    new Section2GHSHazardId(
      3030071,
      '膀胱 ; Vesica',
      '膀胱',
      'Vesica',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_REPRODUCTIVE_SYSTEM =
    new Section2GHSHazardId(
      3030072,
      '生殖器系 ; Reproductive System',
      '生殖器系',
      'Reproductive System',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_ACCESSORY_REPRODUCTIVE_ORGAN =
    new Section2GHSHazardId(
      3030073,
      '副生殖器 ; Accessory Reproductive Organ',
      '副生殖器',
      'Accessory Reproductive Organ',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_MALE_REPRODUCTIVE_ORGAN =
    new Section2GHSHazardId(
      3030074,
      '男性生殖器 ; Male Reproductive Organ',
      '男性生殖器',
      'Male Reproductive Organ',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_TESTIS =
    new Section2GHSHazardId(
      3030075,
      '精巣 ; Testis',
      '精巣',
      'Testis',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_EPIDIDYMIS =
    new Section2GHSHazardId(
      3030076,
      '精巣上体 ; Epididymis',
      '精巣上体',
      'Epididymis',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_TESTICULAR_APPENDAGE =
    new Section2GHSHazardId(
      3030077,
      '精巣付属器 ; Testicular Appendage',
      '精巣付属器',
      'Testicular Appendage',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_PROSTATE =
    new Section2GHSHazardId(
      3030078,
      '前立腺 ; Prostate',
      '前立腺',
      'Prostate',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_FEMALE_REPRODUCTIVE_ORGAN =
    new Section2GHSHazardId(
      3030079,
      '女性生殖器 ; Female Reproductive Organ',
      '女性生殖器',
      'Female Reproductive Organ',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_OVARY =
    new Section2GHSHazardId(
      3030080,
      '卵巣 ; Ovary',
      '卵巣',
      'Ovary',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_UTERUS =
    new Section2GHSHazardId(
      3030081,
      '子宮 ; Uterus',
      '子宮',
      'Uterus',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_ENDOCRINE_SYSTEM =
    new Section2GHSHazardId(
      3030082,
      '内分泌系 ; Endocrine System',
      '内分泌系',
      'Endocrine System',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_PITUITARY_GLAND =
    new Section2GHSHazardId(
      3030083,
      '下垂体 ; Pituitary Gland',
      '下垂体',
      'Pituitary Gland',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_THYROID_GLAND =
    new Section2GHSHazardId(
      3030084,
      '甲状腺 ; Glandula Thyreoidea',
      '甲状腺',
      'Glandula Thyreoidea',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_PARATHYROID_GLAND =
    new Section2GHSHazardId(
      3030085,
      '上皮小体 ; Parathyroid Gland',
      '上皮小体',
      'Parathyroid Gland',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_ADRENAL_GLAND =
    new Section2GHSHazardId(
      3030086,
      '副腎 ; Adrenal Gland',
      '副腎',
      'Adrenal Gland',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_SKIN =
    new Section2GHSHazardId(
      3030087,
      '皮膚 ; Skin',
      '皮膚',
      'Skin',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_SKIN_APPENDAGE =
    new Section2GHSHazardId(
      3030088,
      '皮膚付属器 ; Skin Appendages',
      '皮膚付属器',
      'Skin Appendages',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_BODY_HAIR =
    new Section2GHSHazardId(
      3030089,
      '体毛 ; Body Hair',
      '体毛',
      'Body Hair',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_MUSCLE_SYSTEM =
    new Section2GHSHazardId(
      3030090,
      '筋肉系 ; Musclar System',
      '筋肉系',
      'Musclar System',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_CRURAL_MUSCLE =
    new Section2GHSHazardId(
      3030091,
      '下腿筋 ; Crural Muscle',
      '下腿筋',
      'Crural Muscle',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_SKELETAL_MUSCLE =
    new Section2GHSHazardId(
      3030092,
      '骨格筋 ; Skeletal Muscle',
      '骨格筋',
      'Skeletal Muscle',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_SKELETAL_SYSTEM =
    new Section2GHSHazardId(
      3030093,
      '骨格系 ; Skeletal System',
      '骨格系',
      'Skeletal System',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_OSSIFICATION_DELAY =
    new Section2GHSHazardId(
      3030094,
      '骨化遅延 ; Delay of Ossification',
      '骨化遅延',
      'Delay of Ossification',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_BONE =
    new Section2GHSHazardId(
      3030095,
      '骨 ; Bone',
      '骨',
      'Bone',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_JOINT =
    new Section2GHSHazardId(
      3030096,
      '関節 ; Bone Joint',
      '関節',
      'Bone Joint',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_SYSTEMIC_TOXICITY =
    new Section2GHSHazardId(
      3030097,
      '全身毒性 ; Systemic Toxicity',
      '全身毒性',
      'Systemic Toxicity',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_WHOLE_BODY =
    new Section2GHSHazardId(
      3030098,
      '全身 ; Whole Body',
      '全身',
      'Whole Body',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_INHALATION =
    new Section2GHSHazardId(
      3030099,
      '吸入 ; Inhalation',
      '吸入',
      'Inhalation',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_WHOLE_BODY_INHALATION =
    new Section2GHSHazardId(
      3030100,
      '全身（吸入） ; Whole body (Inhalation)',
      '全身（吸入）',
      'Whole body (Inhalation)',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_RESPIRATORY_TRACT_INHALATION =
    new Section2GHSHazardId(
      3030101,
      '呼吸器（吸入） ; Respiratory Tract (Inhalation)',
      '呼吸器（吸入）',
      'Respiratory Tract (Inhalation)',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_LUNG_INHALATION =
    new Section2GHSHazardId(
      3030102,
      '肺（吸入） ; Lung (Inhalation)',
      '肺（吸入）',
      'Lung (Inhalation)',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_RESPIRATORY_TRACT_IRRITATION =
    new Section2GHSHazardId(
      3031000,
      '気道刺激性 ; Respiratory Tract Irritation',
      '気道刺激性',
      'Respiratory Tract Irritation',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_ANESTHETIC_PROPERTIES =
    new Section2GHSHazardId(
      3031001,
      '麻酔性 ; Anesthesia',
      '麻酔性',
      'Anesthesia',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_ANESTHETIC_EFFECTS =
    new Section2GHSHazardId(
      3031002,
      '麻酔作用 ; Anesthesia',
      '麻酔作用',
      'Anesthesia',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_ANESTHETIC_EFFECT =
    new Section2GHSHazardId(
      3031003,
      '麻酔効果 ; Anesthesia Effect',
      '麻酔効果',
      'Anesthesia Effect',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_MAY_CAUSE_DROWSINESS_OR_DIZZINESS =
    new Section2GHSHazardId(
      3031004,
      '眠気又はめまいのおそれ ; May Cause Drowsiness or Dizziness',
      '眠気又はめまいのおそれ',
      'May Cause Drowsiness or Dizziness',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_RESPIRATORY_SYSTEM =
    new Section2GHSHazardId(
      3031005,
      '呼吸器系 ; Respiratory System',
      '呼吸器系',
      'Respiratory System',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_RESPIRATORY_TRACT =
    new Section2GHSHazardId(
      3031006,
      '呼吸器 ; Respiratory apparatus',
      '呼吸器',
      'Respiratory apparatus',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_BRONCHIAL =
    new Section2GHSHazardId(
      3031007,
      '気管支 ; Bronchi',
      '気管支',
      'Bronchi',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_AIRWAY =
    new Section2GHSHazardId(
      3031008,
      '気道 ; Respiratory tract',
      '気道',
      'Respiratory tract',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_UPPER_AIRWAY =
    new Section2GHSHazardId(
      3031009,
      '上気道 ; Upper respiratory tract',
      '上気道',
      'Upper respiratory tract',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_NASAL_CAVITY =
    new Section2GHSHazardId(
      3031010,
      '鼻腔 ; Nasal Cavity',
      '鼻腔',
      'Nasal Cavity',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_NASAL_MUCOSA =
    new Section2GHSHazardId(
      3031011,
      '鼻粘膜 ; Nasal Mucosa',
      '鼻粘膜',
      'Nasal Mucosa',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_NASOPHARYNX =
    new Section2GHSHazardId(
      3031012,
      '鼻咽頭 ; Nasopharynx',
      '鼻咽頭',
      'Nasopharynx',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_NASAL_TISSUE =
    new Section2GHSHazardId(
      3031013,
      '鼻組織 ; Nasal Tissue',
      '鼻組織',
      'Nasal Tissue',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_LOWER_AIRWAY =
    new Section2GHSHazardId(
      3031014,
      '下気道 ; Lower Respiratory Tract',
      '下気道',
      'Lower Respiratory Tract',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_LARYNX =
    new Section2GHSHazardId(
      3031015,
      '喉頭 ; Larynx',
      '喉頭',
      'Larynx',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_TRACHEA =
    new Section2GHSHazardId(
      3031016,
      '気管 ; Trachea',
      '気管',
      'Trachea',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_LUNG =
    new Section2GHSHazardId(
      3031017,
      '肺 ; Lung',
      '肺',
      'Lung',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_PNEUMOCONIOSIS =
    new Section2GHSHazardId(
      3031018,
      'じん肺 ; Pneumoconiosis',
      'じん肺',
      'Pneumoconiosis',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_BRAIN =
    new Section2GHSHazardId(
      3031019,
      '脳 ; Brain',
      '脳',
      'Brain',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_SPINAL_CORD =
    new Section2GHSHazardId(
      3031020,
      '脊髄 ; Spinal Cord',
      '脊髄',
      'Spinal Cord',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_NERVOUS =
    new Section2GHSHazardId(
      3031021,
      '神経 ; Nervous',
      '神経',
      'Nervous',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_NERVOUS_SYSTEM =
    new Section2GHSHazardId(
      3031022,
      '神経系 ; Nervous System',
      '神経系',
      'Nervous System',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_CENTRAL_NERVOUS_SYSTEM =
    new Section2GHSHazardId(
      3031023,
      '中枢神経系 ; Central Nervous System',
      '中枢神経系',
      'Central Nervous System',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_CRANIAL_NERVOUS_SYSTEM =
    new Section2GHSHazardId(
      3031024,
      '脳神経系 ; Cranial Nervous System',
      '脳神経系',
      'Cranial Nervous System',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_OPTIC_NERVOUS_SYSTEM =
    new Section2GHSHazardId(
      3031025,
      '視神経系 ; Optic Nervous System',
      '視神経系',
      'Optic Nervous System',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_PERIPHERAL_NERVOUS_SYSTEM =
    new Section2GHSHazardId(
      3031026,
      '末梢神経系 ; Peripheral Nervous System',
      '末梢神経系',
      'Peripheral Nervous System',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_AUTONOMIC_NERVOUS_SYSTEM =
    new Section2GHSHazardId(
      3031027,
      '自律神経系 ; Autonomic Nervous System',
      '自律神経系',
      'Autonomic Nervous System',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_MOTOR_NERVE =
    new Section2GHSHazardId(
      3031028,
      '運動神経系 ; Motor Nerve',
      '運動神経系',
      'Motor Nerve',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_SENSORY_ORGAN =
    new Section2GHSHazardId(
      3031029,
      '感覚器 ; Sensory Organ',
      '感覚器',
      'Sensory Organ',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_VISUAL_SYSTEM =
    new Section2GHSHazardId(
      3031030,
      '視覚系 ; Visual System',
      '視覚系',
      'Visual System',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_EYE =
    new Section2GHSHazardId(
      3031031,
      '眼 ; Eyes',
      '眼',
      'Eyes',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_AMPHIBLESTRODE =
    new Section2GHSHazardId(
      3031032,
      '眼網膜 ; Retina',
      '眼網膜',
      'Retina',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_EARING =
    new Section2GHSHazardId(
      3031033,
      '聴覚; Hearing',
      '聴覚',
      'earing',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_AUDITORY_SYSTEM =
    new Section2GHSHazardId(
      3031034,
      '聴覚器 ; Auditory Organ',
      '聴覚器',
      'Auditory Organ',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_BLOOD_SYSTEM =
    new Section2GHSHazardId(
      3031035,
      '血液系 ; Blood System',
      '血液系',
      'Blood System',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_BLOOD_COAGULATION_SYSTEM =
    new Section2GHSHazardId(
      3031036,
      '血液凝固系 ; Blood Coagulation System',
      '血液凝固系',
      'Blood Coagulation System',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_HEMATOPOIETIC_SYSTEM =
    new Section2GHSHazardId(
      3031037,
      '造血系 ; Hematopoietic System',
      '造血系',
      'Hematopoietic System',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_BLOOD =
    new Section2GHSHazardId(
      3031038,
      '血液 ; Blood',
      '血液',
      'Blood',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_BONE_MARROW =
    new Section2GHSHazardId(
      3031039,
      '骨髄 ; Bone Marrow',
      '骨髄',
      'Bone Marrow',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_LYMPHATIC_SYSTEM =
    new Section2GHSHazardId(
      3031040,
      'リンパ系 ; Lymphatic System',
      'リンパ系',
      'Lymphatic System',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_LYMPH_NODE =
    new Section2GHSHazardId(
      3031041,
      'リンパ節 ; Lymph Node',
      'リンパ節',
      'Lymph Node',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_MESENTERIC_LYMPH_NODES =
    new Section2GHSHazardId(
      3031042,
      '腸間膜リンパ節 ; Mesenteric Lymph Nodes',
      '腸間膜リンパ節',
      'Mesenteric Lymph Nodes',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_THYMUS =
    new Section2GHSHazardId(
      3031043,
      '胸腺 ; Thymus',
      '胸腺',
      'Thymus',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_SPLEEN =
    new Section2GHSHazardId(
      3031044,
      '脾臓 ; Spleen',
      '脾臓',
      'Spleen',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_IMMUNE_SYSTEM =
    new Section2GHSHazardId(
      3031045,
      '免疫系 ; Immune System',
      '免疫系',
      'Immune System',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_CIRCULATORY_SYSTEM =
    new Section2GHSHazardId(
      3031046,
      '循環器系 ; Circulatory System',
      '循環器系',
      'Circulatory System',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_HEART =
    new Section2GHSHazardId(
      3031047,
      '心臓 ; Heart',
      '心臓',
      'Heart',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_CARDIOVASCULAR_SYSTEM =
    new Section2GHSHazardId(
      3031048,
      '心血管系 ; Cardiovascular System',
      '心血管系',
      'Cardiovascular System',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_VASCULAR_SYSTEM =
    new Section2GHSHazardId(
      3031049,
      '血管系 ; Blood Vascular System',
      '血管系',
      'Blood Vascular System',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_CEREBRAL_BLOOD_VESSEL =
    new Section2GHSHazardId(
      3031050,
      '脳血管 ; Cerebral Vascula',
      '脳血管',
      'Cerebral Vascula',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_INTERNAL_ORGAN =
    new Section2GHSHazardId(
      3031051,
      '臓器 ; Internal Organ',
      '臓器',
      'Internal Organ',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_DIGESTIVE_SYSTEM =
    new Section2GHSHazardId(
      3031052,
      '消化器系 ; Digestive System',
      '消化器系',
      'Digestive System',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_DIGESTIVE_TRACT =
    new Section2GHSHazardId(
      3031053,
      '消化管 ; Digestive Tract',
      '消化管',
      'Digestive Tract',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_ORAL_CAVITY =
    new Section2GHSHazardId(
      3031054,
      '口腔 ; Oral Cavity',
      '口腔',
      'Oral Cavity',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_ESOPHAGUS =
    new Section2GHSHazardId(
      3031055,
      '食道 ; Esophagus',
      '食道',
      'Esophagus',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_STOMACH =
    new Section2GHSHazardId(
      3031056,
      '胃 ; Stomach',
      '胃',
      'Stomach',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_INTESTINE =
    new Section2GHSHazardId(
      3031057,
      '腸管 ; Intestinal Tract',
      '腸管',
      'Intestinal Tract',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_SMALL_INTESTINE =
    new Section2GHSHazardId(
      3031058,
      '小腸 ; Small Intestine',
      '小腸',
      'Small Intestine',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_DUODENUM =
    new Section2GHSHazardId(
      3031059,
      '十二指腸 ; Duodenum',
      '十二指腸',
      'Duodenum',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_LARGE_INTESTINE =
    new Section2GHSHazardId(
      3031060,
      '大腸 ; Large Intestine',
      '大腸',
      'Large Intestine',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_SALIVARY_GLAND =
    new Section2GHSHazardId(
      3031061,
      '唾液腺 ; Salivary Gland',
      '唾液腺',
      'Salivary Gland',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_SUBMANDIBULAR_GLAND =
    new Section2GHSHazardId(
      3031062,
      '顎下腺 ; Submandibular Gland',
      '顎下腺',
      'Submandibular Gland',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_PANCREAS =
    new Section2GHSHazardId(
      3031063,
      '膵臓 ; Pancreas',
      '膵臓',
      'Pancreas',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_OOTH =
    new Section2GHSHazardId(
      3031064,
      '歯; Teeth',
      '歯',
      'eeth',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_UMS =
    new Section2GHSHazardId(
      3031065,
      '歯肉; Gums',
      '歯肉',
      'ums',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_PERIODONTAL_TISSUE =
    new Section2GHSHazardId(
      3031066,
      '歯周組織 ; Periodontal Tissue',
      '歯周組織',
      'Periodontal Tissue',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_LIVER =
    new Section2GHSHazardId(
      3031067,
      '肝臓 ; Liver',
      '肝臓',
      'Liver',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_GALLBLADDER =
    new Section2GHSHazardId(
      3031068,
      '胆嚢 ; Gallbladder',
      '胆嚢',
      'Gallbladder',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_URINARY_SYSTEM =
    new Section2GHSHazardId(
      3031069,
      '泌尿器 ; Urinary Organs',
      '泌尿器',
      'Urinary Organs',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_KIDNEY =
    new Section2GHSHazardId(
      3031070,
      '腎臓 ; Kidney',
      '腎臓',
      'Kidney',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_BLADDER =
    new Section2GHSHazardId(
      3031071,
      '膀胱 ; Vesica',
      '膀胱',
      'Vesica',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_REPRODUCTIVE_SYSTEM =
    new Section2GHSHazardId(
      3031072,
      '生殖器系 ; Reproductive System',
      '生殖器系',
      'Reproductive System',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_ACCESSORY_REPRODUCTIVE_ORGAN =
    new Section2GHSHazardId(
      3031073,
      '副生殖器 ; Accessory Reproductive Organ',
      '副生殖器',
      'Accessory Reproductive Organ',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_MALE_REPRODUCTIVE_ORGAN =
    new Section2GHSHazardId(
      3031074,
      '男性生殖器 ; Male Reproductive Organ',
      '男性生殖器',
      'Male Reproductive Organ',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_TESTIS =
    new Section2GHSHazardId(
      3031075,
      '精巣 ; Testis',
      '精巣',
      'Testis',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_EPIDIDYMIS =
    new Section2GHSHazardId(
      3031076,
      '精巣上体 ; Epididymis',
      '精巣上体',
      'Epididymis',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_TESTICULAR_APPENDAGE =
    new Section2GHSHazardId(
      3031077,
      '精巣付属器 ; Testicular Appendage',
      '精巣付属器',
      'Testicular Appendage',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_PROSTATE =
    new Section2GHSHazardId(
      3031078,
      '前立腺 ; Prostate',
      '前立腺',
      'Prostate',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_FEMALE_REPRODUCTIVE_ORGAN =
    new Section2GHSHazardId(
      3031079,
      '女性生殖器 ; Female Reproductive Organ',
      '女性生殖器',
      'Female Reproductive Organ',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_OVARY =
    new Section2GHSHazardId(
      3031080,
      '卵巣 ; Ovary',
      '卵巣',
      'Ovary',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_UTERUS =
    new Section2GHSHazardId(
      3031081,
      '子宮 ; Uterus',
      '子宮',
      'Uterus',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_ENDOCRINE_SYSTEM =
    new Section2GHSHazardId(
      3031082,
      '内分泌系 ; Endocrine System',
      '内分泌系',
      'Endocrine System',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_PITUITARY_GLAND =
    new Section2GHSHazardId(
      3031083,
      '下垂体 ; Pituitary Gland',
      '下垂体',
      'Pituitary Gland',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_THYROID_GLAND =
    new Section2GHSHazardId(
      3031084,
      '甲状腺 ; Glandula Thyreoidea',
      '甲状腺',
      'Glandula Thyreoidea',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_PARATHYROID_GLAND =
    new Section2GHSHazardId(
      3031085,
      '上皮小体 ; Parathyroid Gland',
      '上皮小体',
      'Parathyroid Gland',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_ADRENAL_GLAND =
    new Section2GHSHazardId(
      3031086,
      '副腎 ; Adrenal Gland',
      '副腎',
      'Adrenal Gland',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_SKIN =
    new Section2GHSHazardId(
      3031087,
      '皮膚 ; Skin',
      '皮膚',
      'Skin',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_SKIN_APPENDAGE =
    new Section2GHSHazardId(
      3031088,
      '皮膚付属器 ; Skin Appendages',
      '皮膚付属器',
      'Skin Appendages',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_BODY_HAIR =
    new Section2GHSHazardId(
      3031089,
      '体毛 ; Body Hair',
      '体毛',
      'Body Hair',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_MUSCLE_SYSTEM =
    new Section2GHSHazardId(
      3031090,
      '筋肉系 ; Musclar System',
      '筋肉系',
      'Musclar System',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_CRURAL_MUSCLE =
    new Section2GHSHazardId(
      3031091,
      '下腿筋 ; Crural Muscle',
      '下腿筋',
      'Crural Muscle',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_SKELETAL_MUSCLE =
    new Section2GHSHazardId(
      3031092,
      '骨格筋 ; Skeletal Muscle',
      '骨格筋',
      'Skeletal Muscle',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_SKELETAL_SYSTEM =
    new Section2GHSHazardId(
      3031093,
      '骨格系 ; Skeletal System',
      '骨格系',
      'Skeletal System',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_OSSIFICATION_DELAY =
    new Section2GHSHazardId(
      3031094,
      '骨化遅延 ; Delay of Ossification',
      '骨化遅延',
      'Delay of Ossification',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_BONE =
    new Section2GHSHazardId(
      3031095,
      '骨 ; Bone',
      '骨',
      'Bone',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_JOINT =
    new Section2GHSHazardId(
      3031096,
      '関節 ; Bone Joint',
      '関節',
      'Bone Joint',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_SYSTEMIC_TOXICITY =
    new Section2GHSHazardId(
      3031097,
      '全身毒性 ; Systemic Toxicity',
      '全身毒性',
      'Systemic Toxicity',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_WHOLE_BODY =
    new Section2GHSHazardId(
      3031098,
      '全身 ; Whole Body',
      '全身',
      'Whole Body',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_INHALATION =
    new Section2GHSHazardId(
      3031099,
      '吸入 ; Inhalation',
      '吸入',
      'Inhalation',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_WHOLE_BODY_INHALATION =
    new Section2GHSHazardId(
      3031100,
      '全身（吸入） ; Whole body (Inhalation)',
      '全身（吸入）',
      'Whole body (Inhalation)',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_RESPIRATORY_TRACT_INHALATION =
    new Section2GHSHazardId(
      3031101,
      '呼吸器（吸入） ; Respiratory Tract (Inhalation)',
      '呼吸器（吸入）',
      'Respiratory Tract (Inhalation)',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );
  static readonly SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_LUNG_INHALATION =
    new Section2GHSHazardId(
      3031102,
      '肺（吸入） ; Lung (Inhalation)',
      '肺（吸入）',
      'Lung (Inhalation)',
      Section2GHSItemId.SPECIFIC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE.value
    );

  private constructor(
    readonly value: number,
    readonly text: string,
    readonly textJp: string,
    readonly textEn: string,
    readonly itemId: number
  ) {
    Section2GHSHazardId._values.push(this);
  }

  static fromId(value: number): Section2GHSHazardId {
    const res = Section2GHSHazardId._values.find((x) => x.value === value);
    if (res) {
      return res;
    } else {
      return Section2GHSHazardId.DEFAULT;
    }
  }

  static values(): Section2GHSHazardId[] {
    return Section2GHSHazardId._values;
  }

  static valuesFromItemId(id: number): Section2GHSHazardId[] {
    return Section2GHSHazardId._values.filter((x) => x.itemId == id);
  }
}
